import styled from 'styled-components';

interface Props {
  $justify?: string;
  $marginTop?: number;
  $verticalMargin?: number;
  $align?: string;
  $height?: number;
  $totalHeight?: boolean;
  $gap?: number;
  $width?: string;
  $direction?: 'column' | 'row';
  $textAlign?: 'center';
  $flex?: number;
  $horizontalPadding?: number;
  $cursor?: string;
}

const FlexContainer = styled.div<Props>(
  ({
    $justify = 'flex-start',
    $marginTop = 0,
    $align,
    $height,
    $gap,
    $width,
    $direction,
    $textAlign,
    $verticalMargin,
    $totalHeight,
    $flex,
    $horizontalPadding,
    $cursor,
  }) => ({
    cursor: $cursor,
    display: 'flex',
    flex: $flex,
    justifyContent: $justify,
    marginTop: $verticalMargin ? `${$verticalMargin}px` : `${$marginTop}px`,
    marginBottom: $verticalMargin && `${$verticalMargin}px`,
    alignItems: $align,
    height: $totalHeight ? '100%' : $height,
    gap: $gap,
    width: $width,
    flexDirection: $direction,
    textAlign: $textAlign,
    paddingRight: $horizontalPadding,
    paddingLeft: $horizontalPadding,
  }),
);

export default FlexContainer;
