import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const Document: VFC<IconInterface> = ({ color = COLORS.GRAY }) => {
  return (
    <svg
      width="23"
      height="27"
      viewBox="0 0 23 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.709 1.41675L13.4161 0.709641C13.2286 0.522105 12.9742 0.416748 12.709 0.416748V1.41675ZM4.25065 1.41675V0.416748V1.41675ZM1.83398 3.83341H0.833984H1.83398ZM1.83398 23.1667H0.833984H1.83398ZM21.1673 9.87508H22.1673C22.1673 9.60987 22.062 9.35551 21.8744 9.16798L21.1673 9.87508ZM12.709 9.87508H11.709C11.709 10.4274 12.1567 10.8751 12.709 10.8751V9.87508ZM12.709 0.416748H4.25065V2.41675H12.709V0.416748ZM4.25065 0.416748C3.34449 0.416748 2.47545 0.776717 1.8347 1.41747L3.24892 2.83168C3.51459 2.566 3.87493 2.41675 4.25065 2.41675V0.416748ZM1.8347 1.41747C1.19395 2.05822 0.833984 2.92726 0.833984 3.83341H2.83398C2.83398 3.45769 2.98324 3.09736 3.24892 2.83168L1.8347 1.41747ZM0.833984 3.83341V23.1667H2.83398V3.83341H0.833984ZM0.833984 23.1667C0.833984 24.0729 1.19395 24.9419 1.8347 25.5827L3.24892 24.1685C2.98324 23.9028 2.83398 23.5425 2.83398 23.1667H0.833984ZM1.8347 25.5827C2.47545 26.2234 3.34449 26.5834 4.25065 26.5834V24.5834C3.87493 24.5834 3.51459 24.4342 3.24892 24.1685L1.8347 25.5827ZM4.25065 26.5834H18.7507V24.5834H4.25065V26.5834ZM18.7507 26.5834C19.6568 26.5834 20.5258 26.2234 21.1666 25.5827L19.7524 24.1685C19.4867 24.4342 19.1264 24.5834 18.7507 24.5834V26.5834ZM21.1666 25.5827C21.8073 24.9419 22.1673 24.0729 22.1673 23.1667H20.1673C20.1673 23.5425 20.0181 23.9028 19.7524 24.1685L21.1666 25.5827ZM22.1673 23.1667V9.87508H20.1673V23.1667H22.1673ZM21.8744 9.16798L13.4161 0.709641L12.0019 2.12385L20.4602 10.5822L21.8744 9.16798ZM11.709 1.41675L11.709 9.87508H13.709L13.709 1.41675L11.709 1.41675ZM12.709 10.8751L21.1673 10.8751V8.87508L12.709 8.87508V10.8751Z"
        fill={color}
      />
    </svg>
  );
};

export default Document;
