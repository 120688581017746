import { Checkbox } from 'antd';
import type { VFC } from 'react';

import type useDeleteUsers from '../hooks/useDeleteUsers';
import FlexContainer from '../../common/flex/FlexContainer';

interface Props
  extends Pick<
    ReturnType<typeof useDeleteUsers>,
    'isOktaUserIdMarked' | 'updateOktaUserIdsToBeDeleted'
  > {
  oktaUserId: string;
}

const DeleteUserCheckbox: VFC<Props> = ({
  isOktaUserIdMarked,
  oktaUserId,
  updateOktaUserIdsToBeDeleted,
}) => {
  return (
    <FlexContainer $justify="center" $align="center">
      <Checkbox
        checked={isOktaUserIdMarked(oktaUserId)}
        onChange={({ target: { checked } }) => {
          updateOktaUserIdsToBeDeleted(checked, oktaUserId);
        }}
      />
    </FlexContainer>
  );
};

export default DeleteUserCheckbox;
