import type { VFC } from 'react';
import { useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import SuccessModal from './SuccessModal';
import { TMTitle } from '../../common/typography';
import InputFile from '../../common/inputs/InputFile';
import type ModalProps from '../../../types/ModalProps';
import FlexContainer from '../../common/flex/FlexContainer';
import { uploadUsersInBulk } from '../../../store/thunks/users';
import useOktaAuthCustom from '../../../hooks/useOktaAuthCustom';
import {
  useIsUploadingFinished,
  useIsUploadingUsersInBulk,
  useUploadUsersInBulkError,
  useWasUploadingFailure,
  useWasUploadingSuccessful,
} from '../../../store/selectors/users';
import { resetUploadBulkStatus } from '../../../store/slices/users';
import ErrorModal from '../../common/modals/ErrorModal';

const UploadBulkUsersModal: VFC<ModalProps> = ({ isVisible, hideHandler }) => {
  const authState = useOktaAuthCustom();

  const errors = useUploadUsersInBulkError();

  const getErrorsList = () => {
    if (!errors?.validationErrors) return [];
    return Object.values(errors.validationErrors).flat();
  };

  const isUploadingUsersInBulk = useIsUploadingUsersInBulk();
  const wasUploadingSuccessful = useWasUploadingSuccessful();
  const wasUploadingFailure = useWasUploadingFailure();
  const isUploadingFinished = useIsUploadingFinished();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUploadingFinished) {
      hideHandler();
    }
  }, [isUploadingFinished, hideHandler]);

  return (
    <>
      <Modal
        visible={isVisible}
        onCancel={hideHandler}
        footer={null}
        centered
        destroyOnClose
        closable={!isUploadingUsersInBulk}
        maskClosable={!isUploadingUsersInBulk}
      >
        <Container>
          <TMTitle level={4} $textAlign="center">
            Drag and Drop your XLSX File
          </TMTitle>
          <Form
            onFinish={(form) =>
              dispatch(
                uploadUsersInBulk({
                  file: form.file.file,
                  // FIXME: Meltstudio id by default
                  partnerId: authState.user?.partnerId ?? 41,
                }),
              )
            }
          >
            <Form.Item
              name="file"
              valuePropName="file"
              rules={[{ required: true, message: 'Please upload a XLSX file' }]}
            >
              <InputFile title="Upload your XLSX File" accept=".xlsx" />
            </Form.Item>
            <FlexContainer $justify="space-evenly" $gap={10}>
              <Button
                block
                type="primary"
                size="large"
                loading={isUploadingUsersInBulk}
                htmlType="submit"
              >
                {isUploadingUsersInBulk ? 'Uploading' : 'Upload'}
              </Button>
              <Button
                block
                size="large"
                onClick={hideHandler}
                disabled={isUploadingUsersInBulk}
              >
                Cancel
              </Button>
            </FlexContainer>
          </Form>
        </Container>
      </Modal>
      <SuccessModal
        isVisible={wasUploadingSuccessful}
        hideHandler={() => dispatch(resetUploadBulkStatus())}
      />
      <ErrorModal
        isVisible={wasUploadingFailure}
        hideHandler={() => dispatch(resetUploadBulkStatus())}
        subtitle={errors?.message}
        errors={getErrorsList()}
      />
    </>
  );
};

const Container = styled.div`
  margin: 20px;
`;

export default UploadBulkUsersModal;
