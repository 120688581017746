import countries from '../assets/countries.json';

function getCountries() {
  return countries.map((country) => {
    return {
      name: country.name,
      alpha2Code: country.alpha2Code,
    };
  });
}

export default getCountries;
