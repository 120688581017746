import type { VFC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';

import StripeSetupForm from './StripeSetupForm';

interface Props {
  stripe: Promise<Stripe | null>;
  clientSecret: string;
  cancelEditionHandler: () => void;
}

const StripeSetup: VFC<Props> = ({
  stripe,
  clientSecret,
  cancelEditionHandler,
}) => {
  return (
    <Elements stripe={stripe} options={{ clientSecret }}>
      <StripeSetupForm cancelEditionHandler={cancelEditionHandler} />
    </Elements>
  );
};

export default StripeSetup;
