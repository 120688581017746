import type EndpointsType from '@/types/EndpointsType';

const UserEndpoints = Object.freeze<EndpointsType>({
  getPartnerUsers: {
    endpoint: '/partnerUsers',
    method: 'GET',
  },
  uploadUsersInBulk: {
    endpoint: '/partnerUsers/bulk',
    method: 'POST',
  },
  inviteUsers: {
    endpoint: '/partnerUsers/invite',
    method: 'POST',
  },
  processBulkFile: {
    endpoint: '/partnerUsers/processBulk',
    method: 'POST',
  },
  getActiveProcess: {
    endpoint: '/partnerUsers/process',
    method: 'GET',
  },
  getUsersActivityMetrics: {
    endpoint: '/intercom/currentYearUsersActivity',
    method: 'GET',
  },
  deactivateUser: {
    endpoint: '/partnerUsers/:userId/deactivate',
    method: 'GET',
  },
  reactivateUser: {
    endpoint: '/partnerUsers/:userId/reactivate',
    method: 'GET',
  },
  sendAccountReminderEmail: {
    endpoint: '/partnerUsers/:userId/sendAccountReminderEmail',
    method: 'GET',
  },
  changeUserPlan: {
    endpoint: '/partnerUsers/:userId/changePlan',
    method: 'POST',
  },
  deleteUsers: {
    endpoint: '/partnerUsers/deleteUsers',
    method: 'DELETE',
  },
});

export default UserEndpoints;
