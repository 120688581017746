import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Skeleton } from 'antd';

import {
  usePlanDetails,
  useIsLoadingPlanDetails,
} from '../../../store/selectors/planDetails';
import {
  useContract,
  useIsLoadingContract,
} from '../../../store/selectors/contracts';
import PlanSeatsDetail from './PlanSeatsDetail';
import { loadPlans } from '../../../store/thunks/plans';
import { getPlanDetailsByContract } from '../../../store/thunks/planDetails';
import { useIsLoadingPlans, usePlans } from '../../../store/selectors/plans';

const SeatsDescription = () => {
  const dispatch = useDispatch();
  const contract = useContract();
  const isContractLoading = useIsLoadingContract();
  const planDetails = usePlanDetails();
  const isLoading = useIsLoadingPlanDetails();
  const isPlansLoading = useIsLoadingPlans();
  const plans = usePlans();

  useEffect(() => {
    if (contract && planDetails.length === 0 && !isLoading)
      dispatch(getPlanDetailsByContract(contract.id));
  }, [planDetails, isLoading, contract, dispatch]);

  useEffect(() => {
    if (plans.length === 0 && !isPlansLoading) dispatch(loadPlans());
  }, [plans, dispatch, isPlansLoading]);

  const basic = planDetails.find((plan) => plan.planId === 1);
  const advanced = planDetails.find((plan) => plan.planId === 2);
  const premium = planDetails.find((plan) => plan.planId === 3);

  return (
    <Skeleton
      loading={isLoading || isContractLoading || isPlansLoading}
      active
      paragraph={{ rows: 6 }}
    >
      <Container>
        <PlanSeatsDetail
          planName="Basic"
          totalSeats={basic?.seats.allocated ?? 0}
          assignedSeats={basic?.seats.used ?? 0}
          marginBottom={24}
        />
        <PlanSeatsDetail
          planName="Advanced"
          totalSeats={advanced?.seats.allocated ?? 0}
          assignedSeats={advanced?.seats.used ?? 0}
          marginBottom={24}
        />
        <PlanSeatsDetail
          planName="Premium"
          totalSeats={premium?.seats.allocated ?? 0}
          assignedSeats={premium?.seats.used ?? 0}
          marginBottom={24}
        />
      </Container>
    </Skeleton>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
`;

export default SeatsDescription;
