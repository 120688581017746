import type { FC } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Input, Form, Col, Button } from 'antd';
import { TMText } from '../../common/typography';
import COLORS from '../../../styles/Colors';
import { resetChangePasswordLoading } from '../../../store/slices/partnerAdmins';
import { changePassword } from '../../../store/thunks/partnerAdmins';
import {
  useIsChangePasswordSuccess,
  useIsLoadingChangePassword,
} from '../../../store/selectors/partnerAdmins';
import { isPasswordValid } from '../../../utils/isPasswordValid';

interface Props {
  onCancel: () => void;
}

interface ChangePasswordForm {
  user: {
    password: string;
    new_password: string;
    confirm_password: string;
  };
}

const ChangePasswordForm: FC<Props> = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useIsLoadingChangePassword();
  const success = useIsChangePasswordSuccess();

  useEffect(() => {
    if (!isLoading && success) {
      dispatch(resetChangePasswordLoading());
      onCancel();
    }
  }, [isLoading, success, onCancel, dispatch]);

  const onFinish = (data: ChangePasswordForm) => {
    dispatch(
      changePassword({
        oldPassword: data.user.password,
        newPassword: data.user.new_password,
      }),
    );
  };

  return (
    <Container>
      <Form
        name="changePasswordForm"
        form={form}
        labelAlign="left"
        labelCol={{ span: 7, push: 2 }}
        wrapperCol={{ span: 10, push: 2 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {/* Form Change Password Start */}
        <Form.Item
          colon={false}
          label="Current Password"
          name={['user', 'password']}
          rules={[
            {
              required: true,
              message: 'Enter your current password',
            },
          ]}
        >
          <StyledPasswordInput allowClear placeholder="Current Password" />
        </Form.Item>
        <Form.Item
          colon={false}
          name={['user', 'new_password']}
          label="New Password"
          rules={[
            {
              required: true,
              message: 'Enter your new password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && getFieldValue(['user', 'password']) === value) {
                  return Promise.reject(
                    new Error('New password should be different'),
                  );
                }
                if (!value || isPasswordValid(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Please match the below requirement'),
                );
              },
            }),
          ]}
          hasFeedback
        >
          <StyledPasswordInput allowClear placeholder="New Password" />
        </Form.Item>
        <Form.Item
          colon={false}
          name={['user', 'confirm_password']}
          label="Confirm New Password"
          dependencies={['user', 'new_password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Confirm your new password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  getFieldValue(['user', 'new_password']) === value
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Password does not match'));
              },
            }),
          ]}
        >
          <StyledPasswordInput allowClear placeholder="Confirm New Password" />
        </Form.Item>
        {/* Form Change Password End */}

        <Col span={22} push={2}>
          <TMText $color={COLORS.TITLE_BLACK}>
            Ensure that these requirements are met:
          </TMText>
          <StyledList>
            <StyledListItem>
              At least 10 characters (and up to 20 characters)
            </StyledListItem>
            <StyledListItem>
              At least one lowercase character and one uppercase character
            </StyledListItem>
            <StyledListItem>
              Include of at least one special character, e.g., ! @ # ?
            </StyledListItem>
          </StyledList>
        </Col>

        <Form.Item wrapperCol={{ span: 17, push: 2 }}>
          <ButtonsContainer>
            <Button
              type="primary"
              loading={isLoading}
              htmlType="submit"
              size="large"
              block
            >
              Save Changes
            </Button>
            <Button
              disabled={isLoading}
              /* TODO: We need to move this style to the main less file and check if we can have a secondary button with those styles */
              style={{
                backgroundColor: '#eff0f6',
                border: '#eff0f6',
                color: '#171717',
              }}
              onClick={onCancel}
              size="large"
              block
            >
              Cancel
            </Button>
          </ButtonsContainer>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 0px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 0 50px;
`;
const StyledList = styled.ul`
  margin: 0;
  padding: 1rem 2rem;
`;
const StyledListItem = styled.li``;
const StyledPasswordInput = styled(Input.Password)`
  border: 1px solid ${COLORS.SOFT_GRAY} !important;
  box-shadow: none;
`;

export default ChangePasswordForm;
