import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import '../../styles/okta.css';
import CONFIG from '../../config';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const OktaSignIn = require('@okta/okta-signin-widget');

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    const widget = new OktaSignIn({
      baseUrl: CONFIG.oktaDetails.baseURL,
      clientId: CONFIG.oktaDetails.client,
      redirectUri: CONFIG.oktaDetails.redirectUri,
      features: {
        registration: false,
        showPasswordToggleOnSignInPage: true,
        router: true,
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        pkce: true,
        issuer: CONFIG.oktaDetails.URL,
        scopes: CONFIG.oktaDetails.scopes,
        display: 'page',
      },
      idpDisplay: 'SECONDARY',
      language: 'en',
    });

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
        scopes: CONFIG.oktaDetails.scopes,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((tokens: any) => {
        // Add tokens to storage
        oktaAuth.handleLoginRedirect(tokens);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        throw err;
      });

    return () => widget.remove();
  }, [oktaAuth]);

  return (
    <section className="login-page">
      <div ref={widgetRef} className="login-container" />
    </section>
  );
};

export default Login;
