import http from '../http';
import { parse } from './Parser';
import type { DBPartnerManager } from './Parser';
import PartnerManagerEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';
import type { PartnerManager } from '@/store/slices/partners';

const GetPartnerManagerByPartnerId = async (partnerId: string) => {
  const { data: pureData } = await http.request({
    url: PartnerManagerEndpoints.getPartnerManagerByPartnerId.endpoint.replace(
      ':partnerId',
      partnerId,
    ),
    method: PartnerManagerEndpoints.getPartnerManagerByPartnerId.method,
  });

  return ResponseParser<DBPartnerManager, PartnerManager>(pureData, parse);
};

export default GetPartnerManagerByPartnerId;
