import http from '../http';
import { parsePartners } from './Parser';
import type { DBPartner } from './Parser';
import PartnerEndpoints from './Endpoints';
import type { PartnerState } from '@/store/slices/partners';
import { ResponseParserList } from '../http/ResponseParser';

const GetPartners = async () => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.getPartners.method,
    url: PartnerEndpoints.getPartners.endpoint,
    params: {
      noPagination: true,
    },
  });

  return ResponseParserList<DBPartner, PartnerState['partners'][0]>(
    pureResponse,
    parsePartners,
  );
};

export default GetPartners;
