import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import {
  deactivateUser,
  reactivateUser,
  sendAccountReminderEmail,
} from '../../../../store/thunks/users';
import { useIsUserLoadingById } from '../../../../store/selectors/users';
import type { DBUsers } from '../../../../services/users/Parser';
import UserManagementContext from '../../Context';

interface Return {
  actionName: 'deactivate' | 'reactivate' | 'resend email';
  isUserLoading: boolean;
  action: () => void;
}

const useAllowedAccountAction = (
  currentAccountState: DBUsers['ACCOUNT_STATUS'],
  userId: string,
): Return => {
  const dispatch = useDispatch();
  const isUserLoading = useIsUserLoadingById(userId);
  const {
    accountStatusFilter,
    currentPage,
    setCurrentPage,
    searchValue,
    planFilter,
    nameSorter,
    lastActivitySorter,
  } = useContext(UserManagementContext);

  const getActionName = () => {
    if (currentAccountState === 'ACTIVE') return 'deactivate';
    if (currentAccountState === 'DEACTIVATED') return 'reactivate';
    return 'resend email';
  };

  const getAction = () => {
    if (currentAccountState === 'ACTIVE')
      return () =>
        dispatch(
          deactivateUser({
            userId,
            filtersForRefreshingData: {
              accountStatusFilter,
              currentPage,
              setCurrentPage,
              searchValue,
              planFilter,
              nameSorter,
              lastActivitySorter,
            },
          }),
        );
    if (currentAccountState === 'DEACTIVATED')
      return () =>
        dispatch(
          reactivateUser({
            userId,
            filtersForRefreshingData: {
              accountStatusFilter,
              currentPage,
              setCurrentPage,
              searchValue,
              planFilter,
              nameSorter,
              lastActivitySorter,
            },
          }),
        );

    return () => dispatch(sendAccountReminderEmail(userId));
  };

  return {
    actionName: getActionName(),
    action: getAction(),
    isUserLoading,
  };
};

export default useAllowedAccountAction;
