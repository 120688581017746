import { Select } from 'antd';
import type { VFC } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type { DefaultOptionType, SelectProps } from 'antd/lib/select';

import {
  usePlanDetails,
  useIsLoadingPlanDetails,
} from '../../../store/selectors/planDetails';
import COLORS from '../../../styles/Colors';
import FlexContainer from '../flex/FlexContainer';
import { capitalize } from '../../../utils/capitalize';
import type { Plan } from '../../../store/slices/plans';
import { loadPlans } from '../../../store/thunks/plans';
import { useContractId } from '../../../store/selectors/contracts';
import { useIsLoadingPlans, usePlans } from '../../../store/selectors/plans';
import { getPlanDetailsByContract } from '../../../store/thunks/planDetails';

interface Props {
  disabled?: boolean;
  placeholder?: string;
  placeholderWhenLoading?: string;
  onChange?: SelectProps['onChange'];
  launchRequest?: boolean;
  usePlanKeysAsValues?: boolean;
  initialValue?: SelectProps['value'];
  showSeatsCapacity?: boolean;
  isLoading?: boolean;
  useAlternativeDesign?: true;
  disablePlansWithNoSeatsAvailable?: true;
}

const SelectPlan: VFC<Props> = ({
  placeholder = 'Select a plan',
  placeholderWhenLoading = 'Retrieving plans...',
  onChange,
  usePlanKeysAsValues,
  launchRequest = true,
  initialValue = null,
  showSeatsCapacity,
  isLoading,
  useAlternativeDesign,
  disablePlansWithNoSeatsAvailable,
  disabled,
}) => {
  if (disablePlansWithNoSeatsAvailable && !showSeatsCapacity) {
    throw new Error(
      'showSeatsCapacity required when disablePlansWithNoSeatsAvailable is present',
    );
  }

  const [value, setValue] = useState<SelectProps['value']>(null);

  const dispatch = useDispatch();
  const isLoadingPlans = useIsLoadingPlans();
  const isLoadingPlanDetails = useIsLoadingPlanDetails();
  const contractId = useContractId();
  const plansDetails = usePlanDetails();
  const plans = usePlans();

  useEffect(() => {
    if (plans.length <= 0 && !isLoadingPlans && launchRequest) {
      dispatch(loadPlans());
    } else if (plans.length > 0 && initialValue) {
      setValue(initialValue);
    }
  }, [plans, dispatch, isLoadingPlans, launchRequest, initialValue]);

  useEffect(() => {
    if (
      plansDetails.length <= 0 &&
      !isLoadingPlanDetails &&
      launchRequest &&
      showSeatsCapacity &&
      contractId
    ) {
      dispatch(getPlanDetailsByContract(contractId));
    }
  }, [
    plansDetails,
    dispatch,
    isLoadingPlanDetails,
    launchRequest,
    showSeatsCapacity,
    contractId,
  ]);

  const handleChange = (
    changeValue: string,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    setValue(changeValue);
    if (onChange) onChange(changeValue, option);
  };

  const parseIsLoading = () => {
    if (showSeatsCapacity) {
      return isLoadingPlans || isLoadingPlanDetails || isLoading;
    }
    return isLoadingPlans || isLoading;
  };

  const mustOptionBeDisabled = (plan: Plan): boolean => {
    if (!disablePlansWithNoSeatsAvailable) return false;
    return (
      (plansDetails.find(
        (details) => Number(details.planId) === Number(plan.id),
      )?.seats.available ?? 0) <= 0
    );
  };

  return (
    <CustomizingSelectDiv>
      <Select
        className={useAlternativeDesign && 'alternative-select-design'}
        placeholder={parseIsLoading() ? placeholderWhenLoading : placeholder}
        disabled={parseIsLoading() || disabled}
        loading={parseIsLoading()}
        onChange={handleChange}
        value={value}
        style={{ width: '100%' }}
      >
        {plans.map((plan) => (
          <Select.Option
            key={plan.id}
            value={usePlanKeysAsValues ? plan.planKey : `${plan.id}`}
            disabled={mustOptionBeDisabled(plan)}
          >
            <FlexContainer $direction="column">
              <span>{capitalize(plan.name)}</span>
              {showSeatsCapacity && (
                <AvailableSeatsSpan>
                  {plansDetails
                    .find(
                      (details) => Number(details.planId) === Number(plan.id),
                    )
                    ?.seats.available.toLocaleString('EN')}{' '}
                  left
                </AvailableSeatsSpan>
              )}
            </FlexContainer>
          </Select.Option>
        ))}
      </Select>
    </CustomizingSelectDiv>
  );
};

const CustomizingSelectDiv = styled.div`
  .alternative-select-design .ant-select-selector {
    background: #fff !important;
    border: 1px solid ${COLORS.BOTTICELLI} !important;
    border-radius: 5px !important;
  }
`;

const AvailableSeatsSpan = styled.span`
  color: ${COLORS.JUNGLE_GREEN};
  font-size: 12px;
  position: relative;
  bottom: 3px;
`;

export default SelectPlan;
