import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Security } from '@okta/okta-react';
import type { OktaAuth } from '@okta/okta-auth-js';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { BrowserRouter, useNavigate } from 'react-router-dom';

import './styles/index.less';
import store from './store';
import AppRouter from './routes';
import OktaInstance from './config/OktaInstance';
import UnderConstruction from './components/UnderConstruction';

const App = () => {
  const navigate = useNavigate();

  const onRestore = useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    },
    [navigate],
  );

  return (
    <Provider store={store}>
      <Security oktaAuth={OktaInstance} restoreOriginalUri={onRestore}>
        {window.location.origin === 'https://beta-partners.tokenmetrics.com' ? (
          <UnderConstruction />
        ) : (
          <AppRouter />
        )}
      </Security>
    </Provider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
