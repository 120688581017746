import type { FC } from 'react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import SelectPlan from '../../common/inputs/SelectPlan';
import { changeUserPlan } from '../../../store/thunks/users';
import {
  useIsAnyUserWritingPlan,
  useIsUserWritingPlanById,
} from '../../../store/selectors/users';
import UserManagementContext from '../Context';

interface Props {
  launchRequest?: boolean;
  initialPlanId: string;
  userId: string;
  useAlternativeDesign?: true;
  disablePlansWithNoSeatsAvailable?: true;
}

const ChangePlanInput: FC<Props> = ({
  launchRequest,
  initialPlanId: planId,
  userId,
  useAlternativeDesign,
  disablePlansWithNoSeatsAvailable,
}) => {
  const dispatch = useDispatch();
  const isWritingPlan = useIsUserWritingPlanById(userId);
  const isAnyUserWritingPlan = useIsAnyUserWritingPlan();

  const {
    currentPage,
    accountStatusFilter,
    planFilter,
    searchValue,
    setCurrentPage,
    nameSorter,
    lastActivitySorter,
  } = useContext(UserManagementContext);

  return (
    <SelectPlan
      disabled={isAnyUserWritingPlan}
      isLoading={isWritingPlan}
      launchRequest={launchRequest}
      initialValue={planId}
      showSeatsCapacity
      onChange={(value) =>
        dispatch(
          changeUserPlan({
            planId: value,
            userId,
            filtersForRefreshingData: {
              currentPage,
              accountStatusFilter,
              planFilter,
              searchValue,
              setCurrentPage,
              nameSorter,
              lastActivitySorter,
            },
          }),
        )
      }
      useAlternativeDesign={useAlternativeDesign}
      disablePlansWithNoSeatsAvailable={disablePlansWithNoSeatsAvailable}
    />
  );
};

export default ChangePlanInput;
