import http from '../http';
import { multiParse } from './Parser';
import PartnerEndpoints from './Endpoints';
import type { DBInvoice } from './Parser';
import { ResponseParserList } from '../http/ResponseParser';
import type { Invoice } from '@/store/slices/invoices';

const GetInvoicesByPartnerId = async (partnerId: number) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.getInvoicesByPartnerId.method,
    url: PartnerEndpoints.getInvoicesByPartnerId.endpoint.replace(
      ':partnerId',
      `${partnerId}`,
    ),
    params: {
      page: 1,
      limit: 10,
    },
  });

  return ResponseParserList<DBInvoice, Invoice>(pureResponse, multiParse);
};

export default GetInvoicesByPartnerId;
