import type { AuthState, UserClaims } from '@okta/okta-auth-js';
import { OktaAuth } from '@okta/okta-auth-js';

import CONFIG from '.';

interface CustomUserClaims extends UserClaims {
  company: string | null;
  partnerId: number | null;
  phoneNumber: number | null;
  isSuperAdmin: boolean;
}

export interface CustomAuthState extends AuthState {
  user: CustomUserClaims | null;
}

const OktaInstance = new OktaAuth({
  issuer: CONFIG.oktaDetails.URL,
  redirectUri: CONFIG.oktaDetails.redirectUri,
  postLogoutRedirectUri: `${window.location.origin}/login`,
  clientId: CONFIG.oktaDetails.client,
  pkce: true,
  scopes: CONFIG.oktaDetails.scopes,
  transformAuthState: async (oktaAuth, authState): Promise<CustomAuthState> => {
    if (!authState.isAuthenticated) return { authState, user: null };

    const user = (await oktaAuth.token.getUserInfo()) as CustomUserClaims;

    return { ...authState, user };
  },
});

export default OktaInstance;
