import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

import COLORS from '../../../styles/Colors';
import MagnifyingGlass from '../../../assets/icons/MagnifyingGlass';

interface Props {
  placeholder?: string;
  value?: string | number;
  onChange?: (value: string) => void;
  onPressEnter?: () => void;
}
interface StyleProps {
  $marginY?: number;
}

const SearchInput: React.FC<Props & StyleProps> = ({
  onPressEnter,
  placeholder = 'Enter Keywords ...',
  value,
  onChange,
  ...styleProps
}) => {
  return (
    <StyledInput
      value={value}
      onChange={(event) => onChange && onChange(event.target.value)}
      prefix={<MagnifyingGlass />}
      placeholder={placeholder}
      onPressEnter={onPressEnter}
      {...styleProps}
    />
  );
};

const StyledInput = styled(Input)<StyleProps>`
  background: ${COLORS.SOFT_GRAY};
  border-radius: 10px;
  border: 0;
  width: 318px;
  padding: 0.5rem 0.8rem;

  input {
    background: ${COLORS.SOFT_GRAY};
    margin-left: 20px;
  }

  margin-top: ${(props) => props.$marginY && `${props.$marginY}px`};
`;

export default SearchInput;
