import { Col, Row } from 'antd';
import Content from '../Dashboard/Layout/Content';
import PlanOverview from '../../components/ManageYourPlan/PlanOverview';
import BillingHistory from '../../components/common/cards/BillingHistory';
import PaymentSummary from '../../components/ManageYourPlan/PaymentSummary';
import PaymentMethodTile from '../../components/ManageYourPlan/PaymentMethod';
import PaymentMethodContextProvider from '../../components/ManageYourPlan/PaymentMethod/Context/Provider';

const ManageYourPlan = () => {
  return (
    <Content title="Manage Your Plan">
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <PlanOverview />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <PaymentMethodContextProvider>
            <PaymentMethodTile />
          </PaymentMethodContextProvider>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
          <PaymentSummary />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
          <BillingHistory noHeight />
        </Col>
      </Row>
    </Content>
  );
};

export default ManageYourPlan;
