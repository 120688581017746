import type { VFC } from 'react';
import styled from 'styled-components';
import TextSizes from '../../../styles/TextSizes';
import { TMTitle, TMText } from '../../common/typography';
import COLORS from '../../../styles/Colors';
import { capitalize } from '../../../utils/capitalize';

interface Props {
  planName: string;
  assignedSeats: number;
  totalSeats: number;
  marginBottom?: number;
}

const PlanSeatsDetail: VFC<Props> = ({
  planName,
  assignedSeats,
  totalSeats,
  marginBottom = 0,
}) => {
  return (
    <Container marginBottom={marginBottom}>
      <StyledRow>
        <TMTitle level={4}>{capitalize(planName)}</TMTitle>
        <TMText $size={TextSizes.SMALL}>{assignedSeats} Assigned</TMText>
      </StyledRow>
      <StyledRow>
        <TMText $size={TextSizes.SMALL}>{totalSeats} Seats Total</TMText>
        <TMText $color={COLORS.GREEN} $bold $size={TextSizes.SMALL}>
          {totalSeats - assignedSeats} Available
        </TMText>
      </StyledRow>
    </Container>
  );
};

const Container = styled.div<Pick<Props, 'marginBottom'>>((props) => ({
  marginBottom: props.marginBottom,
}));

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export default PlanSeatsDetail;
