import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const ChartBars: VFC<IconInterface> = ({ color = COLORS.GRAY }) => (
  <svg
    width="16"
    height="23"
    viewBox="0 0 16 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 21.1666C14 21.7189 14.4477 22.1666 15 22.1666C15.5523 22.1666 16 21.7189 16 21.1666H14ZM16 9.08325C16 8.53097 15.5523 8.08325 15 8.08325C14.4477 8.08325 14 8.53097 14 9.08325H16ZM7 21.1666C7 21.7189 7.44772 22.1666 8 22.1666C8.55228 22.1666 9 21.7189 9 21.1666H7ZM9 1.83325C9 1.28097 8.55228 0.833252 8 0.833252C7.44772 0.833252 7 1.28097 7 1.83325H9ZM0 21.1666C0 21.7189 0.447715 22.1666 1 22.1666C1.55228 22.1666 2 21.7189 2 21.1666H0ZM2 13.9166C2 13.3643 1.55228 12.9166 1 12.9166C0.447715 12.9166 0 13.3643 0 13.9166H2ZM16 21.1666V9.08325H14V21.1666H16ZM9 21.1666V1.83325H7V21.1666H9ZM2 21.1666V13.9166H0V21.1666H2Z"
      fill={color}
    />
  </svg>
);

export default ChartBars;
