import type { FC } from 'react';
import styled from 'styled-components';
import { AiFillWarning } from 'react-icons/ai';
import { List, Modal, Typography } from 'antd';

import COLORS from '../../../styles/Colors';
import FlexContainer from '../flex/FlexContainer';
import { TMText } from '../typography';

interface Props {
  message?: string;
  subtitle?: string;
  isVisible: boolean;
  hideHandler: () => void;
  errors: string[];
}

const ErrorModal: FC<Props> = ({
  isVisible,
  hideHandler,
  message = 'There was an error',
  subtitle = 'Something went wrong',
  errors = [],
}) => {
  return (
    <Modal
      centered
      destroyOnClose
      footer={null}
      visible={isVisible}
      onCancel={hideHandler}
    >
      <FlexContainer $direction="column" $align="center" $textAlign="center">
        <StyledIcon />
        <Typography.Title level={3}>{message}</Typography.Title>
        <Typography.Text type="danger">{subtitle}</Typography.Text>
      </FlexContainer>

      <StyledList
        bordered
        dataSource={errors}
        renderItem={(item) => (
          <List.Item>
            <TMText>{item as string}</TMText>
          </List.Item>
        )}
      />
    </Modal>
  );
};

const StyledList = styled(List)`
  margin-top: 20px;
`;

const StyledIcon = styled(AiFillWarning)`
  font-size: 60px;
  margin-bottom: 20px;
  color: ${COLORS.DANGER};
`;

export default ErrorModal;
