enum COLORS {
  WHITE = '#FFF',
  SUCCESS = '#4BDE97',
  GREEN = '#02962C',
  DANGER = '#F26464',
  SOFT_GRAY = '#F5F7FE',
  TITLE_BLACK = '#171717',
  SUBTITLE_BLACK = '#545454',
  TEXT_GRAY = '#7A7A7A',
  GRAY = '#737898',
  WAIKAWA_GRAY = '#616FA4',
  ATHENS_GRAY = '#E0E1EA',
  JUNGLE_GREEN = '#2DBA69',
  ALPHA_JUNGLE_GREEN = '#2DBA6922',
  MUSTARD = '#FED44F',
  ALPHA_MUSTARD = '#FED44F22',
  CERULEAN_BLUE = '#2F49D1',
  ALPHA_CERULEAN_BLUE = '#2F49D122',
  SUN_GLOW = '#FFCF35',
  YELLOW_ORANGE = '#FFB648',
  ALPHA_YELLOW_ORANGE = '#FFB64822',
  ORANGE = '#FF9635',
  BOTTICELLI = '#DADEEC',
  // Sidebar background
  SELAGO = '#F5F7FE',
}

export default COLORS;
