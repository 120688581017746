import { useState } from 'react';
import styled from 'styled-components';

import ChangePasswordForm from './ChangePasswordForm';
import PasswordInformation from './PasswordInformation';

const ChangePassword = () => {
  const [isChanging, setIsChanging] = useState<boolean>(false);

  const onChangePassword = () => setIsChanging(!isChanging);

  return (
    <Container>
      {isChanging ? (
        <ChangePasswordForm onCancel={onChangePassword} />
      ) : (
        <PasswordInformation onChange={onChangePassword} />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 0;
`;

export default ChangePassword;
