import type { FC } from 'react';
import { useState, useMemo } from 'react';

import Context from '.';
import type { ContextValue } from '.';

const UserManagementContextProvider: FC = ({ children }) => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [planFilter, setPlanFilter] = useState('none');
  const [accountStatusFilter, setAccountStatusFilter] = useState('none');
  const [nameSorter, setNameSorter] = useState<ContextValue['nameSorter']>();
  const [lastActivitySorter, setLastActivitySorter] =
    useState<ContextValue['lastActivitySorter']>();

  const value = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      currentPage,
      setCurrentPage,
      planFilter,
      setPlanFilter,
      accountStatusFilter,
      setAccountStatusFilter,
      nameSorter,
      setNameSorter,
      lastActivitySorter,
      setLastActivitySorter,
    }),
    [
      searchValue,
      setSearchValue,
      currentPage,
      setCurrentPage,
      planFilter,
      setPlanFilter,
      accountStatusFilter,
      setAccountStatusFilter,
      nameSorter,
      setNameSorter,
      lastActivitySorter,
      setLastActivitySorter,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default UserManagementContextProvider;
