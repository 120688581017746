import type { SetupIntent } from '@stripe/stripe-js';
import { shallowEqual, useSelector } from 'react-redux';

import type {
  Partner,
  PartnerManager,
  PartnerPayment,
  PartnerState,
} from '../slices/partners';
import type RootState from '../types/RootState';
import type ErrorStoreType from '../types/ErrorStoreType';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

const isLoadingPartner = (state: RootState): boolean => {
  return state.partners.loadingStatus === LOADING_STATUSES.LOADING;
};

const partnerSelector = (state: RootState): Partner | null =>
  state.partners.partner;

const isWritingPartner = (state: RootState): boolean => {
  return state.partners.writeStatus === LOADING_STATUSES.LOADING;
};

const isWritingPartnerFinished = (state: RootState): boolean => {
  return (
    state.partners.writeStatus === LOADING_STATUSES.FULFILLED ||
    state.partners.writeStatus === LOADING_STATUSES.FAILED
  );
};

const isLoadingPartnerFinished = (state: RootState): boolean => {
  return (
    state.partners.loadingStatus === LOADING_STATUSES.FULFILLED ||
    state.partners.loadingStatus === LOADING_STATUSES.FAILED
  );
};

const partnerManagerSelector = (state: RootState): PartnerManager | null =>
  state.partners.partnerManager;

const isLoadingPaymentMethod = (state: RootState): boolean => {
  return state.partners.loadingPaymentStatus === LOADING_STATUSES.LOADING;
};

const isLoadingPaymentMethodFinished = (state: RootState): boolean =>
  [LOADING_STATUSES.FULFILLED, LOADING_STATUSES.FAILED].includes(
    state.partners.loadingUpdatePayment,
  );

const paymentMethodSelector = (state: RootState): PartnerPayment | null =>
  state.partners.paymentMethod;

const setupIntentSelector = (state: RootState): SetupIntent | null =>
  state.partners.setupIntent;

const isLoadingSetupIntent = (state: RootState): boolean => {
  return state.partners.setupIntentLoadingStatus === LOADING_STATUSES.LOADING;
};

const isCreatingSetupIntent = (state: RootState): boolean => {
  return state.partners.setupIntentCreatingStatus === LOADING_STATUSES.LOADING;
};

const isCreatingSetupIntentSuccess = (state: RootState): boolean => {
  return (
    state.partners.setupIntentCreatingStatus === LOADING_STATUSES.FULFILLED &&
    state.partners.createSetupIntentError === null
  );
};

const createSetupIntentError = (state: RootState): string | null =>
  state.partners.createSetupIntentError;

const isLoadingChargeMethod = (state: RootState): boolean => {
  return state.partners.loadingChargeMethodStatus === LOADING_STATUSES.LOADING;
};

const partnersSelector = (state: RootState): PartnerState['partners'] =>
  state.partners.partners;

const partnersLoadingStatusSelector = (state: RootState): LOADING_STATUSES =>
  state.partners.loadPartnersStatus;

const partnersErrorSelector = (state: RootState): ErrorStoreType =>
  state.partners.loadPartnersError;

export const usePartnerManager = () =>
  useSelector(partnerManagerSelector, shallowEqual);

export const useIsLoadingPartnerFinished = () =>
  useSelector(isLoadingPartnerFinished);

export const useIsLoadingPartner = () => useSelector(isLoadingPartner);

export const usePartner = () => useSelector(partnerSelector, shallowEqual);

export const usePartnerId = () =>
  useSelector((state: RootState) => state.partners.partner?.id);

export const usePartnerName = () =>
  useSelector((state: RootState) => state.partners.partner?.name);

export const useIsWritingPartner = () => useSelector(isWritingPartner);

export const usePartnerLogoUrl = () =>
  useSelector((state: RootState) => state.partners.partner?.logoUrl);

export const useIsWritingPartnerFinished = () =>
  useSelector(isWritingPartnerFinished);

export const useIsLoadingPaymentMethod = () =>
  useSelector(isLoadingPaymentMethod);

export const useIsLoadingPaymentMethodFinished = () =>
  useSelector(isLoadingPaymentMethodFinished);

export const usePaymentMethod = () =>
  useSelector(paymentMethodSelector, shallowEqual);

export const useIsLoadingSetupIntent = () => useSelector(isLoadingSetupIntent);

export const useIsCreatingSetupIntent = () =>
  useSelector(isCreatingSetupIntent);

export const useIsCreatingSetupIntentSuccess = () =>
  useSelector(isCreatingSetupIntentSuccess);

export const useCreateSetupIntentError = () =>
  useSelector(createSetupIntentError);

export const useSetupIntent = () =>
  useSelector(setupIntentSelector, shallowEqual);

export const useIsLoadingChargeMethod = () =>
  useSelector(isLoadingChargeMethod);

export const usePartners = () => useSelector(partnersSelector, shallowEqual);

export const usePartnersLoadStatus = () =>
  useSelector(partnersLoadingStatusSelector);

export const usePartnersError = () =>
  useSelector(partnersErrorSelector, shallowEqual);
