import type { FC } from 'react';
import styled from 'styled-components';

import COLORS from '../../../styles/Colors';
import TextSizes from '../../../styles/TextSizes';

interface Props {
  href?: string;
  mailTo?: string;
  blank?: boolean;
  className?: string;
}

interface StyleProps {
  $fontSize?: number;
  $marginLeft?: number;
  $color?: COLORS;
  $inline?: boolean;
  $verticalAligned?: boolean;
  $marginRight?: number;
  $bold?: boolean;
}

const TMAnchor: FC<Props & StyleProps> = ({
  href,
  children,
  blank,
  className,
  mailTo,
  ...styleProps
}) => {
  return (
    <StyledAnchor
      target={blank ? '_blank' : ''}
      href={mailTo ? `mailto:${mailTo}` : href}
      className={className}
      {...styleProps}
    >
      {children}
    </StyledAnchor>
  );
};

const StyledAnchor = styled.a<StyleProps>(
  ({
    $color = COLORS.TEXT_GRAY,
    $fontSize = TextSizes.MEDIUM,
    $marginLeft = 0,
    $inline = false,
    $verticalAligned = false,
    $marginRight = 0,
    $bold,
  }) => ({
    fontWeight: $bold ? 'bold' : 'normal',
    textDecoration: 'underline !important',
    color: `${$color} !important`,
    marginLeft: `${$marginLeft}px !important`,
    fontSize: `${$fontSize}px !important`,
    display: (() => {
      if ($verticalAligned) return 'flex';
      if ($inline) return 'inline-flex';
      return 'inline';
    })(),
    alignItems: `${$verticalAligned && 'center'}`,
    marginRight: `${$marginRight}px`,
  }),
);

export default TMAnchor;
