import styled from 'styled-components';
import Tile from './Tile';

const NoBorderedTile = styled(Tile)`
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 50px #a0a3bd30;

  .ant-card-body {
    padding: 0;
    margin: 0;
  }
`;

export default NoBorderedTile;
