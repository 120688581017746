import { message as antMessage } from 'antd';
import { createAsyncThunk } from '@reduxjs/toolkit';

import type ErrorStoreType from '../types/ErrorStoreType';
import ChangePassword from '../../services/admins/ChangePassword';
import GetPartnerAdmin from '../../services/admins/GetPartnerAdmin';
import UpdatePartnerAdmin from '../../services/admins/UpdatePartnerAdmin';
import UpdateUserDidLogin from '../../services/admins/UpdateUserDidLogin';
import type {
  ChangePasswordPayload,
  PartnerAdmin,
} from '../slices/partnerAdmins';

export const getPartnerAdmin = createAsyncThunk(
  'partnerAdmins/getPartnerAdmin',
  async (oktaUid: string, { rejectWithValue }) => {
    const {
      parsedData: partnerAdmin,
      success,
      message,
      validationErrors,
    } = await GetPartnerAdmin(oktaUid);

    if (success && partnerAdmin) {
      return partnerAdmin;
    }

    throw rejectWithValue({ message, validationErrors });
  },
);

export const updatePartnerAdmin = createAsyncThunk(
  'partnerAdmins/updatePartnerAdmin',
  async (data: PartnerAdmin, { rejectWithValue }) => {
    const {
      parsedData: partnerAdmin,
      message,
      success,
      validationErrors,
    } = await UpdatePartnerAdmin(data);

    if (success && partnerAdmin) {
      return partnerAdmin;
    }

    throw rejectWithValue({ message, validationErrors });
  },
);

export const changePassword = createAsyncThunk(
  'partnerAdmins/changePassword',
  async (data: ChangePasswordPayload, { rejectWithValue }) => {
    const { message, success, validationErrors } = await ChangePassword(data);

    if (success) return antMessage.success(message);

    antMessage.error(message);
    throw rejectWithValue({ message, validationErrors });
  },
);

export const updateUserDidLogin = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorStoreType }
>('partnerAdmins/updateUserDidLogin', async (adminId, { rejectWithValue }) => {
  const { data, message, success, validationErrors } = await UpdateUserDidLogin(
    adminId,
  );

  if (success && data) {
    return data;
  }

  throw rejectWithValue({ message, validationErrors });
});
