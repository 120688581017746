import { Badge, Skeleton } from 'antd';
import styled from 'styled-components';

import TextSizes from '../../../styles/TextSizes';
import { TMText, TMTitle } from '../../common/typography';
import {
  useCurrentMonthActivity,
  useIsUserActivityMetricsLoading,
} from '../../../store/selectors/users';

const RightOptions = () => {
  const isLoadingMetrics = useIsUserActivityMetricsLoading();
  const currentMothActivity = useCurrentMonthActivity();

  return (
    <Container>
      <div>
        <Badge status="success" />
        <TMText $size={TextSizes.MEDIUM}>CURRENT MONTH</TMText>
      </div>
      <QuantityContainer>
        <Skeleton
          loading={isLoadingMetrics}
          title={false}
          paragraph={{ rows: 1 }}
          active
        >
          <TMTitle level={5}>
            {(currentMothActivity ?? 0)
              .toLocaleString('en-US')
              .replace(',', ' ')}
          </TMTitle>
        </Skeleton>
      </QuantityContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuantityContainer = styled.div`
  margin-left: 18px;
`;

export default RightOptions;
