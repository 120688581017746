import http from '../http';
import { parse } from './Parser';
import type { DBPartner } from './Parser';
import PartnerEndpoints from './Endpoints';
import type { Partner } from '@/store/slices/partners';
import { ResponseParser } from '../http/ResponseParser';

const GetPartner = async (partnerId: number) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.getPartner.method,
    url: PartnerEndpoints.getPartner.endpoint.replace(':id', `${partnerId}`),
  });

  return ResponseParser<DBPartner, Partner>(pureResponse, parse);
};

export default GetPartner;
