import type {
  Partner,
  PartnerPayment,
  PartnerState,
} from '../../store/slices/partners';

export interface DBPartner {
  PRTNR_ID: number;
  PRTNR_NAME: string;
  PRTNR_CONTACT_PERSON: string;
  PRTNR_CONTACT_PERSON_EMAIL: string;
  PRTNR_CONTACT_PERSON_PHONE: number;
  PRTNR_ADDR_1: string;
  PRTNR_ADDR_2: string;
  PRTNR_CITY_NM: string;
  PRTNR_STATE_NM: string;
  PRTNR_ZIPCODE: string;
  PRTNR_CNTRY_NM: string;
  PRTNR_LOGO_URL: string;
  STRIPE_CUSTOMER_ID: string;
  STRIPE_SETUP_ID: string;
  CREATED_DT: string;
  UPDATED_DT: string;
}

export interface DBPartnerPayment {
  PRTNR_PAYMENT_ID: string;
  PRTNR_ID: number;
  CC_NAME_ON_CARD: string;
  CC_NBR_LAST4: string;
  CC_BRAND: string;
  CC_EXP_MONTH: string;
  CC_EXP_YEAR: string;
  CC_COUNTRY: string;
  ACH_NAME: string;
  ACH_BNK_RTG_NBR: string;
  ACH_BNK_ACCT_LAST4: string;
  ACH_HOLDER_TYPE: string;
  ACH_ACCT_TYPE: string;
  ACH_BNK_NAME: string;
  SEPA_NAME: string;
  SEPA_EMAIL: string;
  SEPA_BNK_CODE: string;
  SEPA_BRANCH_CODE: string;
  SEPA_COUNTRY: string;
  SEPA_LAST4: string;
  LINE1: string;
  LINE2: string;
  CITY: string;
  STATE: string;
  PAYMENT_METHOD: string;
  CHARGE_METHOD: string;
  ACTIVE_FLAG: boolean;
  CREATED_DT: string;
  UPDATED_DT: string;
}

const PartnerParser = {
  parse: (dbPartner: DBPartner): Partner => ({
    id: dbPartner.PRTNR_ID,
    name: dbPartner.PRTNR_NAME,
    contactPerson: {
      fullname: dbPartner.PRTNR_CONTACT_PERSON,
      email: dbPartner.PRTNR_CONTACT_PERSON_EMAIL,
      phone: dbPartner.PRTNR_CONTACT_PERSON_PHONE,
    },
    customerId: dbPartner.STRIPE_CUSTOMER_ID,
    setupId: dbPartner.STRIPE_SETUP_ID,
    address1: dbPartner.PRTNR_ADDR_1,
    address2: dbPartner.PRTNR_ADDR_2,
    city: dbPartner.PRTNR_CITY_NM,
    state: dbPartner.PRTNR_STATE_NM,
    zipcode: dbPartner.PRTNR_ZIPCODE,
    country: dbPartner.PRTNR_CNTRY_NM,
    logoUrl: dbPartner.PRTNR_LOGO_URL,
  }),
  paymentParse: (dbPayment: DBPartnerPayment): PartnerPayment => ({
    id: dbPayment.PRTNR_PAYMENT_ID,
    type: dbPayment.PAYMENT_METHOD,
    active: dbPayment.ACTIVE_FLAG,
    chargeMethod: dbPayment.CHARGE_METHOD,
    line1: dbPayment.LINE1,
    line2: dbPayment.LINE2,
    city: dbPayment.CITY,
    state: dbPayment.STATE,
    card: {
      name: dbPayment.CC_NAME_ON_CARD,
      last4: dbPayment.CC_NBR_LAST4,
      brand: dbPayment.CC_BRAND,
      expMonth: dbPayment.CC_EXP_MONTH,
      expYear: dbPayment.CC_EXP_YEAR,
      country: dbPayment.CC_COUNTRY,
    },
    ach: {
      holderName: dbPayment.ACH_NAME,
      routingNumber: dbPayment.ACH_BNK_RTG_NBR,
      last4: dbPayment.ACH_BNK_ACCT_LAST4,
      holderType: dbPayment.ACH_HOLDER_TYPE,
      accountType: dbPayment.ACH_ACCT_TYPE,
      bankName: dbPayment.ACH_BNK_NAME,
    },
    sepa: {
      name: dbPayment.SEPA_NAME,
      email: dbPayment.SEPA_EMAIL,
      bankCode: dbPayment.SEPA_BNK_CODE,
      branchCode: dbPayment.SEPA_BRANCH_CODE,
      country: dbPayment.SEPA_COUNTRY,
      last4: dbPayment.SEPA_LAST4,
    },
  }),
  parsePartners: (dbPartners: DBPartner[]): PartnerState['partners'] =>
    dbPartners.map((dbPartner) => ({
      id: dbPartner.PRTNR_ID,
      name: dbPartner.PRTNR_NAME,
      contactPerson: {
        fullname: dbPartner.PRTNR_CONTACT_PERSON,
        email: dbPartner.PRTNR_CONTACT_PERSON_EMAIL,
        phone: dbPartner.PRTNR_CONTACT_PERSON_PHONE,
      },
      customerId: dbPartner.STRIPE_CUSTOMER_ID,
      setupId: dbPartner.STRIPE_SETUP_ID,
      address1: dbPartner.PRTNR_ADDR_1,
      address2: dbPartner.PRTNR_ADDR_2,
      city: dbPartner.PRTNR_CITY_NM,
      state: dbPartner.PRTNR_STATE_NM,
      zipcode: dbPartner.PRTNR_ZIPCODE,
      country: dbPartner.PRTNR_CNTRY_NM,
      logoUrl: dbPartner.PRTNR_LOGO_URL,
    })),
};

export const { parse, paymentParse, parsePartners } = PartnerParser;
export default PartnerParser;
