import COLORS from '../../styles/Colors';
import { TMText } from '../common/typography';
import FlexContainer from '../common/flex/FlexContainer';
import { useTotalUsers } from '../../store/selectors/users';
import BorderedHeader from '../common/headers/BorderedHeader';
import { usePartnerName } from '../../store/selectors/partners';
import { Divider } from '../common/Divider';

const HowToUploadUsers = () => {
  const totalUsers = useTotalUsers();
  const partnerName = usePartnerName();

  return (
    <BorderedHeader $paddingY={10}>
      <FlexContainer $height={32}>
        <TMText $verticalAligned>
          Current Number of Users:
          <TMText $bold $color={COLORS.TITLE_BLACK} $marginX={5}>
            {totalUsers}
          </TMText>
        </TMText>
        <Divider type="vertical" />
        <TMText $verticalAligned>
          Company:
          <TMText $bold $color={COLORS.TITLE_BLACK} $marginX={5}>
            {partnerName}
          </TMText>
        </TMText>
      </FlexContainer>
    </BorderedHeader>
  );
};

export default HowToUploadUsers;
