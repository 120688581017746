import { createAsyncThunk } from '@reduxjs/toolkit';

import type {
  InviteUserForm,
  User,
  UserActivityMetrics,
} from '../slices/users';
import type RootState from '../types/RootState';
import type { PlanDetail } from '../slices/planDetails';
import type ErrorStoreType from '../types/ErrorStoreType';
import InviteUsers from '../../services/users/InviteUsers';
import ProcessBulk from '../../services/users/ProcessBulk';
import DeleteUsers from '../../services/users/DeleteUsers';
import DeactivateUser from '../../services/users/DeactivateUser';
import ReactivateUser from '../../services/users/ReactivateUser';
import ChangeUserPlan from '../../services/users/ChangeUserPlan';
import GetPartnerUsers from '../../services/users/GetPartnerUsers';
import GetActiveProcess from '../../services/users/GetActiveProcess';
import UploadUsersInBulk from '../../services/users/UploadUsersInBulk';
import type { ContextValue } from '../../components/UserManagement/Context';
import GetUsersActivityMetrics from '../../services/users/GetUsersActivityMetrics';
import SendAccountReminderEmail from '../../services/users/SendAccountReminderEmail';

type FiltersForRefreshingData = Pick<
  ContextValue,
  | 'currentPage'
  | 'planFilter'
  | 'accountStatusFilter'
  | 'searchValue'
  | 'setCurrentPage'
  | 'nameSorter'
  | 'lastActivitySorter'
>;

export type GetPartnerUsersParams = Omit<
  Partial<FiltersForRefreshingData>,
  'setCurrentPage'
> & {
  pageSize?: number;
};

export const getPartnerUsers = createAsyncThunk<
  {
    users: User[];
    total: number;
  },
  GetPartnerUsersParams,
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>(
  'users/getPartnerUsers',
  async (
    {
      currentPage = 1,
      pageSize = 10,
      searchValue = undefined,
      planFilter = 'none',
      accountStatusFilter = 'none',
      nameSorter,
      lastActivitySorter,
    },
    { rejectWithValue, getState },
  ) => {
    const partnerId = getState().partners.partner?.id;

    if (!partnerId) {
      throw rejectWithValue({
        message: 'No partner Id',
      });
    }

    const {
      parsedData: users,
      success,
      message,
      validationErrors,
      total,
    } = await GetPartnerUsers({
      currentPage,
      pageSize,
      partnerId,
      planFilter,
      accountStatusFilter,
      nameSorter,
      lastActivitySorter,
      searchValue: searchValue !== '' ? searchValue : undefined,
    });

    if (success && users && typeof total === 'number') {
      return { users, total };
    }

    throw rejectWithValue({
      message,
      validationErrors,
    });
  },
);

export const uploadUsersInBulk = createAsyncThunk<
  void,
  { file: File; partnerId: number },
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>('users/uploadUsersInBulk', async (data, { rejectWithValue, dispatch }) => {
  const {
    success,
    data: uuidFile,
    message,
    validationErrors,
  } = await UploadUsersInBulk(data.file, data.partnerId);
  if (success && uuidFile) {
    ProcessBulk(uuidFile, data.partnerId);
    dispatch(getPartnerUsers({}));
  } else {
    throw rejectWithValue({ message, validationErrors });
  }
});

export const inviteUsers = createAsyncThunk<
  void,
  InviteUserForm[],
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>(
  'users/inviteUsers',
  async (users, { rejectWithValue, getState, dispatch }) => {
    const partnerId = getState().partners.partner?.id;

    if (!partnerId) {
      throw rejectWithValue({
        message: 'No partner id',
      });
    }

    const {
      success,
      data: uuidFile,
      message,
      validationErrors,
    } = await InviteUsers(users, `${partnerId}`);

    if (success && uuidFile) {
      ProcessBulk(uuidFile, partnerId);
      dispatch(getPartnerUsers({}));
    } else {
      throw rejectWithValue({
        message,
        validationErrors,
      });
    }
  },
);

export const getActiveProcess = createAsyncThunk<
  boolean,
  void,
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>('users/getActiveProcess', async (_, { rejectWithValue, getState }) => {
  const partnerId = getState().partners.partner?.id;

  if (!partnerId) {
    throw rejectWithValue({
      message: 'No partner id',
    });
  }

  const { data, success, message, validationErrors } = await GetActiveProcess(
    partnerId,
  );

  if (success && typeof data !== 'undefined') {
    return data;
  }

  throw rejectWithValue({
    message,
    validationErrors,
  });
});

export const getUsersActivityMetrics = createAsyncThunk<
  Pick<
    UserActivityMetrics,
    'activityPerMonth' | 'differenceRespectToPreviousMonth'
  >,
  void,
  { rejectValue: ErrorStoreType }
>('users/getUsersActivityMetrics', async (_, { rejectWithValue }) => {
  const { success, message, validationErrors, data } =
    await GetUsersActivityMetrics();

  if (!success || !data) throw rejectWithValue({ message, validationErrors });

  return {
    activityPerMonth: data.activityPerMonth,
    differenceRespectToPreviousMonth: data.differenceRespectToPreviousMonth,
  };
});

export const deactivateUser = createAsyncThunk<
  void,
  {
    userId: string;
    filtersForRefreshingData: FiltersForRefreshingData;
  },
  { rejectValue: ErrorStoreType; state: RootState }
>(
  'users/deactivateUser',
  async (
    {
      userId,
      filtersForRefreshingData: {
        accountStatusFilter,
        currentPage,
        setCurrentPage,
        searchValue,
        planFilter,
        nameSorter,
        lastActivitySorter,
      },
    },
    { rejectWithValue, getState, dispatch },
  ) => {
    const { message, success, validationErrors } = await DeactivateUser(userId);

    if (!success) {
      throw rejectWithValue({ message, validationErrors });
    }

    if (accountStatusFilter !== 'none') {
      const numberOfUsers = getState().users.users.items.length;
      let pageToRequest = currentPage;

      if (numberOfUsers === 1 && currentPage > 1) {
        pageToRequest -= 1;
        setCurrentPage(pageToRequest);
      }

      dispatch(
        getPartnerUsers({
          currentPage: pageToRequest,
          accountStatusFilter,
          searchValue,
          planFilter,
          nameSorter,
          lastActivitySorter,
        }),
      );
    }
  },
);

export const reactivateUser = createAsyncThunk<
  void,
  { userId: string; filtersForRefreshingData: FiltersForRefreshingData },
  { rejectValue: ErrorStoreType; state: RootState }
>(
  'users/reactivateUser',
  async (
    {
      userId,
      filtersForRefreshingData: {
        accountStatusFilter,
        currentPage,
        setCurrentPage,
        searchValue,
        planFilter,
        nameSorter,
        lastActivitySorter,
      },
    },
    { rejectWithValue, getState, dispatch },
  ) => {
    const { message, success, validationErrors } = await ReactivateUser(userId);

    if (!success) {
      throw rejectWithValue({ message, validationErrors });
    }

    if (accountStatusFilter !== 'none') {
      const numberOfUsers = getState().users.users.items.length;
      let pageToRequest = currentPage;

      if (numberOfUsers === 1 && currentPage > 1) {
        pageToRequest -= 1;
        setCurrentPage(pageToRequest);
      }

      dispatch(
        getPartnerUsers({
          currentPage: pageToRequest,
          accountStatusFilter,
          searchValue,
          planFilter,
          nameSorter,
          lastActivitySorter,
        }),
      );
    }
  },
);

export const sendAccountReminderEmail = createAsyncThunk<
  void,
  string,
  { rejectValue: ErrorStoreType }
>('users/sendAccountReminderEmail', async (userId, { rejectWithValue }) => {
  const { message, success, validationErrors } = await SendAccountReminderEmail(
    userId,
  );

  if (!success) {
    throw rejectWithValue({ message, validationErrors });
  }
});

export const changeUserPlan = createAsyncThunk<
  PlanDetail[],
  {
    planId: string;
    userId: string;
    filtersForRefreshingData: FiltersForRefreshingData;
  },
  { rejectValue: ErrorStoreType; state: RootState }
>(
  'users/changeUserPlan',
  async (
    {
      planId,
      userId,
      filtersForRefreshingData: {
        planFilter,
        currentPage,
        accountStatusFilter,
        searchValue,
        setCurrentPage,
        nameSorter,
        lastActivitySorter,
      },
    },
    { rejectWithValue, dispatch, getState },
  ) => {
    const {
      message,
      success,
      validationErrors,
      parsedData: planDetails,
    } = await ChangeUserPlan(userId, planId);

    if (!success || !planDetails) {
      throw rejectWithValue({ message, validationErrors });
    }

    if (planFilter !== 'none') {
      const numberOfUsers = getState().users.users.items.length;
      let pageToRequest = currentPage;

      if (numberOfUsers === 1 && currentPage > 1) {
        pageToRequest -= 1;
        setCurrentPage(pageToRequest);
      }

      dispatch(
        getPartnerUsers({
          currentPage: pageToRequest,
          accountStatusFilter,
          searchValue,
          planFilter,
          nameSorter,
          lastActivitySorter,
        }),
      );
    }

    return planDetails;
  },
);

export const deleteUsers = createAsyncThunk<
  PlanDetail[],
  { oktaUids: string[]; filtersForRefreshingData: FiltersForRefreshingData },
  { rejectValue: ErrorStoreType; state: RootState }
>(
  'users/deleteUsers',
  async (
    {
      oktaUids,
      filtersForRefreshingData: {
        planFilter,
        currentPage,
        accountStatusFilter,
        searchValue,
        setCurrentPage,
        nameSorter,
        lastActivitySorter,
      },
    },
    { rejectWithValue, dispatch, getState },
  ) => {
    const { message, success, validationErrors, parsedData } =
      await DeleteUsers(oktaUids);

    if (!success || !parsedData) {
      throw rejectWithValue({ message, validationErrors });
    }

    const numberOfUsers = getState().users.users.items.length;
    let pageToRequest = currentPage;

    if (numberOfUsers === 1 && currentPage > 1) {
      pageToRequest -= 1;
      setCurrentPage(pageToRequest);
    }

    dispatch(
      getPartnerUsers({
        currentPage: pageToRequest,
        accountStatusFilter,
        searchValue,
        planFilter,
        nameSorter,
        lastActivitySorter,
      }),
    );

    return parsedData;
  },
);
