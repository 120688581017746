import type { VFC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import LoadingPage from '../../components/LoadingPage';
import useOktaAuthCustom from '../../hooks/useOktaAuthCustom';

const IsSuperAdmin: VFC = () => {
  const authState = useOktaAuthCustom();

  if (authState === null) return <LoadingPage />;

  if (authState.user?.isSuperAdmin) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default IsSuperAdmin;
