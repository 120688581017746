import type { VFC } from 'react';
import { Image } from 'antd';
import styled from 'styled-components';

import { TMTitle, TMText } from '../../../common/typography';
import TextSizes from '../../../../styles/TextSizes';
import COLORS from '../../../../styles/Colors';
import type { PlanDetail } from '../../../../store/slices/planDetails';

interface Props {
  image: string;
  title: string;
  plan: PlanDetail | undefined;
}

const PlanItem: VFC<Props> = ({ image, title, plan }) => {
  return (
    <Container>
      <Image width={100} src={image} preview={false} />
      <InfoContainer>
        <TMTitle level={4}>{title}</TMTitle>
        <TMText $color={COLORS.TITLE_BLACK}>
          {plan?.seats.allocated.toLocaleString('en') || 0} Seats Total
        </TMText>
      </InfoContainer>
      <InfoContainer>
        <TMText $size={TextSizes.SMALL}>
          {plan?.seats.used.toLocaleString('en') || 0} Assigned
        </TMText>
        <TMText $size={TextSizes.SMALL} $color={COLORS.GREEN} $bold $top={4}>
          {plan?.seats.available.toLocaleString('en') || 0} Available
        </TMText>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default PlanItem;
