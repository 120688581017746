import { useState, useCallback, useEffect } from 'react';
import { Button, Skeleton, Tag } from 'antd';
import styled from 'styled-components';
import { addDays, endOfMonth, format } from 'date-fns';

import COLORS from '../../../styles/Colors';
import { TMText, TMTitle } from '../../common/typography';
import SmallRocket from '../../../assets/icons/SmallRocket';
import CircleIconContainer from '../../common/CircleIconContainer';
import BorderRoundedDivider from '../../common/Divider/BorderRoundedDivider';
import {
  useContract,
  useIsLoadingContract,
} from '../../../store/selectors/contracts';
import { usePlans, useIsLoadingPlans } from '../../../store/selectors/plans';
import {
  usePlanDetails,
  useIsLoadingPlanDetails,
} from '../../../store/selectors/planDetails';
import { useColorInvoice, useInvoice } from '../../../store/selectors/invoices';
import { capitalize } from '../../../utils/capitalize';

const PaymentSummary = () => {
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const contract = useContract();
  const plans = usePlans();
  const planDetails = usePlanDetails();
  const isContractLoading = useIsLoadingContract();
  const isPlansLoading = useIsLoadingPlans();
  const isPlanDetailsLoading = useIsLoadingPlanDetails();
  const invoice = useInvoice();
  const invoiceColor = useColorInvoice();

  const calculateTotalAmount = useCallback(() => {
    return plans.reduce((price, plan) => {
      const planDetail = planDetails.find(
        (detail) => Number(plan.id) === detail.planId,
      );
      const totalSeats = planDetail?.seats.allocated ?? 0;
      const discount = (planDetail?.discountApplied ?? 0) / 100;
      const currenAmount = totalSeats * plan.price;
      const currentPrice = currenAmount - currenAmount * discount;

      return price + currentPrice;
    }, 0);
  }, [plans, planDetails]);

  useEffect(() => {
    if (plans.length && planDetails.length) {
      setTotalAmount(calculateTotalAmount());
    }
  }, [plans, planDetails, calculateTotalAmount]);

  const expiration = contract?.expireDate
    ? new Date(contract.expireDate)
    : new Date();
  const expirationFormat = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'long',
    timeZone: 'UTC',
  }).format(expiration);
  const nextCharge = addDays(endOfMonth(new Date()), 1);
  const nextChargeFormat = format(nextCharge, 'PP');
  const isLoading = isContractLoading || isPlansLoading || isPlanDetailsLoading;

  return (
    <Container>
      <CircleIconContainer color={COLORS.ALPHA_JUNGLE_GREEN}>
        <SmallRocket color={COLORS.JUNGLE_GREEN} />
      </CircleIconContainer>
      <ContentContainer>
        <Skeleton active loading={isLoading}>
          <TMTitle level={4}>
            ${(totalAmount * 12).toLocaleString('en')} per year
          </TMTitle>

          <TMTitle level={5} $marginTop={5}>
            Contract Details
          </TMTitle>
          <BorderRoundedDivider $color={COLORS.WAIKAWA_GRAY} />

          <TMText $color={COLORS.TITLE_BLACK}>
            Your contract renews on{' '}
            <TMText $color={COLORS.TITLE_BLACK} $bold>
              {expirationFormat}
            </TMText>
          </TMText>

          <TMTitle level={5} $marginTop={1}>
            Billing Details
          </TMTitle>
          <BorderRoundedDivider $color={COLORS.WAIKAWA_GRAY} />

          <TMText $color={COLORS.TITLE_BLACK}>Billing Cycle: Monthly</TMText>
          <TMText $color={COLORS.TITLE_BLACK}>
            Next Charge: {nextChargeFormat}
          </TMText>

          {invoice && (
            <>
              <TMTitle level={5} $marginTop={1}>
                Billing Status
              </TMTitle>
              <BorderRoundedDivider $color={COLORS.WAIKAWA_GRAY} />
              <StyledTag color={invoiceColor}>
                {capitalize(invoice.status)}
              </StyledTag>
            </>
          )}
        </Skeleton>

        <StyledButton
          size="large"
          href="https://help.tokenmetrics.com/en/"
          target="_blank"
        >
          Contact Support
        </StyledButton>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled(Container)`
  margin-top: 20px;
  gap: 10px 0;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  background-color: ${COLORS.BOTTICELLI};
  border: none;

  &:hover {
    color: inherit;
    background-color: ${COLORS.SOFT_GRAY};
  }
`;

const StyledTag = styled(Tag)`
  margin: 5px 0;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  border: 0;
`;

export default PaymentSummary;
