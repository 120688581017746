import { Table, Tooltip } from 'antd';
import type { TableProps } from 'antd';
import { formatDistanceToNow } from 'date-fns';
import { useDispatch } from 'react-redux';
import { FiTrash2 } from 'react-icons/fi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useContext, useEffect, useRef, useState } from 'react';

import {
  useUsers,
  useTotalUsers,
  useIsLoadingUsers,
  useActiveProcess,
} from '../../store/selectors/users';
import {
  usePartnerId,
  useIsLoadingPartner,
} from '../../store/selectors/partners';
import COLORS from '../../styles/Colors';
import { TMText } from '../common/typography';
import UserManagementContext from './Context';
import PlanNameColumn from './PlanNameColumn';
import ChangePlanInput from './ChangePlanInput';
import PlanFilter from './TableFilters/PlanFilter';
import { capitalize } from '../../utils/capitalize';
import useDeleteUsers from './hooks/useDeleteUsers';
import type { User } from '../../store/slices/users';
import DeleteUserCheckbox from './DeleteUserCheckbox';
import FlexContainer from '../common/flex/FlexContainer';
import PollingFunction from '../../utils/PollingFunction';
import AccountStatusOptions from './AccountStatusOptions';
import DeleteConfirmation from '../common/modals/DeleteConfirmation';
import AccountStatusFilter from './TableFilters/AccountStatusFilter';
import { getActiveProcess, getPartnerUsers } from '../../store/thunks/users';
import useOktaAuthCustom from '../../hooks/useOktaAuthCustom';

const UsersTable = () => {
  const dispatch = useDispatch();
  const authState = useOktaAuthCustom();

  const {
    updateOktaUserIdsToBeDeleted,
    isOktaUserIdMarked,
    dispatchDelete: dispatchDeleteUsers,
    areThereSelectedUsers,
    isDeleting: isDeletingUsers,
    deletingIsFinished,
  } = useDeleteUsers();

  const [isVisibleDeleteConfirmation, setIsVisibleDeleteConfirmation] =
    useState(false);

  const partnerId = usePartnerId();
  const activeProcess = useActiveProcess();
  const users = useUsers();
  const total = useTotalUsers();

  const isLoadingUsers = useIsLoadingUsers();
  const isPartnerLoading = useIsLoadingPartner();

  const {
    searchValue,
    currentPage,
    setCurrentPage,
    setSearchValue,
    planFilter,
    accountStatusFilter,
    setNameSorter,
    setLastActivitySorter,
  } = useContext(UserManagementContext);

  const unSubInterval = useRef<() => void>();

  useEffect(() => {
    if (partnerId && activeProcess && !unSubInterval.current) {
      const fetchActiveProcess = () => {
        dispatch(getActiveProcess());
      };

      const delay = 5000;

      unSubInterval.current = PollingFunction(fetchActiveProcess, delay);
    } else if (!activeProcess && unSubInterval.current && partnerId) {
      setCurrentPage(1);
      setSearchValue('');

      dispatch(getPartnerUsers({}));
      unSubInterval.current();
      unSubInterval.current = undefined;
    }

    return unSubInterval.current;
  }, [partnerId, dispatch, activeProcess, setSearchValue, setCurrentPage]);

  useEffect(() => {
    if (partnerId) {
      dispatch(getPartnerUsers({}));
    }
  }, [dispatch, partnerId]);

  useEffect(() => {
    if (deletingIsFinished) hideDeleteConfirmation();
  }, [deletingIsFinished]);

  const onChange: TableProps<User>['onChange'] = (
    pagination,
    _filter,
    sorter,
  ) => {
    const { current = 1, pageSize = 10 } = pagination;
    let nameSorter;
    let lastActivitySorter;

    if (!Array.isArray(sorter)) {
      if (sorter.field === 'fullName') {
        nameSorter = sorter.order;
        setNameSorter(nameSorter);
        setLastActivitySorter(undefined);
      } else if (sorter.field === 'lastActivity') {
        lastActivitySorter = sorter.order;
        setLastActivitySorter(lastActivitySorter);
        setNameSorter(undefined);
      }
    }

    setCurrentPage(current);

    dispatch(
      getPartnerUsers({
        currentPage: current,
        pageSize,
        searchValue,
        planFilter,
        accountStatusFilter,
        nameSorter,
        lastActivitySorter,
      }),
    );
  };

  const showDeleteConfirmation = () => setIsVisibleDeleteConfirmation(true);
  const hideDeleteConfirmation = () => setIsVisibleDeleteConfirmation(false);

  const dropDownFiltersIcon = <MdKeyboardArrowDown size={20} />;

  return (
    <>
      <DeleteConfirmation
        isVisible={isVisibleDeleteConfirmation}
        hideHandler={hideDeleteConfirmation}
        isDeleting={isDeletingUsers}
        message="Are you sure to continue and delete the selected users?"
        deleteHandler={dispatchDeleteUsers}
      />
      <Table
        rowKey="id"
        loading={isLoadingUsers || isPartnerLoading}
        dataSource={users}
        onChange={onChange}
        pagination={{ total, current: currentPage }}
      >
        <Table.Column
          title="Plan"
          dataIndex="planId"
          render={(planId) => <PlanNameColumn planId={planId} />}
          filterIcon={dropDownFiltersIcon}
          filterDropdown={({ confirm: closeModal }) => (
            <PlanFilter closeModal={closeModal} />
          )}
        />
        <Table.Column
          title="Name"
          dataIndex="fullName"
          sorter
          showSorterTooltip={false}
        />
        <Table.Column title="Email" dataIndex="email" />
        <Table.Column
          title="Last Activity"
          dataIndex="lastActivity"
          sorter
          showSorterTooltip={false}
          render={(value) => {
            return value
              ? formatDistanceToNow(new Date(value), { addSuffix: true })
              : 'Not logged in yet';
          }}
        />
        <Table.Column<User>
          title="Account Status"
          dataIndex="id"
          filterIcon={dropDownFiltersIcon}
          filterDropdown={({ confirm: closeModal }) => (
            <AccountStatusFilter closeModal={closeModal} />
          )}
          render={(userId, { accountStatus }) => {
            let color = COLORS.CERULEAN_BLUE;

            if (accountStatus === 'DEACTIVATED') {
              color = COLORS.BOTTICELLI;
            }

            if (accountStatus === 'PENDING') {
              color = COLORS.BOTTICELLI;
            }

            return (
              <FlexContainer
                $align="center"
                $justify="space-between"
                $horizontalPadding={10}
              >
                <TMText $bold $color={color}>
                  {capitalize(accountStatus)}
                </TMText>
                {!authState.user?.isSuperAdmin && (
                  <AccountStatusOptions
                    userId={userId}
                    currentState={accountStatus}
                  />
                )}
              </FlexContainer>
            );
          }}
        />
        {!authState.user?.isSuperAdmin && (
          <>
            <Table.Column<User>
              title="Change Plan"
              dataIndex="planId"
              render={(planId, { id: userId }, index) => (
                <ChangePlanInput
                  launchRequest={index === 0}
                  userId={userId}
                  initialPlanId={planId}
                  useAlternativeDesign
                  disablePlansWithNoSeatsAvailable
                />
              )}
            />
            <Table.Column
              dataIndex="oktaUid"
              title={
                <Tooltip
                  title={
                    areThereSelectedUsers ? 'Delete users' : 'No users selected'
                  }
                >
                  <FlexContainer
                    $align="center"
                    $justify="center"
                    $cursor={areThereSelectedUsers ? 'pointer' : 'not-allowed'}
                    onClick={
                      areThereSelectedUsers
                        ? () => showDeleteConfirmation()
                        : undefined
                    }
                  >
                    <FiTrash2 />
                  </FlexContainer>
                </Tooltip>
              }
              render={(oktaUid) => (
                <DeleteUserCheckbox
                  isOktaUserIdMarked={isOktaUserIdMarked}
                  oktaUserId={oktaUid}
                  updateOktaUserIdsToBeDeleted={updateOktaUserIdsToBeDeleted}
                />
              )}
            />
          </>
        )}
      </Table>
    </>
  );
};

export default UsersTable;
