import http from '../http';
import { parse } from './Parser';
import PartnerEndpoints from './Endpoints';
import type { DBInvoice } from './Parser';
import { ResponseParser } from '../http/ResponseParser';
import type { Invoice } from '@/store/slices/invoices';

const GetCurrentInvoiceByPartnerId = async (partnerId: number) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.getCurrentInvoiceByPartnerId.method,
    url: PartnerEndpoints.getCurrentInvoiceByPartnerId.endpoint.replace(
      ':partnerId',
      `${partnerId}`,
    ),
  });

  return ResponseParser<DBInvoice, Invoice>(pureResponse, parse);
};

export default GetCurrentInvoiceByPartnerId;
