import type { VFC } from 'react';
import styled from 'styled-components';

import { TMText, TMTitle } from '../typography';
import TextSizes from '../../../styles/TextSizes';
import UserAvatar from './UserAvatar';

interface Props {
  avatar?: string | null;
  name: string;
  subtitle?: string;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
  nameAsText?: boolean;
}

const SimpleProfileCard: VFC<Props> = ({
  avatar,
  name,
  subtitle,
  titleLevel = 5,
  nameAsText = false,
}) => {
  return (
    <MainContainer>
      <AvatarContainer>
        <UserAvatar src={avatar} name={name} />
      </AvatarContainer>
      <DataContainer>
        {nameAsText ? (
          <TMText>{name}</TMText>
        ) : (
          <TMTitle level={titleLevel}>{name}</TMTitle>
        )}
        {subtitle && <TMText $size={TextSizes.SMALL}>{subtitle}</TMText>}
      </DataContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0 15px;
`;

const AvatarContainer = styled.div`
  display: flex;
`;

const DataContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
`;

export default SimpleProfileCard;
