import type { FormEvent, VFC } from 'react';
import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import type { StripePaymentElement, StripeElements } from '@stripe/stripe-js';
import { Button, Col, Divider, message, Row } from 'antd';
import styled from 'styled-components';

import {
  usePartner,
  useSetupIntent,
} from '../../../../store/selectors/partners';
import COLORS from '../../../../styles/Colors';

interface Props {
  cancelEditionHandler: () => void;
}

const StripeSetupForm: VFC<Props> = ({ cancelEditionHandler }) => {
  const stripe = useStripe();
  const setupIntent = useSetupIntent();
  const partner = usePartner();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [elements, setElements] = useState<StripeElements>();
  const [paymentElement, setPaymentElement] = useState<StripePaymentElement>();

  useEffect(() => {
    if (setupIntent && stripe) {
      const stripeElements = stripe.elements({
        clientSecret: setupIntent.client_secret || '',
        appearance: {
          theme: 'flat',
          variables: {
            colorPrimary: COLORS.CERULEAN_BLUE,
            colorBackground: '#eff0f6',
            fontSizeBase: '0.95rem',
            spacingUnit: '3px',
            borderRadius: '5px',
          },
        },
        loader: 'always',
      });
      setElements(stripeElements);
      setPaymentElement(
        stripeElements.create('payment', {
          wallets: { googlePay: 'never', applePay: 'never' },
        }),
      );
    }
  }, [setupIntent, stripe]);

  useEffect(() => {
    if (paymentElement) {
      paymentElement.mount('#payment-container');
      paymentElement.on('ready', () => setIsLoading(false));
    }
  }, [paymentElement]);

  const handleSubmit = async (event: FormEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;

    setIsSubmitting(true);

    const result = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/manage-your-plan`,
        payment_method_data: {
          billing_details: {
            address: {
              city: partner?.city,
              state: partner?.state,
              line1: partner?.address1,
              line2: partner?.address2,
            },
          },
        },
      },
    });

    if (result.error) {
      message.error(result.error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <PaymentContainer id="payment-container" />
      <Divider />
      <Row justify="center" gutter={15}>
        <Col span={10}>
          <Button
            type="primary"
            loading={isLoading || isSubmitting}
            htmlType="submit"
            size="large"
            block
          >
            Save Changes
          </Button>
        </Col>
        <Col span={8}>
          <Button
            disabled={isLoading || isSubmitting}
            onClick={cancelEditionHandler}
            size="large"
            block
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </StyledForm>
  );
};

const PaymentContainer = styled.div``;
const StyledForm = styled.form`
  width: 100%;
`;

export default StripeSetupForm;
