import http from '../http';
import UserEndpoints from './Endpoints';
import type BaseBackendResponse from '../http/types/BaseBackendResponse';

const ProcessBulk = async (
  fileUUID: string,
  partnerId: number,
): Promise<void> => {
  await http.request<BaseBackendResponse>({
    method: UserEndpoints.processBulkFile.method,
    url: UserEndpoints.processBulkFile.endpoint,
    data: { fileUUID, partnerId: `${partnerId}` },
  });
};

export default ProcessBulk;
