import { Avatar } from 'antd';
import type { VFC } from 'react';
import COLORS from '../../../styles/Colors';

interface Props {
  src?: string | null;
  name: string;
  size?: number;
}

const UserAvatar: VFC<Props> = ({ src, name, size }) => {
  const getFirstLetterName = () => name[0].toUpperCase();

  return (
    <Avatar
      style={{ backgroundColor: COLORS.SUBTITLE_BLACK }}
      src={src}
      size={size}
      icon={getFirstLetterName()}
    />
  );
};

export default UserAvatar;
