import { shallowEqual, useSelector } from 'react-redux';

import type RootState from '../types/RootState';
import type { Contract } from '../slices/contracts';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

const isLoadingContract = (state: RootState): boolean => {
  return state.contracts.loadingStatus === LOADING_STATUSES.LOADING;
};

const contractSelector = (state: RootState): Contract | null =>
  state.contracts.contract;

export const useIsLoadingContract = () => useSelector(isLoadingContract);

export const useContract = () => useSelector(contractSelector, shallowEqual);

export const useContractId = () =>
  useSelector((state: RootState) => contractSelector(state)?.id);
