import type { Plan } from '../../store/slices/plans';

export interface DBPlan {
  ID: string;
  STATUS: string;
  FEATURES: string;
  LANGUAGEID: number;
  MONTHLY_AMONT: number;
  YEARLY_AMOUNT: number;
  TRIAL_DAYS: number;
  TRIAL_PRICE: number;
  NAME: string;
  CURRENCY: string;
  INTERVAL_COUNT: number;
  CREATEDAT: string;
  UPDATEDAT: string;
  PAYPAL_MONTH_PLAN_ID: string;
  PAYPAL_YEAR_PLAN_ID: string;
  SLUG: string;
  PRODUCT_ID: string;
  TYPE: string;
  REQUEST_ADMIN_ACCESS: boolean;
  STRIPE_YEARLY_PRICING_ID: string;
  STRIPE_MONTHLY_PRICING_ID: string;
  TMTV_PLAN: string;
  DAILY_INSIGHTS: boolean;
  PAYPAL_PRODUCT_ID: string;
  ACTUAL_YEARLY_AMOUNT: number;
}

const PlanParser = {
  parse: (dbPlan: DBPlan): Plan => ({
    id: dbPlan.ID,
    name: dbPlan.NAME,
    planKey: dbPlan.SLUG,
    price: dbPlan.ACTUAL_YEARLY_AMOUNT / 100,
  }),
  multiParse: (dbPlans: DBPlan[]): Plan[] => dbPlans.map(PlanParser.parse),
};

export const { parse, multiParse } = PlanParser;
export default PlanParser;
