import type { SetupIntent } from '@stripe/stripe-js';
import http from '../http';
import PartnerEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';

const GetSetupIntentByPartnerId = async (partnerId: number) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.getSetupIntentByPartnerId.method,
    url: PartnerEndpoints.getSetupIntentByPartnerId.endpoint.replace(
      ':partnerId',
      `${partnerId}`,
    ),
  });

  return ResponseParser<SetupIntent>(pureResponse);
};

export default GetSetupIntentByPartnerId;
