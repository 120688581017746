import { useState, useEffect, useCallback } from 'react';
import type { VFC } from 'react';
import { useDispatch } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Form, Input, message, Select } from 'antd';
import styled from 'styled-components';

import { updatePaymentMethod } from '../../../../../../store/thunks/partners';
import {
  setSetupIntent,
  resetPaymentMethod,
  resetUpdatePaymentLoading,
} from '../../../../../../store/slices/partners';
import {
  usePartnerName,
  useSetupIntent,
  useIsLoadingPaymentMethodFinished,
  usePaymentMethod,
} from '../../../../../../store/selectors/partners';
import getCountries from '../../../../../../utils/getCountries';

interface Props {
  toggleEditMode: () => void;
}

export interface EditPaymentForm {
  name: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  cardNumber?: {
    brand: string;
    complete: boolean;
    elementType: string;
    empty: boolean;
    error: string | undefined;
  };
}

const countries = getCountries();

const EditPaymentForm: VFC<Props> = ({ toggleEditMode }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const companyName = usePartnerName();
  const setupIntent = useSetupIntent();
  const paymentMethod = usePaymentMethod();
  const updatePaymentMethodFinished = useIsLoadingPaymentMethodFinished();
  const [paymentValues, setPaymentValues] = useState<EditPaymentForm>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  const confirmPaymentMethod = useCallback(async () => {
    if (stripe && setupIntent) {
      if (setupIntent?.status !== 'succeeded') {
        const result = await stripe.confirmCardSetup(
          setupIntent.client_secret || '',
          {
            return_url: `${window.location.origin}/manage-your-plan`,
          },
        );

        if (result.error) {
          message.error(result.error.message);
          if (elements) elements.getElement(CardElement)?.clear();
          dispatch(resetUpdatePaymentLoading());
          setIsSaving(false);
        } else {
          dispatch(resetPaymentMethod());
          dispatch(setSetupIntent(result.setupIntent));
          setIsSaving(false);
          toggleEditMode();
        }
      }
    }
  }, [stripe, setupIntent, toggleEditMode, dispatch, elements]);

  useEffect(() => {
    if (paymentMethod) {
      setPaymentValues({
        name: paymentMethod.card.name,
        line1: paymentMethod.line1,
        line2: paymentMethod.line2,
        city: paymentMethod.city,
        state: paymentMethod.state,
        country: paymentMethod.card.country,
      });
    }

    return () => form.resetFields();
  }, [paymentMethod, form]);

  useEffect(() => {
    if (isSaving && updatePaymentMethodFinished) {
      confirmPaymentMethod();
    }
  }, [isSaving, updatePaymentMethodFinished, confirmPaymentMethod]);

  const onFinish = async (data: EditPaymentForm) => {
    if (stripe && elements && data) {
      setIsSaving(true);
      const element = elements.getElement(CardElement);

      if (element) {
        const { error, paymentMethod: paymentMethodCreated } =
          await stripe.createPaymentMethod({
            type: 'card',
            card: element,
            billing_details: {
              address: {
                city: data.city,
                country: data.country,
                line1: data.line1,
                line2: data.line2,
                state: data.state,
              },
              name: data.name,
            },
          });

        if (error) {
          message.error(error.message);
          setIsSaving(false);
        } else if (paymentMethodCreated) {
          const { id: paymentMethodId, type } = paymentMethodCreated;
          dispatch(updatePaymentMethod({ paymentMethodId, type, companyName }));
        }
      }
    }
  };

  if (!paymentValues) return null;

  return (
    <Form
      name="editPaymentForm"
      form={form}
      initialValues={paymentValues}
      labelAlign="left"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 13, push: 3 }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <StyledItem
        labelAlign="left"
        label="Name on Card"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input the name on card!',
          },
          {
            max: 100,
            message: 'the name must not be greater than 100 characters',
          },
        ]}
      >
        <StyledInput placeholder="Name" />
      </StyledItem>

      <StyledCardContainer
        labelAlign="left"
        label="Card Number"
        name="cardNumber"
        rules={[{ required: true }]}
      >
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                '::placeholder': {
                  color: '#C0C2CE',
                },
              },
              invalid: {
                color: '#8B0000',
              },
            },
          }}
        />
      </StyledCardContainer>

      <StyledItem
        labelAlign="left"
        label="Address line 1"
        name="line1"
        rules={[
          {
            required: true,
            message: 'Please input the address!',
          },
          {
            max: 100,
            message: 'the line 1 must not be greater than 100 characters',
          },
        ]}
      >
        <StyledInput placeholder="Address 1" />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="Address line 2"
        name="line2"
        rules={[
          {
            max: 100,
            message: 'the line 2 must not be greater than 100 characters',
          },
        ]}
      >
        <StyledInput placeholder="Address 2" />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="City"
        name="city"
        rules={[
          {
            required: true,
            message: 'Please input the city!',
          },
          {
            max: 28,
            message: 'the city must not be greater than 28 characters',
          },
        ]}
      >
        <StyledInput placeholder="City" />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="State"
        name="state"
        rules={[
          {
            required: true,
            message: 'Please input the state!',
          },
          {
            max: 50,
            message: 'the state must not be greater than 50 characters',
          },
        ]}
      >
        <StyledInput placeholder="State" />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="Country"
        name="country"
        rules={[
          {
            required: true,
            message: 'Please input the country!',
          },
        ]}
      >
        <Select
          placeholder="Country"
          optionFilterProp="children"
          allowClear
          showSearch
        >
          {countries.map((country) => (
            <Select.Option key={country.alpha2Code} value={country.alpha2Code}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </StyledItem>

      <StyledItem wrapperCol={{ span: 18, offset: 4 }}>
        <ButtonsContainer>
          <Button
            type="primary"
            loading={isSaving}
            htmlType="submit"
            size="large"
            block
          >
            Save Changes
          </Button>
          <Button
            disabled={isSaving}
            style={{
              backgroundColor: '#eff0f6',
              border: '#eff0f6',
              color: '#171717',
            }}
            onClick={toggleEditMode}
            size="large"
            block
          >
            Cancel
          </Button>
        </ButtonsContainer>
      </StyledItem>
    </Form>
  );
};

const StyledItem = styled(Form.Item)`
  margin-bottom: 15px;

  label:not(.ant-form-item-required) {
    padding-left: 10px;
  }
`;
const StyledCardContainer = styled(Form.Item)`
  margin-bottom: 15px;

  .ant-form-item-control-input {
    background-color: #eff0f6;
    border-radius: 5px;
    padding: 0 10px;
  }
`;
const StyledInput = styled(Input)`
  border: 0 !important;
  border-radius: 5px;
  box-shadow: none !important;
  width: 100%;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 40px;
`;

export default EditPaymentForm;
