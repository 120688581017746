import { createSlice } from '@reduxjs/toolkit';

import { changeUserPlan, deleteUsers } from '../thunks/users';
import { getPlanDetailsByContract } from '../thunks/planDetails';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

export interface PlanDetail {
  planId: number;
  contractId: number;
  seats: {
    allocated: number;
    used: number;
    available: number;
  };
  discountApplied: number;
  active: boolean;
}

export interface PlanDetailsState {
  readonly planDetails: PlanDetail[];
  readonly loadingStatus: LOADING_STATUSES;
  readonly loadError: string | null;
}

const initialState: PlanDetailsState = {
  loadingStatus: LOADING_STATUSES.NOT_LOADING,
  planDetails: [],
  loadError: null,
};

const PlanDetailsSlice = createSlice({
  name: 'planDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getPlanDetailsByContract.pending, (state) => {
        state.loadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(getPlanDetailsByContract.fulfilled, (state, { payload }) => {
        state.loadingStatus = LOADING_STATUSES.FULFILLED;
        state.planDetails = payload;
      })
      .addCase(getPlanDetailsByContract.rejected, (state, { error }) => {
        state.loadingStatus = LOADING_STATUSES.FAILED;
        state.loadError = error.message ?? 'Unknown error';
      })
      .addCase(changeUserPlan.fulfilled, (state, { payload }) => {
        state.planDetails = payload;
      })
      .addCase(deleteUsers.fulfilled, (state, { payload }) => {
        state.planDetails = payload;
      }),
});

export default PlanDetailsSlice.reducer;
