interface Config {
  maxNumberOfTries?: number;
}

const PollingFunction = (
  callback: () => void,
  delay: number,
  { maxNumberOfTries = Infinity }: Config = {},
): (() => void) => {
  let counter = 0;
  const interval = setInterval(() => {
    counter += 1;

    if (counter <= maxNumberOfTries) {
      callback();
    } else {
      clearInterval(interval);
    }
  }, delay);

  return () => clearInterval(interval);
};

export default PollingFunction;
