import http from '../http';
import PartnerAdminEndpoints from './Endpoints';
import type { ChangePasswordPayload } from '../../store/slices/partnerAdmins';
import { ResponseParser } from '../http/ResponseParser';

const ChangePassword = async (data: ChangePasswordPayload) => {
  const { data: pureResponse } = await http.request({
    method: PartnerAdminEndpoints.changePassword.method,
    url: PartnerAdminEndpoints.changePassword.endpoint,
    data,
  });

  return ResponseParser(pureResponse);
};

export default ChangePassword;
