import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Input, Form, InputNumber, message, Col, Button, Select } from 'antd';

import { usePartner } from '../../store/selectors/partners';
import { usePartnerAdmin } from '../../store/selectors/partnerAdmins';
import { updatePartner } from '../../store/thunks/partners';
import { updatePartnerAdmin } from '../../store/thunks/partnerAdmins';
import { TMText } from '../common/typography';
import COLORS from '../../styles/Colors';
import getCountries from '../../utils/getCountries';

interface PartnerAdminForm {
  adminId: string | undefined;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: number | null;
  partnerId: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
}

const countries = getCountries();

const AdminInformation = () => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<PartnerAdminForm>();
  const partner = usePartner();
  const admin = usePartnerAdmin();
  const [form] = Form.useForm();

  useEffect(() => {
    if (partner && admin && !isEditing) {
      form.resetFields();
      setInitialValues({
        adminId: admin.oktaUid,
        email: admin.email,
        firstName: admin.firstName,
        lastName: admin.lastName,
        phoneNumber: admin.phoneNumber,
        partnerId: partner.id,
        name: partner.name,
        address1: partner.address1,
        address2: partner.address2,
        city: partner.city,
        state: partner.state,
        country: partner.country,
      });
    }
  }, [partner, admin, isEditing, form]);

  const onFinish = (data: PartnerAdminForm) => {
    if (shallowEqual(initialValues, data)) {
      return message.info('No changes detected, please check again');
    }

    dispatch(
      updatePartnerAdmin({
        oktaUid: data.adminId,
        companyName: data.name,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
      }),
    );

    return dispatch(
      updatePartner({
        id: data.partnerId,
        name: data.name,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        country: data.country,
      }),
    );
  };

  return (
    <Container>
      {initialValues && (
        <Form
          name="partnerAdminForm"
          style={{ width: '450px' }}
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 13 }}
          initialValues={initialValues}
          onFinish={onFinish}
          autoComplete="off"
        >
          {/* Form Admin Info Start */}
          <Form.Item name="adminId" labelAlign="left" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="email" labelAlign="left" hidden>
            <Input />
          </Form.Item>
          <StyledItem
            colon={isEditing}
            labelAlign="left"
            label="First Name"
            name="firstName"
            rules={[
              {
                required: isEditing,
                message: 'Please input your first name!',
              },
            ]}
          >
            <Input
              disabled={!isEditing}
              style={{
                border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                boxShadow: 'none',
                width: '100%',
              }}
            />
          </StyledItem>
          <StyledItem
            colon={isEditing}
            labelAlign="left"
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: isEditing,
                message: 'Please input your last name!',
              },
            ]}
          >
            <Input
              disabled={!isEditing}
              style={{
                border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                boxShadow: 'none',
                width: '100%',
              }}
            />
          </StyledItem>
          <StyledItem
            colon={isEditing}
            labelAlign="left"
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: isEditing,
                message: 'Please input your phone number!',
              },
            ]}
          >
            <InputNumber
              style={{
                border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                boxShadow: 'none',
                width: '100%',
              }}
              disabled={!isEditing}
            />
          </StyledItem>
          {/* Form Admin Info End */}

          <Col style={{ marginBottom: 20 }}>
            <TMText $bold $color={COLORS.TITLE_BLACK} $size={16}>
              Company Information
            </TMText>
          </Col>
          {/* Form Company Info Start */}
          <StyledItem name="partnerId" labelAlign="left" hidden>
            <Input
              style={{
                border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                boxShadow: 'none',
                width: '100%',
              }}
            />
          </StyledItem>
          <StyledItem
            colon={false}
            labelAlign="left"
            label="Company Name"
            name="name"
            rules={[
              {
                required: false,
                message: 'Please input the company name!',
              },
            ]}
          >
            <Input
              disabled
              style={{
                border: `none`,
                boxShadow: 'none',
                width: '100%',
              }}
            />
          </StyledItem>
          <StyledItem
            colon={isEditing}
            labelAlign="left"
            label="Address 1"
            name="address1"
            rules={[
              { required: isEditing, message: 'Please input the address 1!' },
            ]}
          >
            <Input
              disabled={!isEditing}
              style={{
                border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                boxShadow: 'none',
                width: '100%',
              }}
            />
          </StyledItem>
          <StyledItem
            colon={isEditing}
            labelAlign="left"
            label="Address 2"
            name="address2"
          >
            <Input
              disabled={!isEditing}
              style={{
                border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                boxShadow: 'none',
                width: '100%',
              }}
            />
          </StyledItem>
          <StyledItem
            colon={isEditing}
            labelAlign="left"
            label="City"
            name="city"
            rules={[{ required: isEditing, message: 'Please input the city!' }]}
          >
            <Input
              disabled={!isEditing}
              style={{
                border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                boxShadow: 'none',
                width: '100%',
              }}
            />
          </StyledItem>
          <StyledItem
            colon={isEditing}
            labelAlign="left"
            label="State"
            name="state"
            rules={[
              { required: isEditing, message: 'Please input the state!' },
            ]}
          >
            <Input
              disabled={!isEditing}
              style={{
                border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                boxShadow: 'none',
                width: '100%',
              }}
            />
          </StyledItem>
          <StyledItem
            colon={isEditing}
            labelAlign="left"
            label="Country"
            name="country"
            rules={[
              {
                required: isEditing,
                message: 'Please input the country!',
              },
            ]}
          >
            {isEditing ? (
              <Select
                placeholder="Country"
                optionFilterProp="children"
                disabled={!isEditing}
                allowClear
                showSearch
              >
                {countries.map((country) => (
                  <Select.Option key={country.alpha2Code} value={country.name}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Input
                disabled={!isEditing}
                style={{
                  border: `${isEditing ? '1px solid #eff0f6' : 'none'}`,
                  boxShadow: 'none',
                  width: '100%',
                }}
              />
            )}
          </StyledItem>
          {/* Form Company Info End */}

          <StyledItem wrapperCol={{ span: 20 }}>
            {isEditing ? (
              <ButtonsContainer>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: '170px',
                    height: '35px',
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  onClick={() => setIsEditing(false)}
                  style={{
                    width: '170px',
                    height: '35px',
                    backgroundColor: '#eff0f6',
                    border: '#eff0f6',
                    color: '#171717',
                  }}
                >
                  Cancel
                </Button>
              </ButtonsContainer>
            ) : (
              <Button
                type="primary"
                style={{
                  width: '170px',
                  height: '35px',
                }}
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            )}
          </StyledItem>
        </Form>
      )}
    </Container>
  );
};

const StyledItem = styled(Form.Item)`
  label:not(.ant-form-item-required) {
    padding-left: 10px;
  }
`;
const Container = styled.div`
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AdminInformation;
