import { useCallback, useMemo } from 'react';
import type { VFC, ReactElement } from 'react';
import { Alert, Tag, Button, Col, Image, Modal, Row, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';

import type { Invoice } from '../../../../../../store/slices/invoices';
import usePlans from '../../../../../../hooks/usePlans';
import type ModalProps from '@/types/ModalProps';
import logo from '../../../../../../assets/images/logo.png';
import { TMText } from '../../../../../common/typography';
import COLORS from '../../../../../../styles/Colors';
import {
  useColorInvoice,
  useIsLoadingPayment,
} from '../../../../../../store/selectors/invoices';
import { capitalize } from '../../../../../../utils/capitalize';
import { payInvoice } from '../../../../../../store/thunks/invoices';
import useOktaAuthCustom from '../../../../../../hooks/useOktaAuthCustom';

interface Props extends ModalProps {
  invoice: Invoice;
}

interface DataType {
  key: string;
  plan?: ReactElement;
  activeSeats?: string;
  cost?: string;
  discount?: string;
  total: ReactElement | string | number;
}

const columns: TableColumnsType<object> = [
  {
    title: 'Plan Name',
    dataIndex: 'plan',
    align: 'left',
  },
  {
    title: 'Active Seats',
    dataIndex: 'activeSeats',
    align: 'center',
  },
  {
    title: 'Cost/Seat',
    dataIndex: 'cost',
    align: 'center',
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    align: 'center',
  },
  {
    title: 'Total Cost',
    dataIndex: 'total',
    align: 'right',
  },
];

const InvoiceModal: VFC<Props> = ({ invoice, isVisible, hideHandler }) => {
  const dispatch = useDispatch();
  const authState = useOktaAuthCustom();
  const { plans } = usePlans();
  const invoiceColor = useColorInvoice();
  const isPaymentLoading = useIsLoadingPayment();

  const data = useMemo((): DataType[] => {
    const basic = plans.find((p) => p.planKey === 'BASIC');
    const advanced = plans.find((p) => p.planKey === 'ADVANCED');
    const premium = plans.find((p) => p.planKey === 'PREMIUM');

    if (!basic || !advanced || !premium) return [];

    return [
      {
        key: '1',
        plan: (
          <TMTextBlack $size={16} $bold>
            Basic Plan
          </TMTextBlack>
        ),
        activeSeats:
          invoice.basic.users.active === 1
            ? `${invoice.basic.users.active} Seat`
            : `${invoice.basic.users.active} Seats`,
        cost: `$${(basic.price / 12).toLocaleString('EN')}/Seat`,
        discount: `-${invoice.basic.discount}%`,
        total: (
          <TMTextBlack $size={16} $bold>
            ${invoice.basic.totalCost.toLocaleString('EN')}
          </TMTextBlack>
        ),
      },
      {
        key: '2',
        plan: (
          <TMTextBlack $size={16} $bold>
            Advanced Plan
          </TMTextBlack>
        ),
        activeSeats:
          invoice.advanced.users.active === 1
            ? `${invoice.advanced.users.active} Seat`
            : `${invoice.advanced.users.active} Seats`,
        cost: `$${(advanced.price / 12).toLocaleString('EN')}/Seat`,
        discount: `-${invoice.advanced.discount}%`,
        total: (
          <TMTextBlack $size={16} $bold>
            ${invoice.advanced.totalCost.toLocaleString('EN')}
          </TMTextBlack>
        ),
      },
      {
        key: '3',
        plan: (
          <TMTextBlack $size={16} $bold>
            Premium Plan
          </TMTextBlack>
        ),
        activeSeats:
          invoice.premium.users.active === 1
            ? `${invoice.premium.users.active} Seat`
            : `${invoice.premium.users.active} Seats`,
        cost: `$${(premium.price / 12).toLocaleString('EN')}/Seat`,
        discount: `-${invoice.premium.discount}%`,
        total: (
          <TMTextBlack $size={16} $bold>
            ${invoice.premium.totalCost.toLocaleString('EN')}
          </TMTextBlack>
        ),
      },
    ];
  }, [invoice, plans]);

  const TableSummary = useCallback(() => {
    const subTotal =
      invoice.basic.totalCost +
      invoice.advanced.totalCost +
      invoice.premium.totalCost;

    return (
      <>
        {invoice.totalDueInvoiceAmount && (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell
                index={0}
                colSpan={4}
                align="right"
                className="with-border-top"
              >
                Sub Total
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                align="right"
                className="with-border-top"
              >
                <TMTextBlack $size={16} $bold>
                  ${subTotal.toLocaleString('EN')}
                </TMTextBlack>
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4} align="right">
                <TMText $color={COLORS.ORANGE}>Due Invoice Last Month</TMText>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={4} align="right">
                <TMText $color={COLORS.ORANGE} $size={16} $bold>
                  ${invoice.totalDueInvoiceAmount.toLocaleString('EN')}
                </TMText>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}

        <Table.Summary.Row>
          <Table.Summary.Cell index={0} className="with-border">
            <TMText $color={COLORS.TITLE_BLACK} $size={16} $bold>
              Total Billing Amount
            </TMText>
          </Table.Summary.Cell>
          <Table.Summary.Cell
            index={1}
            colSpan={4}
            align="right"
            className="with-border"
          >
            <TMText $color={COLORS.JUNGLE_GREEN} $size={20} $bold>
              ${invoice.totalBillingAmount.toLocaleString('EN')}
            </TMText>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }, [invoice]);

  const parseTextWithHTMLEntities = (text: string) => {
    const field = document.createElement('textarea');
    field.innerHTML = text;
    return field.value;
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={hideHandler}
      footer={null}
      title="Invoice"
      width={800}
      destroyOnClose
      centered
    >
      <Row gutter={[0, 36]}>
        {invoice.transactionDetails && (
          <Col span={24}>
            <Alert
              type="error"
              message={parseTextWithHTMLEntities(invoice.transactionDetails)}
              closable
            />
          </Col>
        )}

        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Image src={logo} alt="Tokenmetrics" preview={false} />
          <StyledTag color={invoiceColor}>
            {capitalize(invoice.status)}
          </StyledTag>
        </Col>

        <Col span={24}>
          <Row justify="space-between">
            <Col span={14}>
              <Col style={{ marginTop: 5 }}>
                <TMTextBlack>Invoice Number: </TMTextBlack>
                <TMTextBlack $bold>
                  TM{invoice.id.slice(0, 4).toUpperCase()}
                </TMTextBlack>
              </Col>

              <Col style={{ marginTop: 5 }}>
                <TMTextBlack>Invoice Date: </TMTextBlack>
                <TMTextBlack $bold>
                  {format(new Date(invoice.invoiceDate), 'PP')}
                </TMTextBlack>
              </Col>

              <Col style={{ marginTop: 5 }}>
                <TMText $color={COLORS.ORANGE}>Payment Due Date: </TMText>
                <TMText $bold $color={COLORS.ORANGE}>
                  {format(new Date(invoice.paymentDueDate), 'PP')}
                </TMText>
              </Col>
            </Col>

            <Col span={10}>
              <Row gutter={[0, 5]} justify="end">
                <StyledColRight span={24}>
                  <TMTextBlack $bold>Partner Billing Address</TMTextBlack>
                </StyledColRight>
                <StyledColRight span={24}>
                  {invoice.address1 && (
                    <TMTextBlack $block>{invoice.address1}, </TMTextBlack>
                  )}
                  {invoice.address2 && (
                    <TMTextBlack>{invoice.address2},</TMTextBlack>
                  )}
                </StyledColRight>

                <StyledColRight span={24}>
                  {invoice.city && <TMTextBlack>{invoice.city}, </TMTextBlack>}
                  {invoice.state && (
                    <TMTextBlack>{invoice.state}, </TMTextBlack>
                  )}
                  <TMTextBlack>
                    {invoice.country}. {invoice.zipcode}
                  </TMTextBlack>
                </StyledColRight>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <StyledTable
            columns={columns}
            dataSource={data}
            pagination={false}
            size="small"
            summary={TableSummary}
          />
        </Col>

        {!authState.user?.isSuperAdmin && invoice?.status === 'PENDING' && (
          <Col span={8} offset={8}>
            <Button
              block
              loading={isPaymentLoading}
              size="large"
              type="primary"
              onClick={() => dispatch(payInvoice())}
            >
              Pay Now
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

const StyledColRight = styled(Col)`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
const StyledTable = styled(Table)`
  th.ant-table-cell {
    font-weight: 400;
    background-color: ${COLORS.WHITE};
    border-top: 1px solid ${COLORS.BOTTICELLI};
    border-bottom: 1px solid ${COLORS.BOTTICELLI};
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }

  td.ant-table-cell {
    color: ${COLORS.TITLE_BLACK};
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  td.ant-table-cell.with-border {
    border-top: 1px solid ${COLORS.BOTTICELLI};
    border-bottom: 1px solid ${COLORS.BOTTICELLI};
  }
  td.ant-table-cell.with-border-top {
    border-top: 1px solid ${COLORS.BOTTICELLI};
  }
  td.ant-table-cell.with-border-bottom {
    border-bottom: 1px solid ${COLORS.BOTTICELLI};
  }
`;

const TMTextBlack = styled(TMText)`
  color: ${COLORS.TITLE_BLACK} !important;
`;

const StyledTag = styled(Tag)`
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  border: 0;
`;

export default InvoiceModal;
