import { Typography } from 'antd';
import styled from 'styled-components';
import type { TextProps } from 'antd/lib/typography/Text';
import COLORS from '../../../styles/Colors';
import TextSizes from '../../../styles/TextSizes';

const { Text } = Typography;

interface Props extends TextProps {
  $color?: COLORS;
  $size?: TextSizes;
  $bold?: boolean;
  $italic?: boolean;
  $marginX?: number;
  $marginXLeft?: number;
  $marginXRight?: number;
  $marginY?: number;
  $marginYTop?: number;
  $marginYBottom?: number;
  $verticalAligned?: boolean;
  $horizontalAligned?: boolean;
  $underline?: boolean;
  $cursor?: string;
  $textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  $block?: boolean;
  $top?: number;
}

const TMText = styled(Text)<Props>(
  ({
    $color = COLORS.TEXT_GRAY,
    $size = TextSizes.MEDIUM,
    $bold = false,
    $italic,
    $marginX = 0,
    $marginXLeft,
    $marginXRight,
    $marginY = 0,
    $marginYTop,
    $marginYBottom,
    $verticalAligned = false,
    $horizontalAligned = false,
    $underline = false,
    $cursor = 'auto',
    $textAlign = 'left',
    $block,
    $top,
  }) => ({
    position: 'relative',
    top: $top,
    color: `${$color} !important`,
    fontSize: `${$size}px`,
    fontWeight: $bold ? '500' : 'normal',
    fontStyle: $italic ? 'italic' : 'normal',
    marginLeft: `${$marginXLeft || $marginX}px`,
    marginRight: `${$marginXRight || $marginX}px`,
    marginTop: `${$marginYTop || $marginY}px !important`,
    marginBottom: `${$marginYBottom || $marginY}px !important`,
    display: (() => {
      if ($block) return 'block';
      return $verticalAligned || $horizontalAligned ? 'flex' : 'inline';
    })(),
    alignItems: $verticalAligned ? 'center' : '',
    justifyContent: $horizontalAligned ? 'center' : '',
    textDecoration: $underline ? 'underline' : 'none',
    cursor: $cursor,
    textAlign: $textAlign,
  }),
);

export default TMText;
