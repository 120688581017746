import http from '../http';
import { parse } from './Parser';
import type { DBPartnerAdmin } from './Parser';
import PartnerAdminEndpoints from './Endpoints';
import type { PartnerAdmin } from '../../store/slices/partnerAdmins';
import { ResponseParser } from '../http/ResponseParser';

const UpdatePartnerAdmin = async (data: PartnerAdmin) => {
  const { data: pureResponse } = await http.request({
    method: PartnerAdminEndpoints.updatePartnerAdmin.method,
    url: PartnerAdminEndpoints.updatePartnerAdmin.endpoint,
    data,
  });

  return ResponseParser<DBPartnerAdmin, PartnerAdmin>(pureResponse, parse);
};

export default UpdatePartnerAdmin;
