import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const ArrowUpRightFromSquare: VFC<IconInterface> = ({
  color = COLORS.GRAY,
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 2.5H2.5C2.10218 2.5 1.72064 2.65804 1.43934 2.93934C1.15804 3.22064 1 3.60218 1 4V11.5C1 11.8978 1.15804 12.2794 1.43934 12.5607C1.72064 12.842 2.10218 13 2.5 13H10C10.3978 13 10.7794 12.842 11.0607 12.5607C11.342 12.2794 11.5 11.8978 11.5 11.5V8.5M8.5 1H13M13 1V5.5M13 1L5.5 8.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUpRightFromSquare;
