import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'antd';

import { Tile } from '../../common/tiles';
import PaymentProcesses from './PaymentProcesses';
import {
  getPartnerPayment,
  retrieveSetupIntent,
} from '../../../store/thunks/partners';
import {
  getCurrentInvoiceByPartnerId,
  getCurrentInvoiceSilent,
  getInvoicesByPartnerId,
} from '../../../store/thunks/invoices';
import {
  usePartner,
  useSetupIntent,
  usePaymentMethod,
  useIsLoadingPaymentMethod,
} from '../../../store/selectors/partners';
import {
  useInvoice,
  useIsLoadingInvoiceFinished,
} from '../../../store/selectors/invoices';
import parsePaymentMethod from '../../../utils/parsePaymentMethod';
import { TMText } from '../../common/typography';
import PaymentMethodContext from './Context';
import COLORS from '../../../styles/Colors';
import PollingFunction from '../../../utils/PollingFunction';
import useOktaAuthCustom from '../../../hooks/useOktaAuthCustom';

const PaymentMethodTile = () => {
  const dispatch = useDispatch();
  const authState = useOktaAuthCustom();
  const { editMode, toggleEditMode } = useContext(PaymentMethodContext);
  const setupIntent = useSetupIntent();
  const partner = usePartner();
  const paymentMethod = usePaymentMethod();
  const invoice = useInvoice();
  const isInvoiceLoadingFinished = useIsLoadingInvoiceFinished();
  const isPaymentMethodLoading = useIsLoadingPaymentMethod();

  const [title, setTitle] = useState<string>('Payment Details');
  const unSubInterval = useRef<() => void>();

  useEffect(() => {
    if (partner?.id && partner?.setupId && !setupIntent)
      dispatch(retrieveSetupIntent(partner.id));
  }, [partner, dispatch, setupIntent]);

  useEffect(() => {
    if (partner?.id && setupIntent?.status === 'succeeded') {
      if (!paymentMethod && !isPaymentMethodLoading) {
        dispatch(getPartnerPayment(partner.id));
      }
    }
  }, [partner, dispatch, setupIntent, paymentMethod, isPaymentMethodLoading]);

  useEffect(() => {
    if (partner?.id && setupIntent?.status === 'succeeded') {
      if (!invoice && !isInvoiceLoadingFinished && paymentMethod) {
        dispatch(getCurrentInvoiceByPartnerId(partner.id));
      }
    }
  }, [
    partner,
    dispatch,
    setupIntent,
    paymentMethod,
    invoice,
    isInvoiceLoadingFinished,
  ]);

  useEffect(() => {
    if (partner && invoice?.status === 'PROCESSING' && !unSubInterval.current) {
      const fetchCurrentInvoice = () =>
        dispatch(getCurrentInvoiceSilent(partner.id));

      unSubInterval.current = PollingFunction(fetchCurrentInvoice, 5000, {
        maxNumberOfTries: 10,
      });
    } else if (
      partner &&
      invoice?.status !== 'PROCESSING' &&
      unSubInterval.current
    ) {
      dispatch(getInvoicesByPartnerId(partner.id));
      unSubInterval.current();
      unSubInterval.current = undefined;
    }

    return unSubInterval.current;
  }, [partner, dispatch, invoice]);

  useEffect(() => {
    if (setupIntent?.status === 'requires_payment_method') {
      setTitle('Choose a Payment Method');
    } else if (paymentMethod) {
      setTitle(`Payment Details / ${parsePaymentMethod(paymentMethod.type)}`);
    }
  }, [setupIntent, paymentMethod]);

  return (
    <Tile
      $paddingX={40}
      title={
        <Row align="middle" style={{ padding: 0 }}>
          <Col span={authState.user?.isSuperAdmin ? 24 : 20}>
            <TMText $bold $size={18} $color={COLORS.TITLE_BLACK}>
              {title}
            </TMText>
          </Col>
          {!authState.user?.isSuperAdmin && (
            <Col
              span={4}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {paymentMethod && !editMode && (
                <Button block type="primary" onClick={toggleEditMode}>
                  <TMText $bold $color={COLORS.TITLE_BLACK}>
                    Edit
                  </TMText>
                </Button>
              )}
            </Col>
          )}
        </Row>
      }
      component={<PaymentProcesses partner={partner} />}
    />
  );
};

export default PaymentMethodTile;
