import { createAsyncThunk } from '@reduxjs/toolkit';

import GetContractByPartner from '../../services/contracts/GetContractByPartner';

export const getContractByPartner = createAsyncThunk(
  'contracts/getContractByPartner',
  async (partnerId: number, { rejectWithValue }) => {
    const {
      parsedData: contract,
      success,
      message,
      validationErrors,
    } = await GetContractByPartner(partnerId);

    if (success && contract) {
      return contract;
    }

    throw rejectWithValue({
      message,
      validationErrors,
    });
  },
);
