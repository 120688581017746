import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Spin } from 'antd';

import { TMText } from '../../typography';
import BillingLink from './BillingLink';
import {
  useInvoices,
  useIsLoadingInvoices,
  useIsLoadingInvoicesFinished,
} from '../../../../store/selectors/invoices';
import { getInvoicesByPartnerId } from '../../../../store/thunks/invoices';
import {
  useIsLoadingPartner,
  usePartner,
} from '../../../../store/selectors/partners';

interface StyledProps {
  hasInvoices: boolean;
}

const BillingHistory = () => {
  const dispatch = useDispatch();
  const isInvoicesLoadingFinished = useIsLoadingInvoicesFinished();
  const isInvoicesLoading = useIsLoadingInvoices();
  const isPartnerLoading = useIsLoadingPartner();
  const invoices = useInvoices();
  const partner = usePartner();

  useEffect(() => {
    if (partner && !invoices.length && !isInvoicesLoadingFinished)
      dispatch(getInvoicesByPartnerId(partner.id));
  }, [dispatch, partner, invoices, isInvoicesLoadingFinished]);

  if (isPartnerLoading || isInvoicesLoading)
    return (
      <Container hasInvoices={false}>
        <Spin />
      </Container>
    );

  return (
    <Container hasInvoices={!!invoices.length}>
      {invoices.length ? (
        <>
          {invoices.map((record) => (
            <BillingLink url={record.url} key={record.id}>
              {format(new Date(record.invoiceDate), 'PP')}
            </BillingLink>
          ))}
        </>
      ) : (
        <TMText>No invoices generated</TMText>
      )}
    </Container>
  );
};

const Container = styled.div<StyledProps>(({ hasInvoices }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: 20,
  justifyContent: hasInvoices ? 'flex-start' : 'center',
  alignItems: hasInvoices ? 'flex-start' : 'center',
  overflowY: hasInvoices ? 'scroll' : 'auto',
}));

export default BillingHistory;
