import { notification } from 'antd';
import type { AxiosInstance, AxiosResponse } from 'axios';

const AuthResponseInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (res: AxiosResponse) => res,
    (failureResponse) => {
      if (failureResponse.status === 401) {
        notification.error({
          message: 'Error',
          description: 'Token expired, please login again',
        });

        window.location.href = '/login';
      } else {
        notification.error({
          message: 'Error',
          description: failureResponse.message,
        });
      }

      return Promise.reject(failureResponse);
    },
  );
};

export default AuthResponseInterceptor;
