import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';

import type ErrorStoreType from '../types/ErrorStoreType';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';
import {
  getCurrentInvoiceByPartnerId,
  getCurrentInvoiceSilent,
  payInvoice,
  getInvoicesByPartnerId,
} from '../thunks/invoices';

export interface Invoice {
  id: string;
  partnerId: number;
  invoiceDate: string;
  paymentDueDate: string;
  billingCurrency: string;
  paymentId?: string;
  stripeInvoiceId?: string;
  url?: string;
  address1: string;
  address2?: string;
  city?: string;
  state: string;
  country: string;
  zipcode: string;
  basic: {
    users: {
      active: number;
      total: number;
    };
    totalCost: number;
    discount: number;
  };
  advanced: {
    users: {
      active: number;
      total: number;
    };
    totalCost: number;
    discount: number;
  };
  premium: {
    users: {
      active: number;
      total: number;
    };
    totalCost: number;
    discount: number;
  };
  totalDueInvoiceAmount: number | null;
  totalBillingAmount: number;
  status: 'PENDING' | 'PROCESSING' | 'DUE' | 'CANCELLED' | 'SUCCESS';
  transactionDetails?: string;
}

interface ListInvoiceState {
  items: Invoice[];
  total: number;
  loading: LOADING_STATUSES;
  error: ErrorStoreType;
}

export interface InvoiceState {
  readonly current: Invoice | null;
  readonly loadingStatus: LOADING_STATUSES;
  readonly loadError: string | null;
  readonly paymentStatus: LOADING_STATUSES;
  readonly paymentError: string | null;
  readonly invoices: ListInvoiceState;
}

const initialState: InvoiceState = {
  loadingStatus: LOADING_STATUSES.NOT_LOADING,
  current: null,
  loadError: null,
  paymentStatus: LOADING_STATUSES.NOT_LOADING,
  paymentError: null,
  invoices: {
    items: [],
    total: 0,
    loading: LOADING_STATUSES.NOT_LOADING,
    error: null,
  },
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getCurrentInvoiceByPartnerId.pending, (state) => {
        state.loadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(getCurrentInvoiceByPartnerId.fulfilled, (state, { payload }) => {
        state.current = payload;
        state.loadingStatus = LOADING_STATUSES.FULFILLED;
        state.loadError = null;
      })
      .addCase(getCurrentInvoiceByPartnerId.rejected, (state, { payload }) => {
        state.current = null;
        state.loadingStatus = LOADING_STATUSES.FAILED;
        state.loadError = payload?.message ?? 'Unknown error';

        notification.info({
          message: 'No invoices generated',
          description: payload?.message,
        });
      })
      .addCase(payInvoice.pending, (state) => {
        state.paymentStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(payInvoice.fulfilled, (state, { payload }) => {
        state.current = payload;
        state.paymentStatus = LOADING_STATUSES.FULFILLED;
        state.paymentError = null;

        switch (payload?.status) {
          case 'PENDING':
            notification.warning({
              message: 'Payment Status',
              description: 'The payment was not applied, try again.',
            });
            break;
          case 'PROCESSING':
            notification.success({
              message: 'Payment Status',
              description:
                'The payment is in progress. We let you know when the payment will be success or fail.',
            });
            break;
          default:
            notification.error({
              message: 'Payment Status',
              description: 'The payment fails.',
            });
            break;
        }
      })
      .addCase(payInvoice.rejected, (state, { payload }) => {
        state.current = null;
        state.paymentStatus = LOADING_STATUSES.FAILED;
        state.paymentError = payload?.message ?? 'Unknown error';

        notification.info({
          message: 'No invoice paid',
          description: payload?.message,
        });
      })
      .addCase(getCurrentInvoiceSilent.fulfilled, (state, { payload }) => {
        switch (payload?.status) {
          case 'PENDING':
            notification.warning({
              message: 'Payment Status',
              description: 'The payment was not applied, try again.',
            });
            state.current = payload;

            break;
          case 'SUCCESS':
            notification.success({
              message: 'Payment Status',
              description: 'The payment was successful.',
            });
            state.current = payload;

            break;
          default:
            break;
        }
      })
      .addCase(getInvoicesByPartnerId.pending, (state) => {
        state.invoices.loading = LOADING_STATUSES.LOADING;
      })
      .addCase(
        getInvoicesByPartnerId.fulfilled,
        (state, { payload: { invoices, total } }) => {
          state.invoices.items = invoices;
          state.invoices.total = total;
          state.invoices.loading = LOADING_STATUSES.FULFILLED;
          state.invoices.error = null;
        },
      )
      .addCase(getInvoicesByPartnerId.rejected, (state, { payload }) => {
        state.invoices.loading = LOADING_STATUSES.FAILED;
        state.invoices.error = payload ?? null;
      }),
});

export default invoicesSlice.reducer;
