import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const PaymentMethod: VFC<IconInterface> = ({ color = COLORS.GRAY }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 21.435C2.70163 21.435 2.41548 21.3165 2.2045 21.1055C1.99353 20.8945 1.875 20.6084 1.875 20.31V6C1.87619 4.90635 2.31117 3.85783 3.0845 3.0845C3.85783 2.31117 4.90635 1.87619 6 1.875H27C28.0937 1.87619 29.1422 2.31117 29.9155 3.0845C30.6888 3.85783 31.1238 4.90635 31.125 6V10.5C31.125 10.7984 31.0065 11.0845 30.7955 11.2955C30.5845 11.5065 30.2984 11.625 30 11.625C29.7016 11.625 29.4155 11.5065 29.2045 11.2955C28.9935 11.0845 28.875 10.7984 28.875 10.5V6C28.8742 5.50296 28.6764 5.02651 28.3249 4.67505C27.9735 4.32359 27.497 4.12579 27 4.125H6C5.50296 4.12579 5.02651 4.32359 4.67505 4.67505C4.32359 5.02651 4.12579 5.50296 4.125 6V20.31C4.125 20.6084 4.00647 20.8945 3.7955 21.1055C3.58452 21.3165 3.29837 21.435 3 21.435ZM27 28.125H18C17.7016 28.125 17.4155 28.0065 17.2045 27.7955C16.9935 27.5845 16.875 27.2984 16.875 27C16.875 26.7016 16.9935 26.4155 17.2045 26.2045C17.4155 25.9935 17.7016 25.875 18 25.875H27C27.497 25.8742 27.9735 25.6764 28.3249 25.3249C28.6764 24.9735 28.8742 24.497 28.875 24V19.5C28.875 19.2016 28.9935 18.9155 29.2045 18.7045C29.4155 18.4935 29.7016 18.375 30 18.375C30.2984 18.375 30.5845 18.4935 30.7955 18.7045C31.0065 18.9155 31.125 19.2016 31.125 19.5V24C31.1242 25.0938 30.6894 26.1425 29.9159 26.9159C29.1425 27.6894 28.0938 28.1242 27 28.125Z"
        fill={color}
      />
      <path
        d="M31.5 20.625H24C23.3041 20.6242 22.6368 20.3474 22.1447 19.8553C21.6526 19.3632 21.3758 18.6959 21.375 18V12C21.3758 11.3041 21.6526 10.6368 22.1447 10.1447C22.6368 9.65261 23.3041 9.37579 24 9.375H31.5C32.1959 9.37579 32.8632 9.65261 33.3553 10.1447C33.8474 10.6368 34.1242 11.3041 34.125 12V18C34.1242 18.6959 33.8474 19.3632 33.3553 19.8553C32.8632 20.3474 32.1959 20.6242 31.5 20.625V20.625ZM24 11.625C23.9005 11.625 23.8052 11.6645 23.7348 11.7348C23.6645 11.8052 23.625 11.9005 23.625 12V18C23.625 18.0995 23.6645 18.1948 23.7348 18.2652C23.8052 18.3355 23.9005 18.375 24 18.375H31.5C31.5995 18.375 31.6948 18.3355 31.7652 18.2652C31.8355 18.1948 31.875 18.0995 31.875 18V12C31.875 11.9005 31.8355 11.8052 31.7652 11.7348C31.6948 11.6645 31.5995 11.625 31.5 11.625H24Z"
        fill={color}
      />
      <path
        d="M9 34.125C7.59081 34.125 6.21327 33.7071 5.04157 32.9242C3.86986 32.1413 2.95664 31.0285 2.41736 29.7266C1.87809 28.4247 1.73699 26.9921 2.01191 25.61C2.28683 24.2279 2.96542 22.9583 3.96187 21.9619C4.95832 20.9654 6.22787 20.2868 7.60998 20.0119C8.9921 19.737 10.4247 19.8781 11.7266 20.4174C13.0285 20.9566 14.1413 21.8699 14.9242 23.0416C15.7071 24.2133 16.125 25.5908 16.125 27C16.1226 28.8889 15.3712 30.6998 14.0355 32.0355C12.6998 33.3712 10.8889 34.1226 9 34.125V34.125ZM9 22.125C8.03582 22.125 7.09329 22.4109 6.2916 22.9466C5.48991 23.4823 4.86507 24.2436 4.49609 25.1344C4.12711 26.0252 4.03057 27.0054 4.21867 27.9511C4.40678 28.8967 4.87108 29.7654 5.55286 30.4471C6.23464 31.1289 7.10328 31.5932 8.04894 31.7813C8.99459 31.9694 9.97479 31.8729 10.8656 31.5039C11.7564 31.1349 12.5177 30.5101 13.0534 29.7084C13.5891 28.9067 13.875 27.9642 13.875 27C13.8734 25.7076 13.3593 24.4685 12.4454 23.5546C11.5315 22.6407 10.2924 22.1266 9 22.125V22.125Z"
        fill={color}
      />
      <path
        d="M9 30.375C8.70163 30.375 8.41548 30.2565 8.2045 30.0455C7.99353 29.8345 7.875 29.5484 7.875 29.25V24.75C7.875 24.4516 7.99353 24.1655 8.2045 23.9545C8.41548 23.7435 8.70163 23.625 9 23.625C9.29837 23.625 9.58452 23.7435 9.7955 23.9545C10.0065 24.1655 10.125 24.4516 10.125 24.75V29.25C10.125 29.5484 10.0065 29.8345 9.7955 30.0455C9.58452 30.2565 9.29837 30.375 9 30.375Z"
        fill={color}
      />
      <path
        d="M11.25 28.125H6.75C6.45163 28.125 6.16548 28.0065 5.9545 27.7955C5.74353 27.5845 5.625 27.2984 5.625 27C5.625 26.7016 5.74353 26.4155 5.9545 26.2045C6.16548 25.9935 6.45163 25.875 6.75 25.875H11.25C11.5484 25.875 11.8345 25.9935 12.0455 26.2045C12.2565 26.4155 12.375 26.7016 12.375 27C12.375 27.2984 12.2565 27.5845 12.0455 27.7955C11.8345 28.0065 11.5484 28.125 11.25 28.125Z"
        fill={color}
      />
    </svg>
  );
};

export default PaymentMethod;
