import http from '../http';
import { multiParse } from './Parser';
import type { DBPlanDetails } from './Parser';
import PlanDetailsEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';
import type { PlanDetail } from '@/store/slices/planDetails';

const GetPlanDetailsByContract = async (contractId: number) => {
  const { data: pureResponse } = await http.request({
    method: PlanDetailsEndpoints.getPlanDetailsByContract.method,
    url: PlanDetailsEndpoints.getPlanDetailsByContract.endpoint.replace(
      ':id',
      `${contractId}`,
    ),
  });

  return ResponseParser<DBPlanDetails[], PlanDetail[]>(
    pureResponse,
    multiParse,
  );
};

export default GetPlanDetailsByContract;
