import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteUsers } from '../../../store/thunks/users';
import {
  useIsDeletingUsers,
  useIsDeletingUsersFinished,
} from '../../../store/selectors/users';
import UserManagementContext from '../Context';

const useDeleteUsers = () => {
  const dispatch = useDispatch();

  const {
    planFilter,
    currentPage,
    accountStatusFilter,
    searchValue,
    setCurrentPage,
    nameSorter,
    lastActivitySorter,
  } = useContext(UserManagementContext);

  const [oktaUserIdToBeDeleted, setOktaUserIdToBeDeleted] = useState<string[]>(
    [],
  );

  const isDeleting = useIsDeletingUsers();
  const isDeletingUsersFinished = useIsDeletingUsersFinished();

  useEffect(() => {
    if (isDeletingUsersFinished) setOktaUserIdToBeDeleted([]);
  }, [isDeletingUsersFinished, dispatch]);

  const isOktaUserIdMarked = (oktaUid: string): boolean =>
    oktaUserIdToBeDeleted.includes(oktaUid);

  // If add is true it will add the new id, if false it will remove the given id from the list
  const updateOktaUserIdsToBeDeleted = (
    add: boolean,
    oktaUid: string,
  ): void => {
    const removeFunction = () =>
      oktaUserIdToBeDeleted.filter((id) => id !== oktaUid);

    const addFunction = () => [...oktaUserIdToBeDeleted, oktaUid];

    setOktaUserIdToBeDeleted(add ? addFunction() : removeFunction());
  };

  const areThereSelectedUsers = oktaUserIdToBeDeleted.length > 0;

  return {
    isOktaUserIdMarked,
    updateOktaUserIdsToBeDeleted,
    dispatchDelete: () =>
      dispatch(
        deleteUsers({
          oktaUids: oktaUserIdToBeDeleted,
          filtersForRefreshingData: {
            planFilter,
            currentPage,
            accountStatusFilter,
            searchValue,
            setCurrentPage,
            nameSorter,
            lastActivitySorter,
          },
        }),
      ),
    areThereSelectedUsers,
    isDeleting,
    deletingIsFinished: isDeletingUsersFinished,
  };
};

export default useDeleteUsers;
