import http from '../http';
import { parse } from './Parser';
import PartnerEndpoints from './Endpoints';
import type { DBInvoice } from './Parser';
import { ResponseParser } from '../http/ResponseParser';
import type { Invoice } from '@/store/slices/invoices';

const PayInvoice = async (partnerId: number, invoiceId: string) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.payInvoice.method,
    url: PartnerEndpoints.payInvoice.endpoint
      .replace(':partnerId', `${partnerId}`)
      .replace(':invoiceId', invoiceId),
  });

  return ResponseParser<DBInvoice, Invoice>(pureResponse, parse);
};

export default PayInvoice;
