import type { AccessToken } from '@okta/okta-auth-js';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';

const AuthRequestInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    const oktaToken = JSON.parse(
      localStorage.getItem('okta-token-storage') as string,
    );

    const token: AccessToken = oktaToken?.accessToken;

    if (config.headers && token) {
      config.headers.Authorization = `${token.tokenType} ${token.accessToken}`;
    }

    return config;
  });
};

export default AuthRequestInterceptor;
