import type EndpointsType from '../../types/EndpointsType';

const InvoiceEndpoints = Object.freeze<EndpointsType>({
  getCurrentInvoiceByPartnerId: {
    endpoint: '/partners/:partnerId/current-invoice',
    method: 'GET',
  },
  payInvoice: {
    endpoint: '/partners/:partnerId/transactions/:invoiceId',
    method: 'POST',
  },
  getInvoicesByPartnerId: {
    endpoint: '/partners/:partnerId/invoices',
    method: 'GET',
  },
});

export default InvoiceEndpoints;
