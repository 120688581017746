import type { Contract } from '../../store/slices/contracts';

export interface DBContract {
  PRTNR_CNTRCT_ID: number;
  PRTNR_ID: number;
  PRTNR_CONTRCT_URL: string;
  AUTH_SIGN_NAME: string;
  AUTH_SIGN_TITLE: string;
  AUTH_SIGN_EMAIL: string;
  AUTH_SIGN_PHONE: number;
  CNTRCT_SIGN_DATE: Date;
  CNTRCT_START_DATE: Date;
  CNTRCT_EXPIRY_DATE: Date;
  PRTNR_CONTRCT_ACTIVE: boolean;
  PRTNR_TOTAL_SEATS_ALLOCATION: number;
  INITIAL_AMT: number;
  PRTNR_BILLING_CYCLE: string;
  CREATED_DT: string;
  UPDATED_DT: string;
}

const ContractParser = {
  parse: (dbContract: DBContract): Contract => ({
    id: dbContract.PRTNR_CNTRCT_ID,
    partnerId: dbContract.PRTNR_ID,
    contractUrl: dbContract.PRTNR_CONTRCT_URL,
    signedBy: dbContract.AUTH_SIGN_NAME,
    signedTitle: dbContract.AUTH_SIGN_TITLE,
    signedEmail: dbContract.AUTH_SIGN_EMAIL,
    signedPhone: dbContract.AUTH_SIGN_PHONE,
    signDate: dbContract.CNTRCT_SIGN_DATE,
    startDate: dbContract.CNTRCT_START_DATE,
    expireDate: dbContract.CNTRCT_EXPIRY_DATE,
    active: dbContract.PRTNR_CONTRCT_ACTIVE,
    totalSeats: dbContract.PRTNR_TOTAL_SEATS_ALLOCATION,
    initialAmount: dbContract.INITIAL_AMT,
    billingCycle: dbContract.PRTNR_BILLING_CYCLE,
  }),
};

export const { parse } = ContractParser;
export default ContractParser;
