import { Spin } from 'antd';
import type { VFC } from 'react';
import styled from 'styled-components';

import { Tile } from '../../common/tiles';
import RightOptions from './RightOptions';
import ActiveUsersChart from './ActiveUserChart';
import FlexContainer from '../../common/flex/FlexContainer';
import { useIsUserActivityMetricsLoading } from '../../../store/selectors/users';

const ActiveUserChartTile: VFC = () => {
  const isLoadingMetrics = useIsUserActivityMetricsLoading();

  return (
    <StyledTile
      title="Active Users Statistics"
      component={
        !isLoadingMetrics ? (
          <ActiveUsersChart />
        ) : (
          <FlexContainer $justify="center" $align="center" $totalHeight>
            <Spin />
          </FlexContainer>
        )
      }
      rightOptions={<RightOptions />}
    />
  );
};

const StyledTile = styled(Tile)`
  .ant-card-extra {
    padding: 0;
  }
`;

export default ActiveUserChartTile;
