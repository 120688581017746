import styled from 'styled-components';
import { NoBorderedTile } from '../../common/tiles';
import PaymentSummary from './PaymentSummary';

const PaymentSummaryTile = () => {
  return <StyledTile component={<PaymentSummary />} />;
};

const StyledTile = styled(NoBorderedTile)`
  padding: 25px;
`;

export default PaymentSummaryTile;
