import http from '../http';
import UserEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';
import type ParsedResponse from '../http/types/ParsedResponse';

const UploadUsersInBulk = async (
  file: File,
  partnerId: number,
): Promise<ParsedResponse<string>> => {
  const form = new FormData();
  form.append('file', file);
  form.append('partnerId', `${partnerId}`);

  const { data: pureResponse } = await http.request({
    method: UserEndpoints.uploadUsersInBulk.method,
    url: UserEndpoints.uploadUsersInBulk.endpoint,
    data: form,
  });

  return ResponseParser<string>(pureResponse);
};

export default UploadUsersInBulk;
