import type { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';

import { usePaymentMethod } from '../../../../../../store/selectors/partners';
import COLORS from '../../../../../../styles/Colors';
import { TMText } from '../../../../../common/typography';

const CreditCardInformation: FC = () => {
  const paymentMethod = usePaymentMethod();

  return (
    <Container>
      <RowContainer>
        <Col span={10}>
          <TMText>Name on Card:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.card.name}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Card Number:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            XXXX XXXX XXXX {paymentMethod?.card.last4}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Address line 1:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.line1}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Address line 2:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.line2}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>City:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.city}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>State:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.state}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Country:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.card.country}
          </TMText>
        </Col>
      </RowContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
`;

const RowContainer = styled(Row)`
  margin-bottom: 20px;
`;

export default CreditCardInformation;
