const RouteNames = Object.freeze({
  Dashboard: '/',
  Login: '/login',
  oktaCallback: '/implicit/callback',
  ManageYourPlan: '/manage-your-plan',
  UserManagement: '/user-management',
  HowToUploadUsers: '/how-to-upload-users',
  Settings: '/settings',
});

export default RouteNames;
