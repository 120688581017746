import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  usePartnersError,
  usePartnersLoadStatus,
  usePartners as useOriginalPartners,
} from '../store/selectors/partners';
import { getPartners } from '../store/thunks/partners';
import LOADING_STATUSES from '../types/statuses/LoadingStatuses';

const usePartners = () => {
  const dispatch = useDispatch();
  const partners = useOriginalPartners();
  const loadingStatus = usePartnersLoadStatus();
  const error = usePartnersError();

  useEffect(() => {
    if (
      partners.length <= 0 &&
      loadingStatus !== LOADING_STATUSES.LOADING &&
      !error
    ) {
      dispatch(getPartners());
    }
  }, [partners, loadingStatus, dispatch, error]);

  return { partners, loadingStatus, error };
};

export default usePartners;
