import type { VFC } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button, Form, Modal } from 'antd';

import { TMTitle } from '../../../common/typography';
import InputFile from '../../../common/inputs/InputFile';
import type ModalProps from '../../../../types/ModalProps';
import FlexContainer from '../../../common/flex/FlexContainer';
import { uploadLogoImage } from '../../../../store/thunks/partners';
import { resetWriteStatus } from '../../../../store/slices/partners';
import {
  useIsWritingPartner,
  useIsWritingPartnerFinished,
} from '../../../../store/selectors/partners';

const UploadImageModal: VFC<ModalProps> = ({ hideHandler, isVisible }) => {
  const dispatch = useDispatch();

  const isWriting = useIsWritingPartner();
  const isFinished = useIsWritingPartnerFinished();

  useEffect(() => {
    if (isFinished) {
      dispatch(resetWriteStatus());
      hideHandler();
    }
  }, [isFinished, hideHandler, dispatch]);

  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      onCancel={hideHandler}
      footer={null}
      centered
      closable={!isWriting}
      maskClosable={!isWriting}
    >
      <Container>
        <TMTitle level={4} $textAlign="center">
          Drag and Drop your Image
        </TMTitle>
        <Form
          onFinish={({ file: { file } }) => dispatch(uploadLogoImage(file))}
        >
          <Form.Item
            name="file"
            valuePropName="file"
            rules={[{ required: true, message: 'Please upload an image' }]}
          >
            <InputFile title="Upload your image" accept=".jpg, .jpeg, .png" />
          </Form.Item>
          <FlexContainer $justify="space-evenly" $gap={10}>
            <Button
              block
              type="primary"
              size="large"
              htmlType="submit"
              loading={isWriting}
            >
              Change
            </Button>
            <Button
              block
              size="large"
              onClick={hideHandler}
              disabled={isWriting}
            >
              Cancel
            </Button>
          </FlexContainer>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 20px;
`;

export default UploadImageModal;
