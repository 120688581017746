import type ParsedResponse from './types/ParsedResponse';
import type BaseBackendResponse from './types/BaseBackendResponse';
import type ValidationErrorData from './types/ValidationErrorData';
import type { ListResponse } from './types/BaseBackendListResponse';
import type BaseBackendListResponse from './types/BaseBackendListResponse';

export const ResponseParser = <OriginalType = unknown, ParsedType = unknown>(
  pureResponse: BaseBackendResponse<OriginalType>,
  parser?: (data: OriginalType) => ParsedType,
): ParsedResponse<OriginalType, ParsedType> => {
  const parsedResponse: ParsedResponse<OriginalType, ParsedType> = {
    success: pureResponse.success,
    message: pureResponse.message,
  };

  const dataWithErrors = pureResponse.data as ValidationErrorData;
  const data = pureResponse.data as OriginalType;

  if (dataWithErrors?.errors) {
    parsedResponse.validationErrors = dataWithErrors.errors;
  } else if (typeof data !== 'undefined') {
    parsedResponse.data = data;
    if (parser && data) {
      parsedResponse.parsedData = parser(data);
    }
  }

  return parsedResponse;
};

export const ResponseParserList = <
  OriginalType = unknown,
  ParsedType = unknown,
>(
  pureResponse: BaseBackendListResponse<OriginalType>,
  parser?: (data: OriginalType[]) => ParsedType[],
): ParsedResponse<OriginalType[], ParsedType[]> => {
  const parsedResponse: ParsedResponse<OriginalType[], ParsedType[]> = {
    success: pureResponse.success,
    message: pureResponse.message,
  };

  const dataWithErrors = pureResponse.data as ValidationErrorData;
  const data = pureResponse.data as ListResponse<OriginalType>;

  if (dataWithErrors?.errors) {
    parsedResponse.validationErrors = dataWithErrors.errors;
  } else if (data?.list) {
    parsedResponse.data = data.list;

    if (parser) {
      parsedResponse.parsedData = parser(data.list);
    }

    if (typeof data?.total === 'number') {
      parsedResponse.total = data.total;
    }
  }

  return parsedResponse;
};
