import { createAsyncThunk } from '@reduxjs/toolkit';
import type { SetupIntent } from '@stripe/stripe-js';

import type {
  Partner,
  PartnerManager,
  PartnerPayment,
  PartnerState,
} from '../slices/partners';
import type RootState from '../types/RootState';
import type ErrorStoreType from '../types/ErrorStoreType';
import GetPartner from '../../services/partners/GetPartner';
import UploadLogo from '../../services/partners/UploadLogo';
import DeleteLogo from '../../services/partners/DeleteLogo';
import GetPartners from '../../services/partners/GetPartners';
import UpdatePartner from '../../services/partners/UpdatePartner';
import CreateSetupIntent from '../../services/partners/CreateSetupIntent';
import SwitchChargeMethod from '../../services/partners/SwitchChargeMethod';
import UpdatePaymentMethod from '../../services/partners/UpdatePaymentMethod';
import GetPaymentByPartnerId from '../../services/partners/GetPaymentByPartnerId';
import GetSetupIntentByPartnerId from '../../services/partners/GetSetupIntentByPartnerId';
import GetPartnerManagerByPartnerId from '../../services/partnerManager/GetPartnerManagerByPartnerId';

export const getPartner = createAsyncThunk<
  { partner: Partner; manager: PartnerManager },
  number,
  { rejectValue: ErrorStoreType }
>('partners/getPartner', async (partnerId, { rejectWithValue }) => {
  const {
    parsedData: partner,
    success,
    validationErrors,
    message,
  } = await GetPartner(partnerId);

  if (!success || !partner) {
    throw rejectWithValue({ message, validationErrors });
  }

  const {
    parsedData: manager,
    success: successManager,
    validationErrors: validationErrorsManager,
    message: messageManager,
  } = await GetPartnerManagerByPartnerId(`${partnerId}`);

  if (!successManager || !manager) {
    throw rejectWithValue({
      message: messageManager,
      validationErrors: validationErrorsManager,
    });
  }

  return { partner, manager };
});

export const getPartners = createAsyncThunk<
  PartnerState['partners'],
  void,
  { rejectValue: ErrorStoreType }
>('partners/getPartners', async (_, { rejectWithValue }) => {
  const {
    message,
    success,
    parsedData: partners,
    validationErrors,
  } = await GetPartners();

  if (!success || !partners) {
    throw rejectWithValue({ message, validationErrors });
  }

  return partners;
});

export const updatePartner = createAsyncThunk<
  Partner,
  Partner,
  { rejectValue: ErrorStoreType }
>('partners/updatePartner', async (data: Partner, { rejectWithValue }) => {
  const {
    parsedData: partner,
    success,
    message,
    validationErrors,
  } = await UpdatePartner(data);

  if (success && partner) {
    return partner;
  }
  throw rejectWithValue({ message, validationErrors });
});

export const uploadLogoImage = createAsyncThunk<
  string,
  File,
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>('partners/uploadLogoImage', async (image, { rejectWithValue, getState }) => {
  const partnerId = getState().partners.partner?.id;

  if (!partnerId) {
    throw rejectWithValue({
      message: 'No partner id',
    });
  }

  const {
    message,
    data: imageUrl,
    validationErrors,
    success,
  } = await UploadLogo(image, `${partnerId}`);

  if (success && imageUrl) {
    return imageUrl;
  }

  throw rejectWithValue({
    message,
    validationErrors,
  });
});

export const deleteLogo = createAsyncThunk<
  void,
  void,
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>('partners/deleteLogo', async (_, { rejectWithValue, getState }) => {
  const partnerId = getState().partners.partner?.id;

  if (!partnerId) {
    throw rejectWithValue({
      message: 'No partner id',
    });
  }

  const { message, validationErrors, success } = await DeleteLogo(
    `${partnerId}`,
  );

  if (!success) {
    throw rejectWithValue({
      message,
      validationErrors,
    });
  }
});

export const createSetupIntent = createAsyncThunk<
  SetupIntent,
  void,
  { rejectValue: ErrorStoreType; state: RootState }
>('partners/createSetupIntent', async (_, { rejectWithValue, getState }) => {
  const partnerId = getState().partners.partner?.id;

  if (!partnerId) {
    throw rejectWithValue({
      message: 'No partner id',
    });
  }

  const {
    data: setupIntent,
    success,
    message,
    validationErrors,
  } = await CreateSetupIntent(partnerId);
  if (success && setupIntent) {
    return setupIntent;
  }
  throw rejectWithValue({
    message,
    validationErrors,
  });
});

export const retrieveSetupIntent = createAsyncThunk<
  SetupIntent,
  number,
  {
    rejectValue: ErrorStoreType;
  }
>(
  'partners/retrieveSetupIntent',
  async (partnerId: number, { rejectWithValue }) => {
    const {
      data: setupIntent,
      message,
      success,
      validationErrors,
    } = await GetSetupIntentByPartnerId(partnerId);

    if (success && setupIntent) {
      return setupIntent;
    }

    throw rejectWithValue({ message, validationErrors });
  },
);

export const getPartnerPayment = createAsyncThunk(
  'partners/getPayment',
  async (partnerId: number, { rejectWithValue }) => {
    const {
      parsedData: paymentMethod,
      message,
      success,
      validationErrors,
    } = await GetPaymentByPartnerId(partnerId);

    if (success && paymentMethod) {
      return paymentMethod;
    }

    throw rejectWithValue({ message, validationErrors });
  },
);

export const updatePaymentMethod = createAsyncThunk<
  SetupIntent,
  {
    paymentMethodId: string; // This id comes from the new payment method created
    type: string; // This type comes from the new payment method created
    companyName?: string;
  },
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>('partners/updatePayment', async (data, { rejectWithValue, getState }) => {
  const partnerId = getState().partners.partner?.id;

  if (!partnerId) {
    throw rejectWithValue({
      message: 'No partner data',
    });
  }

  const {
    data: setupIntent,
    message,
    success,
    validationErrors,
  } = await UpdatePaymentMethod(partnerId, data);

  if (success && setupIntent) {
    return setupIntent;
  }

  throw rejectWithValue({ message, validationErrors });
});

export const switchChargeMethod = createAsyncThunk<
  PartnerPayment,
  string,
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>(
  'partners/switchChargeMethod',
  async (chargeMethod, { rejectWithValue, getState }) => {
    const partnerId = getState().partners.partner?.id;

    if (!partnerId) {
      throw rejectWithValue({
        message: 'No partner data',
      });
    }

    const {
      parsedData: paymentMethod,
      message,
      success,
      validationErrors,
    } = await SwitchChargeMethod(partnerId, chargeMethod);

    if (success && paymentMethod) {
      return paymentMethod;
    }

    throw rejectWithValue({ message, validationErrors });
  },
);
