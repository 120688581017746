import http from '../http';
import UserEndpoints from './Endpoints';
import { multiParse } from '../planDetails/Parser';
import type { DBPlanDetails } from '../planDetails/Parser';
import { ResponseParserList } from '../http/ResponseParser';
import type { PlanDetail } from '../../store/slices/planDetails';

const ChangeUserPlan = async (userId: string, planId: string) => {
  const { data: pureResponse } = await http.request({
    method: UserEndpoints.changeUserPlan.method,
    url: UserEndpoints.changeUserPlan.endpoint.replace(':userId', userId),
    data: { planId },
  });

  return ResponseParserList<DBPlanDetails, PlanDetail>(
    pureResponse,
    multiParse,
  );
};

export default ChangeUserPlan;
