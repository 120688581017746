import styled from 'styled-components';

import COLORS from '../../../styles/Colors';

interface Props {
  $borderColor?: COLORS;
  $paddingY?: number;
  $paddingYTop?: number;
  $paddingYBottom?: number;
  $marginY?: number;
  $marginYTop?: number;
  $marginYBottom?: number;
  $justify?: string;
}

const BorderedHeader = styled.div<Props>(
  ({
    $borderColor = COLORS.ATHENS_GRAY,
    $paddingY = 5,
    $paddingYTop,
    $paddingYBottom,
    $marginY = 5,
    $marginYTop,
    $marginYBottom,
    $justify = 'space-between',
  }) => ({
    borderTop: `1px solid ${$borderColor}`,
    borderBottom: `1px solid ${$borderColor}`,
    paddingTop: `${$paddingYTop || $paddingY}px`,
    paddingBottom: `${$paddingYBottom || $paddingY}px`,
    marginTop: `${$marginYTop || $marginY}px`,
    marginBottom: `${$marginYBottom || $marginY}px`,
    display: 'flex',
    justifyContent: $justify,
  }),
);

export default BorderedHeader;
