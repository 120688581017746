import { Dropdown, Menu, Spin } from 'antd';
import type { VFC } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import styled from 'styled-components';

import COLORS from '../../../styles/Colors';
import { capitalize } from '../../../utils/capitalize';
import type { DBUsers } from '../../../services/users/Parser';
import useAllowedAccountAction from './hooks/useAllowedAccountAction';

interface Props {
  userId: string;
  currentState: DBUsers['ACCOUNT_STATUS'];
}

const AccountStatusOptions: VFC<Props> = ({ userId, currentState }) => {
  const { action, actionName, isUserLoading } = useAllowedAccountAction(
    currentState,
    userId,
  );

  return isUserLoading ? (
    <Spin />
  ) : (
    <StyledDropdown
      arrow
      overlay={
        <Menu>
          <Menu.Item onClick={action} key={actionName}>
            {capitalize(actionName)}
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <BsThreeDots size={20} color={COLORS.CERULEAN_BLUE} />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  cursor: pointer;
`;

export default AccountStatusOptions;
