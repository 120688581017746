import type { VFC } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Divider, List, Modal } from 'antd';

import { TMTitle } from '../common/typography';
import usePartners from '../../hooks/usePartners';
import type ModalProps from '../../types/ModalProps';
import SearchInput from '../common/inputs/SearchInput';
import FlexContainer from '../common/flex/FlexContainer';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';
import SimpleProfileCard from '../common/profile/SimpleProfileCard';

interface Props extends Omit<ModalProps, 'hideHandler'> {
  handleSelect: (partnerId: number) => void;
}

const SelectPartnerModal: VFC<Props> = ({ isVisible, handleSelect }) => {
  const { loadingStatus, partners } = usePartners();
  const [filter, setFilter] = useState('');

  const getPartnersToShow = () => {
    if (partners.length <= 0 || !filter) return partners;
    return partners.filter((partner) =>
      partner.name.toLowerCase().includes(filter.toLowerCase()),
    );
  };

  return (
    <Modal
      visible={isVisible}
      footer={null}
      centered
      closable={false}
      maskClosable={false}
      width={700}
    >
      <FlexContainer $justify="space-between" $align="center">
        <TMTitle level={4}>Choose Partner</TMTitle>
        <SearchInput value={filter} onChange={(value) => setFilter(value)} />
      </FlexContainer>
      <Divider />
      <List
        style={{
          maxHeight: 500,
          overflowY: 'scroll',
          paddingRight: 10,
        }}
        loading={loadingStatus === LOADING_STATUSES.LOADING}
        dataSource={getPartnersToShow()}
        renderItem={({ name, logoUrl, id }) => (
          <ListItem onClick={() => handleSelect(id)}>
            <FlexContainer $justify="space-between" $width="100%">
              <SimpleProfileCard nameAsText name={name} avatar={logoUrl} />
            </FlexContainer>
          </ListItem>
        )}
      />
    </Modal>
  );
};

const ListItem = styled(List.Item)`
  cursor: pointer !important;

  .ant-typography {
    cursor: pointer !important;
  }
`;

export default SelectPartnerModal;
