import http from '../http';
import { ResponseParser } from '../http/ResponseParser';
import PartnerEndpoints from './Endpoints';

const UploadLogo = async (image: File, partnerId: string) => {
  const data = new FormData();
  data.append('file', image);

  const { data: pureData } = await http.request({
    url: PartnerEndpoints.uploadLogo.endpoint.replace(':partnerId', partnerId),
    method: PartnerEndpoints.uploadLogo.method,
    data,
  });

  return ResponseParser<string>(pureData);
};

export default UploadLogo;
