import http from '../http';
import { ResponseParser } from '../http/ResponseParser';
import PartnerEndpoints from './Endpoints';

const DeleteLogo = async (partnerId: string) => {
  const { data: pureData } = await http.request({
    url: PartnerEndpoints.deleteLogo.endpoint.replace(':partnerId', partnerId),
    method: PartnerEndpoints.deleteLogo.method,
  });

  return ResponseParser(pureData);
};

export default DeleteLogo;
