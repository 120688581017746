import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const PlayButton: VFC<IconInterface> = ({ color = COLORS.GRAY, className }) => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <ellipse cx="24.0525" cy="24" rx="24.0525" ry="24" fill={color} />
      <path
        d="M32.5859 24.3869L18.62 32.7678L18.62 16.006L32.5859 24.3869Z"
        fill="black"
      />
    </svg>
  );
};

export default PlayButton;
