import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { MdOutlineModeEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';

import UploadImageModal from './UploadImageModal';
import UserAvatar from '../../common/profile/UserAvatar';
import FlexContainer from '../../common/flex/FlexContainer';
import { deleteLogo } from '../../../store/thunks/partners';
import { resetWriteStatus } from '../../../store/slices/partners';
import DeleteConfirmation from '../../common/modals/DeleteConfirmation';
import {
  useIsWritingPartner,
  useIsWritingPartnerFinished,
  usePartnerLogoUrl,
  usePartnerName,
} from '../../../store/selectors/partners';

const LogoImage = () => {
  const dispatch = useDispatch();

  const partnerName = usePartnerName();
  const logoPartnerUrl = usePartnerLogoUrl();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const hideCreateModalHandler = () => setIsCreateModalVisible(false);
  const showCreateModalHandler = () => setIsCreateModalVisible(true);

  const hideDeleteModalHandler = () => setIsDeleteModalVisible(false);
  const showDeleteModalHandler = () => setIsDeleteModalVisible(true);

  const isWriting = useIsWritingPartner();
  const isFinished = useIsWritingPartnerFinished();

  useEffect(() => {
    if (isFinished) {
      dispatch(resetWriteStatus());
      setIsDeleteModalVisible(false);
    }
  }, [isFinished, setIsDeleteModalVisible, dispatch]);

  return (
    <>
      <Row>
        <Col offset={3}>
          <UserAvatar
            name={partnerName ?? 'U'}
            src={logoPartnerUrl}
            size={250}
          />
          <FlexContainer $gap={15} $marginTop={50}>
            <Button type="primary" block onClick={showCreateModalHandler}>
              <FlexContainer $align="center" $justify="space-evenly">
                <MdOutlineModeEdit /> Change
              </FlexContainer>
            </Button>
            <Button block onClick={showDeleteModalHandler}>
              <FlexContainer $align="center" $justify="space-evenly">
                <RiDeleteBin6Line /> Remove
              </FlexContainer>
            </Button>
          </FlexContainer>
        </Col>
      </Row>
      <UploadImageModal
        isVisible={isCreateModalVisible}
        hideHandler={hideCreateModalHandler}
      />
      <DeleteConfirmation
        isVisible={isDeleteModalVisible}
        hideHandler={hideDeleteModalHandler}
        deleteHandler={() => dispatch(deleteLogo())}
        isDeleting={isWriting}
      />
    </>
  );
};

export default LogoImage;
