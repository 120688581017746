import type { VFC } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { HiOutlineTrash } from 'react-icons/hi';
import { Button, Col, Form, Input, Modal, Row } from 'antd';

import {
  useIsInvitingUsers,
  useIsInvitingUsersFinished,
  useWasInvitingUsersFailure,
  useWasInvitingUsersSuccessful,
} from '../../../store/selectors/users';
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';
import type ModalProps from '@/types/ModalProps';
import Divider from '../../common/Divider/Divider';
import SelectPlan from '../../common/inputs/SelectPlan';
import { inviteUsers } from '../../../store/thunks/users';
import FlexContainer from '../../common/flex/FlexContainer';
import type { InviteUserForm } from '../../../store/slices/users';
import { resetInviteUsersStatus } from '../../../store/slices/users';

interface Props extends ModalProps {
  minNumberOfForms: number;
  maxNumberOfForms: number;
}

const InviteUsersModal: VFC<Props> = ({
  isVisible,
  hideHandler,
  minNumberOfForms,
  maxNumberOfForms,
}) => {
  const dispatch = useDispatch();
  const isInviting = useIsInvitingUsers();
  const isInvitingFinished = useIsInvitingUsersFinished();
  const wasInvitingUsersSuccessful = useWasInvitingUsersSuccessful();
  const wasInvitingUsersFailure = useWasInvitingUsersFailure();

  useEffect(() => {
    if (isInvitingFinished) {
      hideHandler();
    }
  }, [isInvitingFinished, hideHandler]);

  return (
    <>
      <Modal
        visible={isVisible}
        onCancel={hideHandler}
        footer={null}
        title="Invite Users"
        width={900}
        destroyOnClose
        centered
      >
        <Form
          onFinish={({ users }: { users: InviteUserForm[] }) =>
            dispatch(inviteUsers(users))
          }
        >
          <Form.List
            name="users"
            initialValue={Array(minNumberOfForms).fill({
              firstName: null,
              lastName: null,
              email: null,
              planKey: null,
              interval: null,
            })}
          >
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <FlexContainer>
                      <FlexContainer $flex={20}>
                        <Row justify="space-between" gutter={20}>
                          <Col span={12}>
                            <StyledFormItem
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter your first name',
                                },
                              ]}
                              label="First Name"
                              name={[name, 'firstName']}
                              {...restField}
                            >
                              <Input placeholder="First Name" />
                            </StyledFormItem>
                          </Col>
                          <Col span={12}>
                            <StyledFormItem
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter your last name',
                                },
                              ]}
                              label="Last Name"
                              name={[name, 'lastName']}
                              {...restField}
                            >
                              <Input placeholder="Last Name" />
                            </StyledFormItem>
                          </Col>
                          <Col span={12}>
                            <StyledFormItem
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter your email',
                                },
                              ]}
                              label="E-mail"
                              name={[name, 'email']}
                              {...restField}
                            >
                              <Input placeholder="E-mail" />
                            </StyledFormItem>
                          </Col>
                          <Col span={12}>
                            <StyledFormItem
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a plan',
                                },
                              ]}
                              label="Plan"
                              name={[name, 'planKey']}
                              {...restField}
                            >
                              <SelectPlan usePlanKeysAsValues />
                            </StyledFormItem>
                          </Col>
                        </Row>
                      </FlexContainer>
                      <FlexContainer $justify="flex-end" $flex={1}>
                        {fields.length > minNumberOfForms && (
                          <Button size="small" onClick={() => remove(name)}>
                            <FlexContainer $align="center">
                              <HiOutlineTrash />
                            </FlexContainer>
                          </Button>
                        )}
                      </FlexContainer>
                    </FlexContainer>
                    <Divider style={{ marginBottom: 30 }} />
                  </div>
                ))}
                <FlexContainer $justify="center" $marginTop={15} $gap={50}>
                  <StyledButton
                    type="primary"
                    size="large"
                    loading={isInviting}
                    htmlType="submit"
                  >
                    Invite
                  </StyledButton>
                  <StyledButton
                    size="large"
                    onClick={() => add()}
                    disabled={fields.length >= maxNumberOfForms}
                  >
                    Add User
                  </StyledButton>
                </FlexContainer>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
      <SuccessModal
        isVisible={wasInvitingUsersSuccessful}
        hideHandler={() => dispatch(resetInviteUsersStatus())}
      />
      <ErrorModal
        isVisible={wasInvitingUsersFailure}
        hideHandler={() => dispatch(resetInviteUsersStatus())}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 200px;
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    min-width: 100px;
    text-align: left;
  }
`;

export default InviteUsersModal;
