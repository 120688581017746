import { useEffect } from 'react';
import { Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import PlanItem from './PlanItem';
import Divider from '../../../common/Divider/Divider';
import Telescope from '../../../../assets/images/telescope.png';
import Planet from '../../../../assets/images/planet.png';
import Rocket from '../../../../assets/images/rocket.png';
import {
  useContract,
  useIsLoadingContract,
} from '../../../../store/selectors/contracts';
import {
  useIsLoadingPlanDetails,
  usePlanDetails,
} from '../../../../store/selectors/planDetails';
import { useIsLoadingPlans, usePlans } from '../../../../store/selectors/plans';
import { getPlanDetailsByContract } from '../../../../store/thunks/planDetails';
import { loadPlans } from '../../../../store/thunks/plans';

const PlanDetails = () => {
  const dispatch = useDispatch();
  const contract = useContract();
  const isContractLoading = useIsLoadingContract();
  const planDetails = usePlanDetails();
  const isLoading = useIsLoadingPlanDetails();
  const isPlansLoading = useIsLoadingPlans();
  const plans = usePlans();

  useEffect(() => {
    if (contract && planDetails.length === 0 && !isLoading)
      dispatch(getPlanDetailsByContract(contract.id));
  }, [planDetails, isLoading, contract, dispatch]);

  useEffect(() => {
    if (plans.length === 0 && !isPlansLoading) dispatch(loadPlans());
  }, [plans, dispatch, isPlansLoading]);

  const getPlanDetailByName = (planName: string) => {
    const plan = plans.find((p) => p.name === planName);
    return planDetails.find(
      (planDetail) => planDetail.planId === Number(plan?.id),
    );
  };

  return (
    <Container>
      <Skeleton
        loading={isLoading || isContractLoading || isPlansLoading}
        avatar={{ size: 80 }}
        active
      >
        <PlanItem
          image={Telescope}
          title="Basic"
          plan={getPlanDetailByName('BASIC')}
        />
      </Skeleton>
      <Divider type="vertical" />
      <Skeleton
        loading={isLoading || isContractLoading || isPlansLoading}
        avatar={{ size: 80 }}
        active
      >
        <PlanItem
          image={Rocket}
          title="Advanced"
          plan={getPlanDetailByName('ADVANCED')}
        />
      </Skeleton>
      <Divider type="vertical" />
      <Skeleton
        loading={isLoading || isContractLoading || isPlansLoading}
        avatar={{ size: 80 }}
        active
      >
        <PlanItem
          image={Planet}
          title="Premium"
          plan={getPlanDetailByName('PREMIUM')}
        />
      </Skeleton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

export default PlanDetails;
