import http from '../http';
import UserEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';
import type { UserActivityMetrics } from '../../store/slices/users';

interface PureData {
  differenceRespectToPreviousMonth: number | null;
  activityPerMonth: UserActivityMetrics['activityPerMonth'];
}

const GetUsersActivityMetrics = async () => {
  const { data: pureResponse } = await http.request({
    url: UserEndpoints.getUsersActivityMetrics.endpoint,
    method: UserEndpoints.getUsersActivityMetrics.method,
  });

  return ResponseParser<PureData>(pureResponse);
};

export default GetUsersActivityMetrics;
