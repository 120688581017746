import { Image } from 'antd';
import styled from 'styled-components';

import { TMText, TMTitle } from '../common/typography';
import DownloadTemplateExample from '../../assets/images/downloadTemplateExample.png';
import COLORS from '../../styles/Colors';

const LeftColumn = () => (
  <>
    <TMTitle level={4} $marginBottom={10}>
      STEP 1 | DOWNLOAD TEMPLATE
    </TMTitle>
    <TMText $color={COLORS.TITLE_BLACK}>
      At the top of the &ldquo;User Management&rdquo; page, click{' '}
      <b>&ldquo;Download file Template for Bulk&rdquo;</b> to download a excel
      (.xlsx) file.
    </TMText>
    <StyledImage src={DownloadTemplateExample} preview={false} />
    <TMTitle level={4} $marginBottom={10}>
      STEP 2 | OPEN TEMPLATE
    </TMTitle>
    <TMText $color={COLORS.TITLE_BLACK}>
      Open <b>XLSX</b> file in a spreadsheet application, such as Google Sheets
      or Microsoft Excel.
    </TMText>
    <TMTitle level={4} $marginTop={10}>
      STEP 3 | ENTER USER INFO
    </TMTitle>
    <TMText $color={COLORS.TITLE_BLACK} $marginY={10} $block>
      The file has columns for the attributes (First Name, Last Name, Email,
      Phone, Plan) that will appear for each user&apos;s profile. Profiles will
      appear in the Admin console in &ldquo;User Management&rdquo;.
    </TMText>
    <TMText $color={COLORS.TITLE_BLACK} $block $marginYBottom={10}>
      For each user you want to add, enter the following required information in
      these columns in the spreadsheet.
    </TMText>

    <ul>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>First Name</TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>Last Name</TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>Email</TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>Phone (optional)</TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>Plan</TMText>
      </li>
    </ul>
  </>
);

const StyledImage = styled(Image)`
  margin: 20px 0px;
`;

export default LeftColumn;
