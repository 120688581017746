import type { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import type { ParagraphProps } from 'antd/lib/typography/Paragraph';
import COLORS from '../../../styles/Colors';

const { Paragraph } = Typography;

const TMParagraph: FC<ParagraphProps> = ({ children, ...props }) => {
  return <StyledParagraph {...props}>{children}</StyledParagraph>;
};

const StyledParagraph = styled(Paragraph)`
  color: ${COLORS.TEXT_GRAY} !important;
  margin-bottom: 0px !important;
`;

export default TMParagraph;
