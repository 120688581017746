import { Col, Row } from 'antd';
import styled from 'styled-components';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';

const Content = () => {
  return (
    <StyledRow gutter={50}>
      <Col span={24} lg={12}>
        <LeftColumn />
      </Col>

      <Col span={24} lg={12}>
        <RightColumn />
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: 20px;
`;

export default Content;
