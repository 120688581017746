import { Radio } from 'antd';
import type { VFC } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import BaseFilter from './BaseFilter';
import UserManagementContext from '../Context';
import { TMText } from '../../common/typography';
import ResetButton from '../../common/Buttons/ResetButton';
import FlexContainer from '../../common/flex/FlexContainer';
import { getPartnerUsers } from '../../../store/thunks/users';

interface Props {
  closeModal: () => void;
}

const AccountStatusFilter: VFC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();

  const {
    planFilter,
    setCurrentPage,
    searchValue,
    setAccountStatusFilter,
    accountStatusFilter,
    nameSorter,
    lastActivitySorter,
  } = useContext(UserManagementContext);

  const handleChange = (value: string) => {
    closeModal();
    setAccountStatusFilter(value);
    setCurrentPage(1);
    dispatch(
      getPartnerUsers({
        searchValue,
        planFilter,
        accountStatusFilter: value,
        nameSorter,
        lastActivitySorter,
      }),
    );
  };

  return (
    <BaseFilter>
      <TMText>Filter by</TMText>
      <Radio.Group
        value={accountStatusFilter}
        onChange={({ target: { value } }) => handleChange(value)}
      >
        <FlexContainer $direction="column" $gap={2}>
          <Radio value="pending">Pending</Radio>
          <Radio value="deactivated">Deactivated</Radio>
          <Radio value="active">Active</Radio>
        </FlexContainer>
      </Radio.Group>
      <FlexContainer>
        <StyledResetButton onClick={() => handleChange('none')}>
          Reset
        </StyledResetButton>
      </FlexContainer>
    </BaseFilter>
  );
};

const StyledResetButton = styled(ResetButton)`
  width: 100%;
  margin: 0;
`;

export default AccountStatusFilter;
