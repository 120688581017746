import type { SetupIntent } from '@stripe/stripe-js';
import http from '../http';
import PartnerEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';

const UpdatePaymentMethod = async (
  partnerId: number,
  data: { paymentMethodId: string; type: string },
) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.updatePaymentMethod.method,
    url: PartnerEndpoints.updatePaymentMethod.endpoint.replace(
      ':partnerId',
      `${partnerId}`,
    ),
    data,
  });

  return ResponseParser<SetupIntent>(pureResponse);
};

export default UpdatePaymentMethod;
