import styled from 'styled-components';

import Content from '../Dashboard/Layout/Content';
import Header from '../../components/UserManagement/Header';
import { NoBorderedTile } from '../../components/common/tiles';
import SubHeader from '../../components/UserManagement/SubHeader';
import ProcessInfo from '../../components/UserManagement/ProcessInfo';
import UsersTable from '../../components/UserManagement/UsersTable';
import UserManagementContextProvider from '../../components/UserManagement/Context/Provider';

const UserManagement = () => {
  return (
    <Content title="User Management">
      <Tile
        component={
          <UserManagementContextProvider>
            <div>
              <Header />
              <SubHeader />
              <ProcessInfo />
              <UsersTable />
            </div>
          </UserManagementContextProvider>
        }
      />
    </Content>
  );
};

const Tile = styled(NoBorderedTile)`
  padding: 15px;
`;

export default UserManagement;
