import http from '../http';
import { parse } from './Parser';
import type { DBContract } from './Parser';
import ContractEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';
import type { Contract } from '@/store/slices/contracts';

const GetContractByPartner = async (partnerId: number) => {
  const { data: pureResponse } = await http.request({
    method: ContractEndpoints.getContractByPartner.method,
    url: ContractEndpoints.getContractByPartner.endpoint,
    params: { partnerId },
  });

  return ResponseParser<DBContract, Contract>(pureResponse, parse);
};

export default GetContractByPartner;
