import type EndpointsType from '../../types/EndpointsType';

const PlanEndpoints = Object.freeze<EndpointsType>({
  loadPlans: {
    endpoint: '/plans',
    method: 'GET',
  },
});

export default PlanEndpoints;
