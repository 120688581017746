import { Button } from 'antd';
import styled from 'styled-components';
import { BiFilm } from 'react-icons/bi';

import { TMAnchor, TMText, TMTitle } from '../common/typography';
import COLORS from '../../styles/Colors';

const RightColumn = () => (
  <>
    <TMTitle level={4} $marginBottom={10}>
      STEP 4 | SAVE FILE
    </TMTitle>
    <TMText $color={COLORS.TITLE_BLACK}>
      After you&apos;ve filled in the spreadsheet, save it as a <b>XLSX</b> file{' '}
      <b>(.xlsx)</b>
    </TMText>
    <TMText $color={COLORS.TITLE_BLACK} $block $marginY={10} $bold>
      Tips:
    </TMText>

    <ul>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>
          Maximum XLSX file size is <b>35 MB</b>.
        </TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>
          Maximum number of records per file is <b>150,000</b>.
        </TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>
          If your file is larger or has too many records, open it in your
          spreadsheet program, split the table into separate files, and save
          each one as a <b>XLSX</b> file. Make sure you include the column
          headings row in all <b>XLSX</b> files.
        </TMText>
      </li>
    </ul>

    <TMTitle level={4} $marginBottom={10}>
      STEP 5 | UPLOAD USERS
    </TMTitle>

    <ul>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>
          At the top of the &ldquo;Users Management&rdquo; page, click{' '}
          <Button type="primary">Upload Users in Bulk</Button>
        </TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>
          Attach <b>XLSX</b> file.
        </TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>
          Browse to the location on your computer and attach the <b>XLSX</b>{' '}
          file.
        </TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>
          Click Upload. If there&apos;s an error, enter the missing information
          in your spreadsheet and upload the file again. For more information,
          go to Resolve common errors below.
        </TMText>
      </li>
      <li>
        <TMText $color={COLORS.TITLE_BLACK}>
          Your Tasks list opens automatically and shows the progress of the
          upload. Once processing is complete you&apos;ll receive an email
          report.
        </TMText>
      </li>
    </ul>

    <TMText $color={COLORS.TITLE_BLACK} $marginYTop={10} $italic>
      It can take up to 24 hours for new users to have access to Token Metrics
      and to appear in on your User Management Page
    </TMText>

    <TMText $color={COLORS.TITLE_BLACK} $marginY={10} $block>
      For additional support{' '}
      <TMAnchor
        blank
        href="https://help.tokenmetrics.com/en/"
        $color={COLORS.CERULEAN_BLUE}
      >
        Click here
      </TMAnchor>
      .
    </TMText>

    <TMAnchor $bold href="#" $color={COLORS.CERULEAN_BLUE} $verticalAligned>
      <StyledIcon />
      CLICK FOR A HOW TO VIDEO
    </TMAnchor>
  </>
);

const StyledIcon = styled(BiFilm)`
  margin-right: 5px;
`;

export default RightColumn;
