import { Typography } from 'antd';
import styled from 'styled-components';

import COLORS from '../../../styles/Colors';

interface Props {
  $textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  $color?: COLORS;
  $marginTop?: number;
  $marginBottom?: number;
}

const TMTitle = styled(Typography.Title)<Props>(
  ({
    $textAlign = 'left',
    $color = COLORS.TITLE_BLACK,
    $marginTop = 0,
    $marginBottom = 0,
  }) => ({
    textAlign: $textAlign,
    color: `${$color} !important`,
    marginTop: `${$marginTop !== 0 ? $marginTop : 0}px !important`,
    marginBottom: `${$marginBottom !== 0 ? $marginBottom : 0}px !important`,
  }),
);

export default TMTitle;
