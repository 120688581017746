import { useState } from 'react';

const useModal = (initialModalState = false) => {
  const [isVisible, setIsVisible] = useState(initialModalState);

  const showHandler = () => setIsVisible(true);
  const hideHandler = () => setIsVisible(false);

  return {
    isVisible,
    showHandler,
    hideHandler,
  };
};

export default useModal;
