import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const Plus: VFC<IconInterface> = ({ color = COLORS.GRAY, className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 1V8M8 8V15M8 8H15M8 8H1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Plus;
