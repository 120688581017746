import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const Users: VFC<IconInterface> = ({ color = COLORS.GRAY }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 19.5C10.75 19.5 9.5 19.5 9.5 18.25C9.5 17 10.75 13.25 15.75 13.25C20.75 13.25 22 17 22 18.25C22 19.5 20.75 19.5 20.75 19.5H10.75ZM15.75 12C16.7446 12 17.6984 11.6049 18.4017 10.9017C19.1049 10.1984 19.5 9.24456 19.5 8.25C19.5 7.25544 19.1049 6.30161 18.4017 5.59835C17.6984 4.89509 16.7446 4.5 15.75 4.5C14.7554 4.5 13.8016 4.89509 13.0983 5.59835C12.3951 6.30161 12 7.25544 12 8.25C12 9.24456 12.3951 10.1984 13.0983 10.9017C13.8016 11.6049 14.7554 12 15.75 12ZM8.52 19.5C8.33469 19.1098 8.24228 18.6819 8.25 18.25C8.25 16.5562 9.1 14.8125 10.67 13.6C9.88636 13.3585 9.06994 13.2405 8.25 13.25C3.25 13.25 2 17 2 18.25C2 19.5 3.25 19.5 3.25 19.5H8.52ZM7.625 12C8.4538 12 9.24866 11.6708 9.83471 11.0847C10.4208 10.4987 10.75 9.7038 10.75 8.875C10.75 8.0462 10.4208 7.25134 9.83471 6.66529C9.24866 6.07924 8.4538 5.75 7.625 5.75C6.7962 5.75 6.00134 6.07924 5.41529 6.66529C4.82924 7.25134 4.5 8.0462 4.5 8.875C4.5 9.7038 4.82924 10.4987 5.41529 11.0847C6.00134 11.6708 6.7962 12 7.625 12Z"
        fill={color}
      />
    </svg>
  );
};

export default Users;
