import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createRootReducer from './slices';

const store = configureStore({
  reducer: createRootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'test') {
      return getDefaultMiddleware();
    }
    return getDefaultMiddleware().concat(logger);
  },
  enhancers: [],
});

export default store;
