import type { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';

import COLORS from '../../../../../../styles/Colors';
import { TMText } from '../../../../../common/typography';
import { capitalize } from '../../../../../../utils/capitalize';
import { usePaymentMethod } from '../../../../../../store/selectors/partners';

const USBankInformation: FC = () => {
  const paymentMethod = usePaymentMethod();

  return (
    <Container>
      <RowContainer>
        <Col span={10}>
          <TMText>Name on Account:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.ach.holderName}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Bank Routing Number:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.ach.routingNumber}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>
            {capitalize(paymentMethod?.ach.accountType || '')} Account Number:
          </TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            XXXXXXXX{paymentMethod?.ach.last4}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>State:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.state}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Address line 1:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.line1}
          </TMText>
        </Col>
      </RowContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
`;

const RowContainer = styled(Row)`
  margin-bottom: 20px;
`;

export default USBankInformation;
