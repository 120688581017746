import type { PartnerManager } from '../../store/slices/partners';

export interface DBPartnerManager {
  ID: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  EMAIL: string;
  PHONE_NUMBER: string;
  TELEGRAM: string;
  ACTIVE: boolean;
  CREATED_AT: string;
  UPDATED_AT: string;
}

const PartnerManagerParser = {
  parse: (dbPartnerManager: DBPartnerManager): PartnerManager => ({
    id: dbPartnerManager.ID,
    active: dbPartnerManager.ACTIVE,
    createdAt: dbPartnerManager.CREATED_AT,
    email: dbPartnerManager.EMAIL,
    firstName: dbPartnerManager.FIRST_NAME,
    lastName: dbPartnerManager.LAST_NAME,
    phoneNumber: dbPartnerManager.PHONE_NUMBER,
    telegram: dbPartnerManager.TELEGRAM,
    updatedAt: dbPartnerManager.UPDATED_AT,
  }),
  multiParse: (dbPartnerManagers: DBPartnerManager[]): PartnerManager[] =>
    dbPartnerManagers.map(PartnerManagerParser.parse),
};

export const { parse } = PartnerManagerParser;
export default PartnerManagerParser;
