import type { AnyAction } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import users from './users';
import plans from './plans';
import contracts from './contracts';
import partners from './partners';
import partnerAdmins from './partnerAdmins';
import planDetails from './planDetails';
import invoices from './invoices';

const rootReducer = combineReducers({
  users,
  plans,
  contracts,
  partners,
  partnerAdmins,
  planDetails,
  invoices,
});

const createRootReducer = (
  state: ReturnType<typeof rootReducer> | undefined,
  action: AnyAction,
) => {
  return rootReducer(state, action);
};

export default createRootReducer;
