import styled from 'styled-components';

import Divider from '../../common/Divider/Divider';
import COLORS from '../../../styles/Colors';
import { TMTitle, TMText, TMAnchor } from '../../common/typography';
import { useContract } from '../../../store/selectors/contracts';

const Title = () => {
  const contract = useContract();

  return (
    <Container>
      <TMTitle level={5}>Plan Overview</TMTitle>
      <Divider type="vertical" sideMargin={25} />
      <TMText>
        See details of each plan{' '}
        <TMAnchor
          href="https://tokenmetrics.com/pricing"
          $color={COLORS.CERULEAN_BLUE}
          $inline
          blank
        >
          Here
        </TMAnchor>
      </TMText>
      <Divider type="vertical" sideMargin={25} />
      <TMAnchor
        href={contract?.contractUrl || 'https://tokenmetrics.com'}
        $color={COLORS.CERULEAN_BLUE}
        blank
      >
        Your Contract
      </TMAnchor>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

export default Title;
