import styled from 'styled-components';

import Routes from '../../routes/routeNames';
import ContentWrapper from '../Dashboard/Layout/Content';
import Header from '../../components/HowToUploadUsers/Header';
import { NoBorderedTile } from '../../components/common/tiles';
import Content from '../../components/HowToUploadUsers/Content';

const HowToUploadUsersScreens = () => {
  return (
    <ContentWrapper
      title="How To Upload Users"
      goBackTo={Routes.UserManagement}
      goBackLabel="Back to User Management"
    >
      <Tile
        component={
          <div>
            <Header />
            <Content />
          </div>
        }
      />
    </ContentWrapper>
  );
};

const Tile = styled(NoBorderedTile)`
  padding: 20px 40px;
`;

export default HowToUploadUsersScreens;
