import type { VFC } from 'react';
import { Tile } from '../../tiles';
import BillingHistory from './BillingHistory';

interface Props {
  noHeight?: boolean;
}

const BillingHistoryTile: VFC<Props> = ({ noHeight = false }) => {
  return (
    <Tile
      $height={noHeight ? undefined : 350}
      title="Billing History"
      component={<BillingHistory />}
    />
  );
};

export default BillingHistoryTile;
