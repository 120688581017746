import Nut from '../../../../assets/icons/Nut';
import House from '../../../../assets/icons/House';
import CreditCard from '../../../../assets/icons/CreditCard';
import Users from '../../../../assets/icons/Users';
// import Mail from '../../../../assets/icons/Mail';
// import Bell from '../../../../assets/icons/Bell';
// import CircleQuestionMark from '../../../../assets/icons/CircleQuestionMark';
import RouteNames from '../../../../routes/routeNames';

const SidebarItems = [
  {
    label: 'Dashboard',
    path: RouteNames.Dashboard,
    icon: House,
  },
  {
    label: 'Settings',
    path: RouteNames.Settings,
    icon: Nut,
  },
  {
    label: 'Manage Your Plan',
    path: RouteNames.ManageYourPlan,
    icon: CreditCard,
  },
  {
    label: 'User Management',
    path: RouteNames.UserManagement,
    icon: Users,
  },
  // {
  //   label: 'Customize Email',
  //   path: RouteNames.Dashboard,
  //   icon: Mail,
  // },
  // {
  //   label: 'Message',
  //   path: RouteNames.Dashboard,
  //   icon: Bell,
  // },
  // {
  //   label: 'FAQ',
  //   path: RouteNames.Dashboard,
  //   icon: CircleQuestionMark,
  // },
];

export default SidebarItems;
