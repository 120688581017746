import http from '../http';
import { parse } from './Parser';
import type { DBPartnerAdmin } from './Parser';
import PartnerAdminEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';
import type { PartnerAdmin } from '@/store/slices/partnerAdmins';

const GetPartnerAdmin = async (oktaUid: string) => {
  const { data: pureResponse } = await http.request({
    method: PartnerAdminEndpoints.getPartnerAdmin.method,
    url: PartnerAdminEndpoints.getPartnerAdmin.endpoint.replace(
      ':oktaUid',
      `${oktaUid}`,
    ),
  });

  return ResponseParser<DBPartnerAdmin, PartnerAdmin>(pureResponse, parse);
};

export default GetPartnerAdmin;
