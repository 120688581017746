import type { RouteObject } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import Login from '../screens/Login';
import RouteNames from './routeNames';
import Summary from '../screens/Summary';
import Settings from '../screens/Settings';
import Dashboard from '../screens/Dashboard/Layout';
import AuthRequired from './middlewares/AuthRequired';
import ManageYourPlan from '../screens/ManageYourPlan';
import UserManagement from '../screens/UserManagement';
import WhenAuthenticated from './middlewares/WhenAuthenticated';
import HowToUploadUsersScreen from '../screens/HowToUploadUsers';
import IsSuperAdmin from './middlewares/IsSuperAdmin';

const Routes: RouteObject[] = [
  {
    path: RouteNames.oktaCallback,
    element: <LoginCallback />,
  },
  {
    // When user is authenticated it can't access directly to login page
    element: <WhenAuthenticated />,
    children: [
      {
        path: RouteNames.Login,
        element: <Login />,
      },
    ],
  },
  {
    // Protected routes
    element: <AuthRequired />,
    children: [
      {
        // Dashboard layout
        element: <Dashboard />,
        children: [
          {
            // Summary view
            path: RouteNames.Dashboard,
            element: <Summary />,
          },
          {
            path: RouteNames.ManageYourPlan,
            element: <ManageYourPlan />,
          },
          {
            path: RouteNames.UserManagement,
            element: <UserManagement />,
          },
          {
            path: RouteNames.HowToUploadUsers,
            element: <HowToUploadUsersScreen />,
          },
          {
            path: RouteNames.Settings,
            element: <IsSuperAdmin />,
            children: [
              {
                path: RouteNames.Settings,
                element: <Settings />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default Routes;
