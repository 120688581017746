import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const TrendingDown: VFC<IconInterface> = ({ color = COLORS.GRAY }) => (
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1667 9L15.168 9C15.4441 9 15.668 8.77614 15.668 8.5L15.668 4.5C15.668 4.22386 15.4441 4 15.168 4C14.8918 4 14.668 4.22386 14.668 4.5L14.668 7.2942L9.18689 1.81311C9.09312 1.71935 8.96594 1.66667 8.83333 1.66667C8.70072 1.66667 8.57355 1.71935 8.47978 1.81311L5.5 4.79289L0.853553 0.146446C0.658291 -0.0488157 0.341709 -0.0488157 0.146447 0.146446C-0.0488155 0.341709 -0.0488155 0.658291 0.146447 0.853554L5.14645 5.85355C5.24021 5.94732 5.36739 6 5.5 6C5.63261 6 5.75978 5.94732 5.85355 5.85355L8.83333 2.87377L13.9596 8L11.168 8C10.8918 8 10.668 8.22386 10.668 8.5C10.668 8.77614 10.8918 9 11.168 9L15.1667 9Z"
      fill={color}
    />
  </svg>
);

export default TrendingDown;
