import { createAsyncThunk } from '@reduxjs/toolkit';

import GetPlanDetailsByContract from '../../services/planDetails/GetPlanDetailsByContract';

export const getPlanDetailsByContract = createAsyncThunk(
  'planDetails/getPlanDetailsByContract',
  async (contractId: number, { rejectWithValue }) => {
    const {
      parsedData: planDetails,
      success,
      message,
      validationErrors,
    } = await GetPlanDetailsByContract(contractId);

    if (success && planDetails) {
      return planDetails;
    }

    throw rejectWithValue({
      message,
      validationErrors,
    });
  },
);
