import type EndpointsType from '../../types/EndpointsType';

const PartnerAdminEndpoints = Object.freeze<EndpointsType>({
  getPartnerAdmin: {
    endpoint: '/partnerAdmin/:oktaUid',
    method: 'GET',
  },
  updatePartnerAdmin: {
    endpoint: '/partnerAdmin',
    method: 'PUT',
  },
  changePassword: {
    endpoint: '/api/user/change_password',
    method: 'PUT',
  },
  updateUserDidFirstLogin: {
    endpoint: '/partnerAdmin/:adminId/updateUserDidLogin',
    method: 'GET',
  },
});

export default PartnerAdminEndpoints;
