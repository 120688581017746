function parsePaymentMethod(type: string): string {
  if (type.includes('BANK')) {
    return 'Bank';
  }
  if (type.includes('CARD')) {
    return 'Card';
  }
  return 'SEPA';
}

export default parsePaymentMethod;
