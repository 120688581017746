import type { VFC } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';

import { TMAnchor, TMTitle } from '../../typography';
import ArrowUpRightFromSquare from '../../../../assets/icons/ArrowUpRightFromSquare';
import COLORS from '../../../../styles/Colors';

interface Props {
  children: string;
  url: string | undefined;
}

const BillingLink: VFC<Props> = ({ children, url }) => {
  return (
    <TMTitle level={5}>
      {children}{' '}
      {url && (
        <Tooltip
          placement="bottom"
          title="Download report"
          color={COLORS.WHITE}
        >
          <StyledAnchor href={url} blank>
            <ArrowUpRightFromSquare color={COLORS.TITLE_BLACK} />
          </StyledAnchor>
        </Tooltip>
      )}
    </TMTitle>
  );
};

const StyledAnchor = styled(TMAnchor)`
  margin-left: 10px !important;
`;

export default BillingLink;
