import { notification } from 'antd';
import { createSlice } from '@reduxjs/toolkit';

import {
  inviteUsers,
  uploadUsersInBulk,
  getPartnerUsers,
  getActiveProcess,
  getUsersActivityMetrics,
  deactivateUser,
  reactivateUser,
  sendAccountReminderEmail,
  changeUserPlan,
  deleteUsers,
} from '../thunks/users';
import type ErrorStoreType from '../types/ErrorStoreType';
import type { DBUsers } from '../../services/users/Parser';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  uploadId: string;
  email: string;
  lastActivity: string;
  uploadStatus: string;
  oktaUid: string;
  partnerId: string;
  active: boolean;
  planId: string;
  accountStatus: DBUsers['ACCOUNT_STATUS'];

  // Data set when needed
  writeStatus?: LOADING_STATUSES;
  writeError?: ErrorStoreType;

  writeStatusPlan?: LOADING_STATUSES;
  writeErrorPlan?: ErrorStoreType;
  // END data set when needed
}

export interface InviteUserForm {
  firstName: string;
  lastName: string;
  email: string;
  planKey: string;
}

interface ListUserState {
  items: User[];
  total: number;
  loading: LOADING_STATUSES;
  error: ErrorStoreType;
}

export interface UserActivityMetrics {
  userActivityMetricsLoadingStatus: LOADING_STATUSES;
  error: ErrorStoreType;
  activityPerMonth: Record<string, number> | null;
  differenceRespectToPreviousMonth: number | null;
}

export interface UsersState {
  readonly inviteUsersStatus: LOADING_STATUSES;
  readonly inviteUsersError: ErrorStoreType;
  readonly uploadBulkStatus: LOADING_STATUSES;
  readonly uploadBulkError: ErrorStoreType;
  readonly users: ListUserState;
  readonly activeProcess: boolean;
  readonly activeProcessLoadingStatus: LOADING_STATUSES;
  readonly activeProcessLoadingError: ErrorStoreType;
  readonly userActivityMetrics: UserActivityMetrics;

  readonly deleteStatus?: LOADING_STATUSES;
  readonly deleteError?: ErrorStoreType;
}

const initialState: UsersState = {
  inviteUsersStatus: LOADING_STATUSES.NOT_LOADING,
  inviteUsersError: null,
  uploadBulkStatus: LOADING_STATUSES.NOT_LOADING,
  uploadBulkError: null,
  activeProcess: false,
  activeProcessLoadingStatus: LOADING_STATUSES.NOT_LOADING,
  activeProcessLoadingError: null,

  // metrics data
  userActivityMetrics: {
    error: null,
    userActivityMetricsLoadingStatus: LOADING_STATUSES.NOT_LOADING,
    activityPerMonth: null,
    differenceRespectToPreviousMonth: null,
  },
  // end metrics data

  users: {
    items: [],
    total: 0,
    loading: LOADING_STATUSES.NOT_LOADING,
    error: null,
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUploadBulkStatus(state) {
      state.uploadBulkStatus = LOADING_STATUSES.NOT_LOADING;
      state.uploadBulkError = null;
    },
    resetInviteUsersStatus(state) {
      state.inviteUsersStatus = LOADING_STATUSES.NOT_LOADING;
      state.inviteUsersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerUsers.pending, (state) => {
        state.users.loading = LOADING_STATUSES.LOADING;
      })
      .addCase(
        getPartnerUsers.fulfilled,
        (state, { payload: { users, total } }) => {
          state.users.loading = LOADING_STATUSES.FULFILLED;
          state.users.items = users;
          state.users.total = total;
        },
      )
      .addCase(getPartnerUsers.rejected, (state, { payload }) => {
        state.users.loading = LOADING_STATUSES.FAILED;
        state.users.error = payload ?? null;
      })
      .addCase(uploadUsersInBulk.pending, (state) => {
        state.uploadBulkStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(uploadUsersInBulk.fulfilled, (state) => {
        state.uploadBulkStatus = LOADING_STATUSES.FULFILLED;
        state.activeProcess = true;
      })
      .addCase(uploadUsersInBulk.rejected, (state, { payload }) => {
        state.uploadBulkStatus = LOADING_STATUSES.FAILED;
        state.uploadBulkError = payload ?? null;
        state.activeProcess = false;
      })
      .addCase(inviteUsers.pending, (state) => {
        state.inviteUsersStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(inviteUsers.fulfilled, (state) => {
        state.inviteUsersStatus = LOADING_STATUSES.FULFILLED;
        state.activeProcess = true;
      })
      .addCase(inviteUsers.rejected, (state, { payload }) => {
        state.inviteUsersStatus = LOADING_STATUSES.FAILED;
        state.inviteUsersError = payload ?? null;
        state.activeProcess = false;
      })
      .addCase(getActiveProcess.pending, (state) => {
        state.activeProcessLoadingError = null;
        state.activeProcessLoadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(getActiveProcess.fulfilled, (state, { payload }) => {
        state.activeProcess = payload;
        state.activeProcessLoadingStatus = LOADING_STATUSES.FULFILLED;
      })
      .addCase(getActiveProcess.rejected, (state, { payload }) => {
        state.activeProcessLoadingStatus = LOADING_STATUSES.FAILED;
        state.activeProcessLoadingError = payload ?? null;
      })
      .addCase(getUsersActivityMetrics.pending, (state) => {
        state.userActivityMetrics.userActivityMetricsLoadingStatus =
          LOADING_STATUSES.LOADING;
        state.userActivityMetrics.error = null;
      })
      .addCase(getUsersActivityMetrics.fulfilled, (state, { payload }) => {
        state.userActivityMetrics.userActivityMetricsLoadingStatus =
          LOADING_STATUSES.FULFILLED;
        state.userActivityMetrics.activityPerMonth = payload.activityPerMonth;
        state.userActivityMetrics.differenceRespectToPreviousMonth =
          payload.differenceRespectToPreviousMonth;
      })
      .addCase(getUsersActivityMetrics.rejected, (state, { payload }) => {
        state.userActivityMetrics.userActivityMetricsLoadingStatus =
          LOADING_STATUSES.FAILED;
        state.userActivityMetrics.activityPerMonth = null;
        state.userActivityMetrics.differenceRespectToPreviousMonth = null;
        state.userActivityMetrics.error = payload ?? null;
      })
      .addCase(
        deactivateUser.pending,
        (
          state,
          {
            meta: {
              arg: { userId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatus = LOADING_STATUSES.LOADING;
              modificableUser.writeError = null;
            }

            return modificableUser;
          });
        },
      )
      .addCase(
        deactivateUser.fulfilled,
        (
          state,
          {
            meta: {
              arg: { userId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.active = false;
              modificableUser.writeStatus = LOADING_STATUSES.FULFILLED;
              modificableUser.accountStatus = 'DEACTIVATED';
            }

            return modificableUser;
          });
        },
      )
      .addCase(
        deactivateUser.rejected,
        (
          state,
          {
            payload,
            meta: {
              arg: { userId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatus = LOADING_STATUSES.FAILED;
              modificableUser.writeError = payload ?? null;
            }

            return modificableUser;
          });

          notification.error({ message: payload?.message });
        },
      )
      .addCase(
        reactivateUser.pending,
        (
          state,
          {
            meta: {
              arg: { userId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatus = LOADING_STATUSES.LOADING;
              modificableUser.writeError = null;
            }

            return modificableUser;
          });
        },
      )
      .addCase(
        reactivateUser.fulfilled,
        (
          state,
          {
            meta: {
              arg: { userId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.active = true;
              modificableUser.writeStatus = LOADING_STATUSES.FULFILLED;
              modificableUser.accountStatus = 'ACTIVE';
            }

            return modificableUser;
          });
        },
      )
      .addCase(
        reactivateUser.rejected,
        (
          state,
          {
            payload,
            meta: {
              arg: { userId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatus = LOADING_STATUSES.FAILED;
              modificableUser.writeError = payload ?? null;
            }

            return modificableUser;
          });

          notification.error({ message: payload?.message });
        },
      )
      .addCase(
        sendAccountReminderEmail.pending,
        (state, { meta: { arg: userId } }) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatus = LOADING_STATUSES.LOADING;
              modificableUser.writeError = null;
            }

            return modificableUser;
          });
        },
      )
      .addCase(
        sendAccountReminderEmail.fulfilled,
        (state, { meta: { arg: userId } }) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatus = LOADING_STATUSES.FULFILLED;
            }

            return modificableUser;
          });

          notification.success({ message: 'Email re-sent' });
        },
      )
      .addCase(
        sendAccountReminderEmail.rejected,
        (state, { payload, meta: { arg: userId } }) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatus = LOADING_STATUSES.FAILED;
              modificableUser.writeError = payload ?? null;
            }

            return modificableUser;
          });

          notification.error({ message: payload?.message });
        },
      )
      .addCase(
        changeUserPlan.pending,
        (
          state,
          {
            meta: {
              arg: { userId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatusPlan = LOADING_STATUSES.LOADING;
              modificableUser.writeErrorPlan = null;
            }

            return modificableUser;
          });
        },
      )
      .addCase(
        changeUserPlan.fulfilled,
        (
          state,
          {
            meta: {
              arg: { userId, planId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatusPlan = LOADING_STATUSES.FULFILLED;
              modificableUser.planId = planId;
            }

            return modificableUser;
          });

          notification.success({ message: "User's plan changed" });
        },
      )
      .addCase(
        changeUserPlan.rejected,
        (
          state,
          {
            payload,
            meta: {
              arg: { userId },
            },
          },
        ) => {
          state.users.items = state.users.items.map((user) => {
            const modificableUser = user;

            if (user.id === userId) {
              modificableUser.writeStatusPlan = LOADING_STATUSES.FAILED;
              modificableUser.writeErrorPlan = payload ?? null;
            }

            return modificableUser;
          });

          notification.error({ message: payload?.message });
        },
      )
      .addCase(deleteUsers.pending, (state) => {
        state.deleteError = null;
        state.deleteStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(deleteUsers.fulfilled, (state) => {
        state.deleteStatus = LOADING_STATUSES.FULFILLED;
        notification.success({ message: 'Users deleted' });
      })
      .addCase(deleteUsers.rejected, (state, { payload }) => {
        state.deleteError = payload;
        state.deleteStatus = LOADING_STATUSES.FAILED;

        notification.error({ message: payload?.message });
      });
  },
});

export const { resetUploadBulkStatus, resetInviteUsersStatus } =
  usersSlice.actions;

export default usersSlice.reducer;
