import { shallowEqual, useSelector } from 'react-redux';

import type RootState from '../types/RootState';
import type { Plan } from '../slices/plans';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

const isLoadingPlans = (state: RootState): boolean => {
  return state.plans.loadingStatus === LOADING_STATUSES.LOADING;
};

const plansSelector = (state: RootState): Plan[] => state.plans.plans;

const planByIdSelector = (planId: string) => (state: RootState) =>
  plansSelector(state).find((plan) => `${plan.id}` === `${planId}`);

export const useIsLoadingPlans = () => useSelector(isLoadingPlans);

export const usePlans = () => useSelector(plansSelector, shallowEqual);

export const usePlanById = (planId: string) =>
  useSelector(planByIdSelector(planId), shallowEqual);
