import { Layout } from 'antd';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';

import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import useOktaAuthCustom from '../../../hooks/useOktaAuthCustom';
import {
  useContract,
  useIsLoadingContract,
} from '../../../store/selectors/contracts';
import {
  useIsLoadingPartnerAdminFinished,
  usePartnerAdmin,
} from '../../../store/selectors/partnerAdmins';
import {
  useIsLoadingPartnerFinished,
  usePartner,
} from '../../../store/selectors/partners';
import { getContractByPartner } from '../../../store/thunks/contracts';
import {
  getPartnerAdmin,
  updateUserDidLogin,
} from '../../../store/thunks/partnerAdmins';
import { getPartner } from '../../../store/thunks/partners';
import FirstLoginModal from '../../../components/Modals/FirstLoginModal';
import SelectPartnerModal from '../../../components/Modals/SelectPartnerModal';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const contract = useContract();
  const admin = usePartnerAdmin();
  const partner = usePartner();
  const isContractLoading = useIsLoadingContract();
  const isAdminLoadingFinished = useIsLoadingPartnerAdminFinished();
  const isPartnerLoadingFinished = useIsLoadingPartnerFinished();
  const authState = useOktaAuthCustom();

  const [isVisibleFirstLoginModal, setIsVisibleFirstLoginModal] =
    useState(false);

  const [isVisibleSelectPartnerModal, setIsVisibleSelectPartnerModal] =
    useState(false);

  const [emulatedPartner, setEmulatedPartner] = useState<number | null>(null);

  const hideWelcomeModalHandler = () => {
    if (admin?.id) {
      setIsVisibleFirstLoginModal(false);
      dispatch(updateUserDidLogin(`${admin.id}`));
    }
  };

  useEffect(() => {
    const partnerId = authState?.user?.partnerId;
    const isSuperAdmin = authState?.user?.isSuperAdmin;

    if (!partner && !isPartnerLoadingFinished && partnerId && !isSuperAdmin) {
      dispatch(getPartner(partnerId));
    } else if (
      !partner &&
      !isPartnerLoadingFinished &&
      isSuperAdmin &&
      emulatedPartner
    ) {
      dispatch(getPartner(emulatedPartner));
    }
  }, [partner, dispatch, isPartnerLoadingFinished, authState, emulatedPartner]);

  useEffect(() => {
    const oktaUid = authState?.user?.sub;
    if (!admin && !isAdminLoadingFinished && oktaUid) {
      dispatch(getPartnerAdmin(oktaUid));
    }
  }, [admin, dispatch, isAdminLoadingFinished, authState]);

  useEffect(() => {
    if (!contract && !isContractLoading && partner?.id) {
      dispatch(getContractByPartner(partner.id));
    }
  }, [contract, dispatch, isContractLoading, partner]);

  useEffect(() => {
    if (admin?.userDidFirstLogin === false) {
      setIsVisibleFirstLoginModal(true);
    }
  }, [admin]);

  useEffect(() => {
    if (authState.user?.isSuperAdmin) {
      setIsVisibleSelectPartnerModal(true);
    }
  }, [authState]);

  return (
    <>
      <StyledLayout>
        <Header />
        <Layout>
          <Sidebar />
          <Layout>
            <StyledContent>
              <Outlet />
            </StyledContent>
            <Footer />
          </Layout>
        </Layout>
      </StyledLayout>
      <FirstLoginModal
        isVisible={isVisibleFirstLoginModal}
        hideHandler={hideWelcomeModalHandler}
      />
      <SelectPartnerModal
        isVisible={isVisibleSelectPartnerModal}
        handleSelect={(partnerId) => {
          setEmulatedPartner(partnerId);
          setIsVisibleSelectPartnerModal(false);
        }}
      />
    </>
  );
};

const StyledContent = styled(Layout.Content)`
  padding: 24px;
  overflow-y: scroll;
`;

const StyledLayout = styled(Layout)`
  height: 100vh;
`;

export default Dashboard;
