import type EndpointsType from '@/types/EndpointsType';

const PartnerManagerEndpoints = Object.freeze<EndpointsType>({
  getPartnerManagerByPartnerId: {
    method: 'GET',
    endpoint: '/partner/:partnerId/manager',
  },
});

export default PartnerManagerEndpoints;
