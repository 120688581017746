import http from '../http';
import UserEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';

const SendAccountReminderEmail = async (userId: string) => {
  const { data: pureResponse } = await http.request({
    method: UserEndpoints.sendAccountReminderEmail.method,
    url: UserEndpoints.sendAccountReminderEmail.endpoint.replace(
      ':userId',
      userId,
    ),
  });

  return ResponseParser<void>(pureResponse);
};

export default SendAccountReminderEmail;
