import { shallowEqual, useSelector } from 'react-redux';

import type RootState from '../types/RootState';
import type { PlanDetail } from '../slices/planDetails';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

const isLoadingPlanDetails = (state: RootState): boolean => {
  return state.planDetails.loadingStatus === LOADING_STATUSES.LOADING;
};

const planDetailsSelector = (state: RootState): PlanDetail[] =>
  state.planDetails.planDetails;

export const useIsLoadingPlanDetails = () => useSelector(isLoadingPlanDetails);

export const usePlanDetails = () =>
  useSelector(planDetailsSelector, shallowEqual);
