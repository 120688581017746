import http from '../http';
import { parse } from './Parser';
import type { DBPartner } from './Parser';
import PartnerEndpoints from './Endpoints';
import type { Partner } from '../../store/slices/partners';
import { ResponseParser } from '../http/ResponseParser';

const UpdatePartner = async (data: Partner) => {
  const { data: pureData } = await http.request({
    method: PartnerEndpoints.updatePartner.method,
    url: PartnerEndpoints.updatePartner.endpoint,
    data,
  });

  return ResponseParser<DBPartner, Partner>(pureData, parse);
};

export default UpdatePartner;
