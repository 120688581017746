import type { PlanDetail } from '../../store/slices/planDetails';

export interface DBPlanDetails {
  PLAN_ID: number;
  CONTRACT_ID: number;
  ALLOCATED_ACCT: number;
  USED_ACCT: number;
  AVAIL_ACCT: number;
  PLAN_DISCOUNT_APPLIED: number;
  ACTIVE_FLAG: boolean;
  CREATED_DT: string;
  UPDATED_DT: string;
}

const PlanDetailParser = {
  parse: (dbPlanDetail: DBPlanDetails): PlanDetail => ({
    planId: dbPlanDetail.PLAN_ID,
    contractId: dbPlanDetail.CONTRACT_ID,
    seats: {
      allocated: dbPlanDetail.ALLOCATED_ACCT,
      used: dbPlanDetail.USED_ACCT,
      available: dbPlanDetail.AVAIL_ACCT,
    },
    discountApplied: dbPlanDetail.PLAN_DISCOUNT_APPLIED,
    active: dbPlanDetail.ACTIVE_FLAG,
  }),
  multiParse: (dbPlanDetails: DBPlanDetails[]): PlanDetail[] =>
    dbPlanDetails.map(PlanDetailParser.parse),
};

export const { parse, multiParse } = PlanDetailParser;
export default PlanDetailParser;
