import { Col, Row, Spin, Tabs } from 'antd';
import styled from 'styled-components';

import Content from '../Dashboard/Layout/Content';
import { Tile } from '../../components/common/tiles';
import Header from '../../components/Settings/Header';
import LogoImage from '../../components/Settings/LogoImage';
import { useIsLoadingPartner } from '../../store/selectors/partners';
import WorkersComputer from '../../assets/images/WorkersComputer.png';
import ChangePassword from '../../components/Settings/ChangePassword';
import AdminInformation from '../../components/Settings/AdminInformation';
import { useIsLoadingPartnerAdmin } from '../../store/selectors/partnerAdmins';

const { TabPane } = Tabs;

const Settings = () => {
  const isPartnerLoading = useIsLoadingPartner();
  const isAdminLoading = useIsLoadingPartnerAdmin();
  const isLoading = isPartnerLoading || isAdminLoading;

  return (
    <Content title="Settings">
      <Tile
        $height={800}
        component={
          <div className="settings-container">
            <Header />
            <Tabs tabPosition="left">
              <TabPane tab="Admin Information" key="1">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                    {isLoading ? <Spin /> : <AdminInformation />}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Password" key="2">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                    {isLoading ? <Spin /> : <ChangePassword />}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Logo image" key="3">
                <Row>
                  <Col span={24}>{isLoading ? <Spin /> : <LogoImage />}</Col>
                </Row>
              </TabPane>
            </Tabs>
            <StyledImage src={WorkersComputer} />
          </div>
        }
      />
    </Content>
  );
};

// Used img directly instead of Image from Antd because Image apply its className directly to the image instead its container
const StyledImage = styled.img`
  width: 400px;
  position: absolute;
  bottom: 50px;
  right: 50px;

  @media (max-width: 1300px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 200px;
  }
  @media (max-width: 992px) {
    width: 0px;
  }
`;

export default Settings;
