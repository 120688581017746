import { useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useOktaAuth } from '@okta/okta-react';

import Container from './Container';
import COLORS from '../../../../styles/Colors';
import PowerOff from '../../../../assets/icons/PowerOff';
import Divider from '../../../../components/common/Divider/Divider';
import type { CustomAuthState } from '../../../../config/OktaInstance';
import TMAnchor from '../../../../components/common/typography/TMAnchor';
import { usePartnerManager } from '../../../../store/selectors/partners';
import SimpleProfileCard from '../../../../components/common/profile/SimpleProfileCard';

const BottomOptions = () => {
  const { oktaAuth, authState: oktaState } = useOktaAuth();
  const [authState] = useState<CustomAuthState>(oktaState as CustomAuthState);

  const manager = usePartnerManager();

  const onLogout = () =>
    oktaAuth.signOut({ accessToken: authState?.accessToken });

  return (
    <StyledContainer>
      <StyledButton type="text" icon={<PowerOff />} onClick={onLogout}>
        Logout
      </StyledButton>
      <Divider />
      <SimpleProfileCard
        name={
          manager
            ? `${manager.firstName} ${manager.lastName}`
            : 'Relationship Manager'
        }
        subtitle="Relationship Manager"
      />

      <AnchorContainer>
        {manager?.email && <TMAnchor mailTo={manager.email}>Email</TMAnchor>}
        {manager?.telegram && (
          <TMAnchor href={manager.telegram} blank>
            Telegram
          </TMAnchor>
        )}
      </AnchorContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  margin-bottom: 15px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0 20px;
  color: ${COLORS.TEXT_GRAY};
  padding-left: 22px;
`;

const AnchorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 50px;
  margin-top: 20px;
`;

export default BottomOptions;
