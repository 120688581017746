import { useOktaAuth } from '@okta/okta-react';

import type { CustomAuthState } from '../config/OktaInstance';

const useOktaAuthCustom = () => {
  const { authState } = useOktaAuth();

  return authState as CustomAuthState;
};

export default useOktaAuthCustom;
