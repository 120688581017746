import { shallowEqual, useSelector } from 'react-redux';

import type RootState from '../types/RootState';
import type { PartnerAdmin } from '../slices/partnerAdmins';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

const isLoadingPartnerAdmin = (state: RootState): boolean => {
  return state.partnerAdmins.loadingStatus === LOADING_STATUSES.LOADING;
};

const isLoadingPartnerAdminFinished = (state: RootState): boolean =>
  [LOADING_STATUSES.FULFILLED, LOADING_STATUSES.FAILED].includes(
    state.partnerAdmins.loadingStatus,
  );

const partnerAdminSelector = (state: RootState): PartnerAdmin | null =>
  state.partnerAdmins.partnerAdmin;

const isLoadingChangePassword = (state: RootState): boolean => {
  return state.partnerAdmins.loadingChangePassword === LOADING_STATUSES.LOADING;
};

const isChangePasswordSuccess = (state: RootState): boolean => {
  return (
    state.partnerAdmins.loadingChangePassword === LOADING_STATUSES.FULFILLED &&
    state.partnerAdmins.changePasswordError === null
  );
};

export const useIsLoadingPartnerAdmin = () =>
  useSelector(isLoadingPartnerAdmin);

export const useIsLoadingPartnerAdminFinished = () =>
  useSelector(isLoadingPartnerAdminFinished);

export const usePartnerAdmin = () =>
  useSelector(partnerAdminSelector, shallowEqual);

export const useIsLoadingChangePassword = () =>
  useSelector(isLoadingChangePassword);

export const useIsChangePasswordSuccess = () =>
  useSelector(isChangePasswordSuccess);
