import type { User } from '../../store/slices/users';

export interface DBUsers {
  PP_USER_ID: string;
  UPLOAD_ID: string;
  USER_FNAME: string;
  USER_LNAME: string;
  USER_EMAIL: string;
  LAST_ACTIVITY: string;
  UPLOAD_STATUS: string;
  OKTA_UID: string;
  PRTNR_ID: string;
  ACTIVE_FLAG: boolean;
  PLAN_KEY: string;
  PLAN_ID: number;
  ACCOUNT_STATUS: 'ACTIVE' | 'DEACTIVATED' | 'PENDING';
}

const UserParser = {
  parse: (dbUser: DBUsers): User => ({
    id: dbUser.PP_USER_ID,
    firstName: dbUser.USER_FNAME,
    lastName: dbUser.USER_LNAME,
    fullName: `${dbUser.USER_FNAME} ${dbUser.USER_LNAME}`,
    uploadId: dbUser.UPLOAD_ID,
    email: dbUser.USER_EMAIL,
    lastActivity: dbUser.LAST_ACTIVITY,
    uploadStatus: dbUser.UPLOAD_STATUS,
    oktaUid: dbUser.OKTA_UID,
    partnerId: dbUser.PRTNR_ID,
    active: dbUser.ACTIVE_FLAG,
    planId: `${dbUser.PLAN_ID}`,
    accountStatus: dbUser.ACCOUNT_STATUS,
  }),
  multiParse: (dbUsers: DBUsers[]): User[] => dbUsers.map(UserParser.parse),
};

export const { parse, multiParse } = UserParser;
export default UserParser;
