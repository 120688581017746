import { Alert, Col, Row } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { usePartner } from '../../store/selectors/partners';
import { useActiveProcess } from '../../store/selectors/users';
import { getActiveProcess } from '../../store/thunks/users';

const ProcessInfo = () => {
  const dispatch = useDispatch();
  const activeProcess = useActiveProcess();
  const partner = usePartner();

  useEffect(() => {
    if (partner) dispatch(getActiveProcess());
  }, [partner, dispatch]);

  if (!activeProcess) return null;

  return (
    <Row justify="center" style={{ marginBottom: 20 }}>
      <Col span={24}>
        <Alert
          message="A process is running right now, please wait..."
          type="info"
          showIcon
        />
      </Col>
    </Row>
  );
};

export default ProcessInfo;
