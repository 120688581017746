import http from '../http';
import { multiParse } from './Parser';
import type { DBUsers } from './Parser';
import UserEndpoints from './Endpoints';
import type { User } from '@/store/slices/users';
import { ResponseParserList } from '../http/ResponseParser';
import type { GetPartnerUsersParams } from '../../store/thunks/users';

interface Params
  extends Omit<
    Required<GetPartnerUsersParams>,
    'searchValue' | 'nameSorter' | 'lastActivitySorter'
  > {
  partnerId: number;
  searchValue: string | undefined;
  nameSorter: GetPartnerUsersParams['nameSorter'];
  lastActivitySorter: GetPartnerUsersParams['lastActivitySorter'];
}

const GetPartnerUsers = async ({
  currentPage,
  pageSize,
  partnerId,
  planFilter,
  accountStatusFilter,
  nameSorter,
  searchValue,
  lastActivitySorter,
}: Params) => {
  const { data: pureResponse } = await http.request({
    method: UserEndpoints.getPartnerUsers.method,
    url: UserEndpoints.getPartnerUsers.endpoint,
    params: {
      page: currentPage,
      limit: pageSize,
      partnerId,
      filter: searchValue,
      plan: planFilter,
      accountStatus: accountStatusFilter,
      nameSorter,
      lastActivitySorter,
    },
  });

  return ResponseParserList<DBUsers, User>(pureResponse, multiParse);
};

export default GetPartnerUsers;
