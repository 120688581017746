import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Image } from 'antd';

import COLORS from '../../../styles/Colors';
import FlexContainer from '../../common/flex/FlexContainer';
import { useActivityPerMonth } from '../../../store/selectors/users';
import NoDataAvailable from '../../../assets/images/no_data_available.png';

const ActiveUsersChart = () => {
  const activityPerMonth = useActivityPerMonth();

  const sortAndParseActivities = () => {
    if (!activityPerMonth) return [];

    return Object.entries(activityPerMonth)
      .sort(([dateA], [dateB]) => {
        const [monthA, yearA] = dateA.split('-');
        const [monthB, yearB] = dateB.split('-');

        const parsedDateA = new Date(`01-${monthA}-${yearA}`);
        const parsedDateB = new Date(`01-${monthB}-${yearB}`);

        return parsedDateA.getTime() - parsedDateB.getTime();
      })
      .map(([date, value]) => {
        const [month, year] = date.split('-');
        return { month: `${month} ${year}`, users: value };
      });
  };

  return sortAndParseActivities().length > 0 ? (
    <ResponsiveContainer height={210} width="100%">
      <LineChart data={sortAndParseActivities()}>
        <Line
          type="monotone"
          name="Users"
          dataKey="users"
          stroke={COLORS.SUN_GLOW}
          strokeWidth={2}
        />
        <CartesianGrid stroke={COLORS.ATHENS_GRAY} horizontal={false} />
        <XAxis dataKey="month" />
        <YAxis tickCount={4} axisLine={false} />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  ) : (
    <FlexContainer $justify="center" $align="center" $totalHeight>
      <Image preview={false} src={NoDataAvailable} />
    </FlexContainer>
  );
};

export default ActiveUsersChart;
