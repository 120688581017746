import { createAsyncThunk } from '@reduxjs/toolkit';

import LoadPlans from '../../services/plans/LoadPlans';

export const loadPlans = createAsyncThunk(
  'plans/loadPlans',
  async (_, { rejectWithValue }) => {
    const {
      parsedData: plans,
      message,
      validationErrors,
      success,
    } = await LoadPlans();

    if (success && plans) {
      return plans;
    }

    throw rejectWithValue({
      message,
      validationErrors,
    });
  },
);
