import type { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';

import { usePaymentMethod } from '../../../../../../store/selectors/partners';
import COLORS from '../../../../../../styles/Colors';
import { TMText } from '../../../../../common/typography';

const SEPABankInformation: FC = () => {
  const paymentMethod = usePaymentMethod();

  return (
    <Container>
      <RowContainer>
        <Col span={10}>
          <TMText>Name on Account:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.sepa.name}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Email:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.sepa.email}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Account Number:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.sepa.country}XXXXXXXXXXXXXX
            {paymentMethod?.sepa.last4}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Bank code:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.sepa.bankCode}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Address line 1:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.line1 || ''}
          </TMText>
        </Col>
      </RowContainer>

      <RowContainer>
        <Col span={10}>
          <TMText>Country:</TMText>
        </Col>
        <Col span={14}>
          <TMText $bold $color={COLORS.TITLE_BLACK}>
            {paymentMethod?.sepa.country}
          </TMText>
        </Col>
      </RowContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
`;

const RowContainer = styled(Row)`
  margin-bottom: 20px;
`;

export default SEPABankInformation;
