import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import Content from '../Dashboard/Layout/Content';
import MyPlan from '../../components/Summary/MyPlan';
import TMNews from '../../components/Summary/TMNews';
import Visits from '../../components/Summary/Visits';
import LearnMore from '../../components/Summary/LearnMore';
import SeatDescription from '../../components/Summary/Seats';
import PlanContract from '../../components/Summary/PlanContract';
import { Tile, NoBorderedTile } from '../../components/common/tiles';
import DashboardTour from '../../components/common/cards/DashboardTour';
import BillingHistory from '../../components/common/cards/BillingHistory';
import ActiveUserChartTile from '../../components/Summary/ActiveUsersChart';

const Summary: React.FC = () => {
  return (
    <Content title="Dashboard">
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <MyPlan />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <TMNews />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Visits />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <PlanContract />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <ActiveUserChartTile />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Tile $height={350} title="Seats" component={<SeatDescription />} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <BillingHistory />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
          <StyledNoBorderedTile component={<LearnMore />} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <DashboardTour />
        </Col>
      </Row>
    </Content>
  );
};

const StyledNoBorderedTile = styled(NoBorderedTile)`
  padding: 10px 0;
`;

export default Summary;
