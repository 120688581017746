import type { SetupIntent } from '@stripe/stripe-js';
import http from '../http';
import PartnerEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';

const CreateSetupIntent = async (partnerId: number) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.createSetupIntent.method,
    url: PartnerEndpoints.createSetupIntent.endpoint.replace(
      ':partnerId',
      `${partnerId}`,
    ),
  });

  return ResponseParser<SetupIntent>(pureResponse);
};

export default CreateSetupIntent;
