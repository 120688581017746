import type { VFC } from 'react';
import { Menu, Layout } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import TopOptions from './TopOptions';
import SidebarItems from './SidebarItems';
import BottomOptions from './BottomOptions';
import COLORS from '../../../../styles/Colors';
import RouteNames from '../../../../routes/routeNames';
import useOktaAuthCustom from '../../../../hooks/useOktaAuthCustom';

const Sidebar: VFC = () => {
  const authState = useOktaAuthCustom();
  const [selectedOption, setSelectedOption] = useState(SidebarItems[0].label);

  useEffect(() => {
    const path = window.location.pathname;
    if (path !== '/') {
      const itemSelected = SidebarItems.find((item) => {
        if (
          item.path === RouteNames.UserManagement &&
          path === RouteNames.HowToUploadUsers
        ) {
          return true;
        }
        return item.path === path;
      });

      setSelectedOption(itemSelected?.label || SidebarItems[0].label);
    }
  }, []);

  return (
    <StyledSidebar width={250}>
      <div>
        <TopOptions />
        <Menu
          mode="inline"
          selectedKeys={[selectedOption]}
          onClick={({ key }) => setSelectedOption(key)}
        >
          {SidebarItems.map((item) => (
            <StyledMenuItem
              key={item.label}
              hidden={
                item.path === RouteNames.Settings &&
                authState.user?.isSuperAdmin
              }
            >
              <item.icon
                color={
                  selectedOption === item.label ? COLORS.TITLE_BLACK : undefined
                }
              />
              <Link to={item.path}>{item.label}</Link>
            </StyledMenuItem>
          ))}
        </Menu>
      </div>
      <BottomOptions />
    </StyledSidebar>
  );
};

const StyledMenuItem = styled(Menu.Item)`
  margin: 20px 0 !important;
`;

const StyledSidebar = styled(Layout.Sider)`
  background: ${COLORS.SELAGO} !important;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ul {
    background: ${COLORS.SELAGO} !important;
  }

  li {
    background: none !important;
    overflow: visible !important;

    &::after {
      border-right-color: ${COLORS.SUN_GLOW} !important;
    }

    a {
      color: ${COLORS.TITLE_BLACK} !important;
    }

    span {
      padding-left: 20px !important;
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 20px;
    }

    &.ant-menu-item-selected span {
      border-radius: 8px;
      box-shadow: #1f64ff15 1px 4px 10px;
      background: ${COLORS.WHITE};
      font-weight: bold;
    }
  }
`;

export default Sidebar;
