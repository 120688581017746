import http from '../http';
import UserEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';

const DeactivateUser = async (userId: string) => {
  const { data: pureResponse } = await http.request({
    method: UserEndpoints.deactivateUser.method,
    url: UserEndpoints.deactivateUser.endpoint.replace(':userId', userId),
  });

  return ResponseParser<void>(pureResponse);
};

export default DeactivateUser;
