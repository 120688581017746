import type { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import type { TextProps } from 'antd/lib/typography/Text';
import COLORS from '../../../styles/Colors';

const { Text } = Typography;

const TMSubtitle: FC<TextProps> = ({ children, ...props }) => {
  return <StyledText {...props}>{children}</StyledText>;
};

const StyledText = styled(Text)`
  color: ${COLORS.SUBTITLE_BLACK} !important;
`;

export default TMSubtitle;
