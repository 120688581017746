import { Col, Row, Skeleton } from 'antd';
import styled from 'styled-components';
import type { ReactElement, FC } from 'react';

import type COLORS from '../../styles/Colors';
import CircleIconContainer from '../common/CircleIconContainer';
import { TMParagraph, TMSubtitle, TMTitle } from '../common/typography';
import Tile from '../common/tiles/Tile';

interface Props {
  title: string;
  subtitle?: string;
  children?: string | ReactElement;
  loading?: boolean;
  icon: ReactElement;
  containerIconColor: COLORS;
  marginTopContent?: number;
}

const BaseWidget: FC<Props> = ({
  icon,
  containerIconColor,
  title,
  subtitle,
  children,
  marginTopContent,
  loading,
}) => {
  const renderContent = () => {
    if (!children) return null;

    if (typeof children === 'string') {
      return <TMParagraph>{children}</TMParagraph>;
    }

    return children;
  };

  return (
    <Tile
      $paddingX={0}
      component={
        <Row>
          <Col span={6}>
            <CircleIconContainer color={containerIconColor}>
              {icon}
            </CircleIconContainer>
          </Col>
          <Col span={17} offset={1}>
            <Skeleton active paragraph={{ rows: 2 }} loading={loading}>
              <TMTitle level={4}>{title}</TMTitle>
              {subtitle && <TMSubtitle strong>{subtitle}</TMSubtitle>}
              {children && (
                <StyledContentContainer marginTopContent={marginTopContent}>
                  {renderContent()}
                </StyledContentContainer>
              )}
            </Skeleton>
          </Col>
        </Row>
      }
    />
  );
};

const StyledContentContainer = styled.div<Pick<Props, 'marginTopContent'>>(
  (props) => ({
    marginTop: props.marginTopContent || 5,
  }),
);

export default BaseWidget;
