import type { VFC } from 'react';

import { capitalize } from '../../../utils/capitalize';
import { useIsLoadingPlans, usePlanById } from '../../../store/selectors/plans';

interface Props {
  planId: string;
}

const PlanNameColumn: VFC<Props> = ({ planId }) => {
  const plan = usePlanById(planId);
  const isLoading = useIsLoadingPlans();

  return (
    <span>
      {!isLoading && plan?.name ? capitalize(plan.name) : 'Loading plan...'}
    </span>
  );
};

export default PlanNameColumn;
