import BaseWidget from './BaseWidget';
import Document from '../../assets/icons/Document';
import COLORS from '../../styles/Colors';
import { TMAnchor, TMSubtitle } from '../common/typography';
import {
  useContract,
  useIsLoadingContract,
} from '../../store/selectors/contracts';

const PlanContract = () => {
  const contract = useContract();
  const isLoading = useIsLoadingContract();

  return (
    <BaseWidget
      title="Plan Contract"
      icon={<Document color={COLORS.YELLOW_ORANGE} />}
      containerIconColor={COLORS.ALPHA_YELLOW_ORANGE}
      loading={isLoading}
    >
      <TMSubtitle strong>
        See details of your Plan{' '}
        <TMAnchor
          href={contract?.contractUrl || 'https://tokenmetrics.com'}
          blank
          $color={COLORS.CERULEAN_BLUE}
        >
          Here
        </TMAnchor>
      </TMSubtitle>
    </BaseWidget>
  );
};

export default PlanContract;
