import { Space } from 'antd';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';

import BaseWidget from './BaseWidget';
import COLORS from '../../styles/Colors';
import ChartBars from '../../assets/icons/ChartBars';
import TrendingUp from '../../assets/icons/TrendingUp';
import TrendingDown from '../../assets/icons/TrendingDown';
import { TMParagraph, TMText } from '../common/typography';
import { getUsersActivityMetrics } from '../../store/thunks/users';
import {
  useCurrentMonthActivity,
  useIsUserActivityMetricsLoading,
  useIsUserActivityMetricsLoadingFinished,
  useUserActivityMetricsPercentageDifference,
} from '../../store/selectors/users';

const Visits: React.FC = () => {
  const dispatch = useDispatch();

  const previousMothDifference = useUserActivityMetricsPercentageDifference();
  const loadingMetricsIsFinished = useIsUserActivityMetricsLoadingFinished();
  const areMetricsLoading = useIsUserActivityMetricsLoading();
  const currentMonthActivity = useCurrentMonthActivity();

  useEffect(() => {
    if (!loadingMetricsIsFinished) {
      dispatch(getUsersActivityMetrics());
    }
  }, [loadingMetricsIsFinished, dispatch]);

  const renderTrending = () => {
    if (!previousMothDifference) return 'No previous data';

    if (Math.sign(previousMothDifference) === 1) {
      return (
        <>
          <TrendingUp color={COLORS.SUCCESS} />
          <TMText $color={COLORS.SUCCESS}>
            {previousMothDifference.toFixed(2)}%
          </TMText>
        </>
      );
    }

    return (
      <>
        <TrendingDown color={COLORS.DANGER} />
        <TMText $color={COLORS.DANGER}>
          {previousMothDifference.toFixed(2)}%
        </TMText>
      </>
    );
  };

  return (
    <BaseWidget
      title={
        currentMonthActivity?.toLocaleString('en-US').replace(',', ' ') ?? '0'
      }
      icon={<ChartBars color={COLORS.CERULEAN_BLUE} />}
      containerIconColor={COLORS.ALPHA_CERULEAN_BLUE}
      subtitle={`${format(new Date(), 'MMM')} Total Visits`}
      loading={areMetricsLoading}
    >
      <TMParagraph>
        <Space direction="horizontal">
          {renderTrending()}
          <TMText>last month</TMText>
        </Space>
      </TMParagraph>
    </BaseWidget>
  );
};

export default Visits;
