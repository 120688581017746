import type EndpointsType from '../../types/EndpointsType';

const ContractEndpoints = Object.freeze<EndpointsType>({
  getContractByPartner: {
    endpoint: '/contracts',
    method: 'GET',
  },
});

export default ContractEndpoints;
