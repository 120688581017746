import type { RowProps } from 'antd';
import { Col, Row } from 'antd';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import type { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { TMText, TMTitle } from '../../../components/common/typography';
import COLORS from '../../../styles/Colors';

interface Props {
  title: string;
  children: ReactElement;
  goBack?: boolean;
  goBackLabel?: string;
  goBackTo?: string;
}

interface StyledRowProps extends RowProps {
  $marginYBottom: number;
}

const Content: FC<Props> = ({
  title,
  children,
  goBack,
  goBackTo,
  goBackLabel = 'Go back',
}) => {
  const navigate = useNavigate();

  const dynamicNavigate = (): void => {
    if (goBackTo) {
      navigate(goBackTo);
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <TitleContainer $marginYBottom={15}>
        <Col>
          <TMTitle>{title}</TMTitle>
          {(goBack || goBackTo) && (
            <TMText
              $color={COLORS.TITLE_BLACK}
              $marginYTop={20}
              $marginYBottom={0}
              $verticalAligned
              $cursor="pointer"
              onClick={dynamicNavigate}
            >
              <StyledArrow /> {goBackLabel}
            </TMText>
          )}
        </Col>
      </TitleContainer>
      <div>{children}</div>
    </div>
  );
};

const TitleContainer = styled(Row)<StyledRowProps>(
  ({ $marginYBottom = 35 }) => ({
    marginBottom: `${$marginYBottom}px !important`,
  }),
);

const StyledArrow = styled(BsArrowLeft)`
  margin-right: 10px;
`;

export default Content;
