import { Upload } from 'antd';
import type { VFC } from 'react';
import { GoInbox } from 'react-icons/go';
import styled from 'styled-components';

import COLORS from '../../../styles/Colors';
import { TMText, TMTitle } from '../typography';
import TextSizes from '../../../styles/TextSizes';

interface StyledProps {
  $height?: number;
  $marginTop?: number;
}

interface Props {
  onChange?: () => void;
  title?: string;
  subTitle?: string;
  accept?: string;
  maxCount?: number;
}

const InputFile: VFC<Props & StyledProps> = ({
  onChange,
  accept,
  maxCount = 1,
  title = 'Upload your file',
  subTitle = 'You can either look for the file by clicking here or drag and drop it here',
  ...styledProps
}) => {
  return (
    <StyledDragger
      onChange={onChange}
      maxCount={maxCount}
      accept={accept}
      beforeUpload={() => false}
      {...styledProps}
    >
      <GoInbox size={40} color={COLORS.GRAY} />
      <TMTitle level={5} $textAlign="center" $color={COLORS.TEXT_GRAY}>
        {title}
      </TMTitle>
      <TMText $size={TextSizes.SMALL} $color={COLORS.TEXT_GRAY}>
        {subTitle}
      </TMText>
    </StyledDragger>
  );
};

const StyledDragger = styled(Upload.Dragger)<StyledProps>(
  ({ $height = 150, $marginTop = 20 }) => ({
    height: `${$height}px !important`,
    marginTop: `${$marginTop}px !important`,
  }),
);

export default InputFile;
