export const isPasswordValid = (str: string) => {
  const regexSpecial = /^[A-Za-z0-9 ]+$/;
  const regexLower = /(?=.*[a-z])/;
  const regexUpper = /(?=.*[A-Z])/;
  if (
    !regexSpecial.test(str) &&
    regexLower.test(str) &&
    regexUpper.test(str) &&
    !(str.length < 10 || str.length > 20)
  ) {
    return true;
  }
  return false;
};
