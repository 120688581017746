import React from 'react';

import Rocket from '../../assets/icons/Rocket';
import COLORS from '../../styles/Colors';
import BaseWidget from './BaseWidget';
import {
  useIsLoadingContract,
  useContract,
} from '../../store/selectors/contracts';

const MyPlan: React.FC = () => {
  const isLoading = useIsLoadingContract();
  const contract = useContract();

  const subtitle = contract?.totalSeats
    ? `${contract?.totalSeats.toLocaleString('en-US').replace(',', ' ')} seats`
    : '0 seats';
  const expiration = contract?.expireDate
    ? new Date(contract.expireDate)
    : new Date();
  const expirationFormat = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'long',
    timeZone: 'UTC',
  }).format(expiration);

  return (
    <BaseWidget
      title="Partner Plan"
      icon={<Rocket color={COLORS.JUNGLE_GREEN} />}
      containerIconColor={COLORS.ALPHA_JUNGLE_GREEN}
      subtitle={subtitle}
      loading={isLoading}
    >
      <>Exp. Date {expirationFormat}</>
    </BaseWidget>
  );
};

export default MyPlan;
