import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const PowerOff: VFC<IconInterface> = ({ color = COLORS.GRAY }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1_7961" fill="white">
        <path d="M4.47123 7.90261C3.95336 7.65347 3.73028 7.02719 4.04166 6.54419C4.87868 5.24581 6.0627 4.19806 7.46854 3.52585C9.18877 2.7033 11.1382 2.49226 12.9946 2.92762C14.851 3.36297 16.5033 4.41865 17.6785 5.92022C18.8537 7.42178 19.4814 9.27936 19.458 11.186C19.4345 13.0926 18.7613 14.9342 17.5495 16.4064C16.3377 17.8786 14.6599 18.8933 12.7934 19.2829C10.9268 19.6724 8.98318 19.4135 7.28371 18.5488C5.89484 17.8422 4.73695 16.7657 3.93212 15.4471C3.63272 14.9566 3.87115 14.336 4.39498 14.0997C4.91882 13.8634 5.52835 14.1029 5.84764 14.5807C6.4436 15.4726 7.26111 16.2024 8.22737 16.694C9.50463 17.3438 10.9654 17.5384 12.3682 17.2457C13.771 16.9529 15.032 16.1903 15.9427 15.0838C16.8534 13.9774 17.3594 12.5933 17.3771 11.1604C17.3947 9.72744 16.9229 8.33137 16.0397 7.20285C15.1564 6.07434 13.9146 5.28093 12.5194 4.95374C11.1242 4.62654 9.65914 4.78515 8.36628 5.40334C7.38822 5.87102 6.553 6.58054 5.93528 7.45746C5.60433 7.92727 4.98909 8.15174 4.47123 7.90261Z" />
      </mask>
      <path
        d="M4.47123 7.90261C3.95336 7.65347 3.73028 7.02719 4.04166 6.54419C4.87868 5.24581 6.0627 4.19806 7.46854 3.52585C9.18877 2.7033 11.1382 2.49226 12.9946 2.92762C14.851 3.36297 16.5033 4.41865 17.6785 5.92022C18.8537 7.42178 19.4814 9.27936 19.458 11.186C19.4345 13.0926 18.7613 14.9342 17.5495 16.4064C16.3377 17.8786 14.6599 18.8933 12.7934 19.2829C10.9268 19.6724 8.98318 19.4135 7.28371 18.5488C5.89484 17.8422 4.73695 16.7657 3.93212 15.4471C3.63272 14.9566 3.87115 14.336 4.39498 14.0997C4.91882 13.8634 5.52835 14.1029 5.84764 14.5807C6.4436 15.4726 7.26111 16.2024 8.22737 16.694C9.50463 17.3438 10.9654 17.5384 12.3682 17.2457C13.771 16.9529 15.032 16.1903 15.9427 15.0838C16.8534 13.9774 17.3594 12.5933 17.3771 11.1604C17.3947 9.72744 16.9229 8.33137 16.0397 7.20285C15.1564 6.07434 13.9146 5.28093 12.5194 4.95374C11.1242 4.62654 9.65914 4.78515 8.36628 5.40334C7.38822 5.87102 6.553 6.58054 5.93528 7.45746C5.60433 7.92727 4.98909 8.15174 4.47123 7.90261Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        mask="url(#path-1-inside-1_1_7961)"
      />
      <rect x="1" y="10" width="9" height="2" rx="1" fill={color} />
    </svg>
  );
};

export default PowerOff;
