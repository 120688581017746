import type { VFC } from 'react';
import { Col, Image, Layout, Row } from 'antd';
import styled from 'styled-components';

// import RightOptions from './RightOptions';
import COLORS from '../../../../styles/Colors';
import logo from '../../../../assets/images/logo.png';

const Header: VFC = () => {
  return (
    <StyledHeader>
      <Row justify="space-between">
        <Col>
          <Image src={logo} alt="Tokenmetrics" preview={false} />
        </Col>
        {/* <Col>
          <RightOptions />
        </Col> */}
      </Row>
    </StyledHeader>
  );
};

const StyledHeader = styled(Layout.Header)`
  background-color: ${COLORS.WHITE};
  border-bottom: 1px solid ${COLORS.ATHENS_GRAY};
`;

export default Header;
