import styled from 'styled-components';

import Container from './Container';
import COLORS from '../../../../styles/Colors';
import SimpleProfileCard from '../../../../components/common/profile/SimpleProfileCard';
import Divider from '../../../../components/common/Divider/Divider';
import { usePartner } from '../../../../store/selectors/partners';

const TopOptions = () => {
  const partner = usePartner();

  return (
    <StyledContainer>
      <SimpleProfileCard
        name={partner?.name || 'Token Metrics'}
        avatar={partner?.logoUrl}
        subtitle="Partner Plan"
        titleLevel={4}
      />
      <Divider color={COLORS.SUN_GLOW} sideMargin={15} />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  margin-top: 15px;
`;

export default TopOptions;
