import type { VFC } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate, Outlet } from 'react-router-dom';
import LoadingPage from '../../components/LoadingPage';

const WhenAuthenticated: VFC = () => {
  const { authState } = useOktaAuth();

  if (authState === null) return <LoadingPage />;

  if (authState?.isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default WhenAuthenticated;
