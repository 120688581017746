import { Modal } from 'antd';
import type { VFC } from 'react';

import COLORS from '../../../styles/Colors';
import { TMAnchor, TMText, TMTitle } from '../../common/typography';
import type ModalProps from '../../../types/ModalProps';
import { BorderRoundedDivider } from '../../common/Divider';
import RocketStars from '../../../assets/icons/RocketStars';
import FlexContainer from '../../common/flex/FlexContainer';

const SuccessModal: VFC<ModalProps> = ({ isVisible, hideHandler }) => {
  return (
    <Modal visible={isVisible} onCancel={hideHandler} footer={null} centered>
      <TMTitle level={3} $textAlign="center">
        THANK YOU!
      </TMTitle>
      <BorderRoundedDivider $color={COLORS.TITLE_BLACK} $verticalMargin={5} />
      <TMTitle level={4} $color={COLORS.SUBTITLE_BLACK} $textAlign="center">
        Your file has been successfully uploaded.
      </TMTitle>
      <TMText
        $marginY={15}
        $horizontalAligned
        $textAlign="center"
        $color={COLORS.SUBTITLE_BLACK}
      >
        Please note that it can take up to 24 hours for new users to have access
        to Token Metrics and to appear in on your User Management Page
      </TMText>
      <FlexContainer $justify="center">
        <RocketStars />
      </FlexContainer>
      <TMText $marginY={15} $horizontalAligned $color={COLORS.SUBTITLE_BLACK}>
        For additional support
        <TMAnchor
          blank
          href="https://help.tokenmetrics.com/en/"
          $color={COLORS.CERULEAN_BLUE}
          $marginLeft={5}
        >
          click here
        </TMAnchor>
        .
      </TMText>
    </Modal>
  );
};

export default SuccessModal;
