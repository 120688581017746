import type { VFC } from 'react';
import { Button, Layout } from 'antd';
import styled from 'styled-components';

import packageJson from '../../../../package.json';
import COLORS from '../../../styles/Colors';

const Footer: VFC = () => {
  return (
    <StyledFooter>
      <div>
        <span>2022 © Token Metrics | Visit</span>
        <a target="_blank" href="https://tokenmetrics.com" rel="noreferrer">
          tokenmetrics.com
        </a>
        <span> | v{packageJson.version}</span>
      </div>

      <div>
        <Button
          href="https://help.tokenmetrics.com/en/collections/2036122-faqs-and-troubleshooting"
          target="_blank"
          type="link"
        >
          FAQ
        </Button>
        <Button
          href="https://tokenmetrics.com/about"
          target="_blank"
          type="link"
        >
          About
        </Button>
        <Button
          href="https://help.tokenmetrics.com/en/"
          target="_blank"
          type="link"
        >
          Support
        </Button>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled(Layout.Footer)`
  padding: 20px;
  border-top: 1px ${COLORS.ATHENS_GRAY} solid;
  background-color: ${COLORS.WHITE};
  display: flex;

  justify-content: space-between;

  a {
    text-decoration: underline;
    color: ${COLORS.TEXT_GRAY};
    margin-left: 5px;
    font-size: 12px;
  }

  span {
    color: ${COLORS.TEXT_GRAY};
    font-size: 12px;
  }
`;

export default Footer;
