import styled from 'styled-components';

const CircleIconContainer = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 72px;
`;

export default CircleIconContainer;
