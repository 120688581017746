import styled from 'styled-components';

import PaymentMethod from '../../../assets/icons/PaymentMethod';
import COLORS from '../../../styles/Colors';
import CircleIconContainer from '../../common/CircleIconContainer';
import { TMText, TMTitle } from '../../common/typography';
import useOktaAuthCustom from '../../../hooks/useOktaAuthCustom';

const ThereIsNotPayment = () => {
  const authState = useOktaAuthCustom();

  return (
    <Container>
      <CircleIconContainer color={COLORS.ALPHA_CERULEAN_BLUE}>
        <PaymentMethod color={COLORS.CERULEAN_BLUE} />
      </CircleIconContainer>
      <ContentContainer>
        <TMTitle level={4}>There is no payment method yet</TMTitle>

        {!authState.user?.isSuperAdmin && (
          <TMText $color={COLORS.TITLE_BLACK}>
            For adding one, please click on{' '}
            <TMText $bold $color={COLORS.TITLE_BLACK}>
              Add Payment Method
            </TMText>
          </TMText>
        )}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const ContentContainer = styled(Container)`
  margin-top: 20px;
  gap: 10px 0;
  text-align: center;
`;

export default ThereIsNotPayment;
