import styled from 'styled-components';

import COLORS from '../../../styles/Colors';

interface Props {
  $color?: COLORS;
  $width?: number;
  $height?: number;
  $verticalMargin?: number;
  $marginBottom?: number;
}

const BorderRoundedDivider = styled.div<Props>(
  ({
    $marginBottom,
    $color = COLORS.ATHENS_GRAY,
    $width = 30,
    $height = 2,
    $verticalMargin = 0,
  }) => {
    return {
      background: $color,
      width: `${$width}px`,
      height: `${$height}px`,
      margin: 'auto',
      marginTop: $verticalMargin,
      marginBottom: $marginBottom || $verticalMargin,
    };
  },
);

export default BorderRoundedDivider;
