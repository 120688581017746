import type { FC } from 'react';
import { useState, useMemo, useCallback } from 'react';

import Context from '.';

const PaymentMethodContextProvider: FC = ({ children }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const toggleEditMode = useCallback(
    () => setEditMode((edit) => !edit),
    [setEditMode],
  );

  const value = useMemo(
    () => ({
      editMode,
      toggleEditMode,
    }),
    [editMode, toggleEditMode],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default PaymentMethodContextProvider;
