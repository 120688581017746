import { format } from 'date-fns';
import { shallowEqual, useSelector } from 'react-redux';

import type RootState from '../types/RootState';
import type { User } from '../slices/users';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

const isUploadingUsersInBulkSelector = (state: RootState): boolean => {
  return state.users.uploadBulkStatus === LOADING_STATUSES.LOADING;
};

const isUploadingFinished = (state: RootState): boolean => {
  return (
    state.users.uploadBulkStatus === LOADING_STATUSES.FULFILLED ||
    state.users.uploadBulkStatus === LOADING_STATUSES.FAILED
  );
};

const isInvitingUsers = (state: RootState): boolean =>
  state.users.inviteUsersStatus === LOADING_STATUSES.LOADING;

const isInvitingUsersFinished = (state: RootState): boolean =>
  state.users.inviteUsersStatus === LOADING_STATUSES.FULFILLED ||
  state.users.inviteUsersStatus === LOADING_STATUSES.FAILED;

const wasInvitingUsersSuccessful = (state: RootState): boolean =>
  state.users.inviteUsersStatus === LOADING_STATUSES.FULFILLED;

const wasInvitingUsersFailure = (state: RootState): boolean =>
  state.users.inviteUsersStatus === LOADING_STATUSES.FAILED;

const wasUploadingSuccessful = (state: RootState): boolean =>
  state.users.uploadBulkStatus === LOADING_STATUSES.FULFILLED;

const wasUploadingFailure = (state: RootState): boolean =>
  state.users.uploadBulkStatus === LOADING_STATUSES.FAILED;

const isLoadingUsers = (state: RootState): boolean => {
  return state.users.users.loading === LOADING_STATUSES.LOADING;
};
const isUsersLoadingFinishedSelector = (state: RootState): boolean => {
  return (
    state.users.users.loading === LOADING_STATUSES.FULFILLED ||
    state.users.users.loading === LOADING_STATUSES.FAILED
  );
};

const usersSelector = (state: RootState): User[] => state.users.users.items;

const totalUsersSelector = (state: RootState): number =>
  state.users.users.total;

const activeProcessSelector = (state: RootState): boolean =>
  state.users.activeProcess;

const userActivityMetricsSelector = (state: RootState) =>
  state.users.userActivityMetrics;

const userActivityMetricsPercentageDifferenceSelector = (state: RootState) =>
  state.users.userActivityMetrics.differenceRespectToPreviousMonth;

const isUserActivityMetricsLoadingFinishedSelector = (
  state: RootState,
): boolean => {
  return (
    state.users.userActivityMetrics.userActivityMetricsLoadingStatus ===
      LOADING_STATUSES.FULFILLED ||
    state.users.userActivityMetrics.userActivityMetricsLoadingStatus ===
      LOADING_STATUSES.FAILED
  );
};

const isUserActivityMetricsLoadingSelector = (state: RootState): boolean => {
  return (
    state.users.userActivityMetrics.userActivityMetricsLoadingStatus ===
    LOADING_STATUSES.LOADING
  );
};

const currentMonthActivitySelector = (state: RootState) =>
  state.users.userActivityMetrics.activityPerMonth?.[
    format(new Date(), 'MMM-yy')
  ];

const activityPerMonthSelector = (state: RootState) =>
  state.users.userActivityMetrics.activityPerMonth;

const userByIdSelector = (userId: string) => (state: RootState) =>
  state.users.users.items.find((user) => user.id === userId);

const isUserLoadingByIdSelector = (userId: string) => (state: RootState) =>
  userByIdSelector(userId)(state)?.writeStatus === LOADING_STATUSES.LOADING;

const userByOktaIdSelector = (oktaUid: string) => (state: RootState) =>
  state.users.users.items.find((user) => user.oktaUid === oktaUid);

const isDeletingUsers = (state: RootState) =>
  state.users.deleteStatus === LOADING_STATUSES.LOADING;

const isDeletingUserFinishedSelector = (state: RootState): boolean => {
  return (
    state.users.deleteStatus === LOADING_STATUSES.FULFILLED ||
    state.users.deleteStatus === LOADING_STATUSES.FAILED
  );
};

export const useIsUploadingUsersInBulk = () =>
  useSelector(isUploadingUsersInBulkSelector);

export const useIsUploadingFinished = () => useSelector(isUploadingFinished);

export const useWasUploadingSuccessful = () =>
  useSelector(wasUploadingSuccessful);

export const useIsInvitingUsers = () => useSelector(isInvitingUsers);

export const useIsLoadingUsers = () => useSelector(isLoadingUsers);

export const useUsers = () => useSelector(usersSelector, shallowEqual);

export const useTotalUsers = () =>
  useSelector(totalUsersSelector, shallowEqual);

export const useIsUsersLoadingFinished = () =>
  useSelector(isUsersLoadingFinishedSelector);

export const useWasUploadingFailure = () => useSelector(wasUploadingFailure);

export const useUploadUsersInBulkError = () =>
  useSelector((state: RootState) => state.users.uploadBulkError, shallowEqual);

export const useInviteUsersError = () =>
  useSelector((state: RootState) => state.users.inviteUsersError, shallowEqual);

export const useIsInvitingUsersFinished = () =>
  useSelector(isInvitingUsersFinished);

export const useWasInvitingUsersSuccessful = () =>
  useSelector(wasInvitingUsersSuccessful);

export const useWasInvitingUsersFailure = () =>
  useSelector(wasInvitingUsersFailure);

export const useActiveProcess = () => useSelector(activeProcessSelector);

export const useUserActivityMetrics = () =>
  useSelector(userActivityMetricsSelector, shallowEqual);

export const useIsUserActivityMetricsLoadingFinished = () =>
  useSelector(isUserActivityMetricsLoadingFinishedSelector);

export const useUserActivityMetricsPercentageDifference = () =>
  useSelector(userActivityMetricsPercentageDifferenceSelector);

export const useIsUserActivityMetricsLoading = () =>
  useSelector(isUserActivityMetricsLoadingSelector);

export const useCurrentMonthActivity = () =>
  useSelector(currentMonthActivitySelector);

export const useActivityPerMonth = () =>
  useSelector(activityPerMonthSelector, shallowEqual);

export const useUserById = (userId: string) =>
  useSelector(userByIdSelector(userId), shallowEqual);

export const useIsUserLoadingById = (userId: string) =>
  useSelector(isUserLoadingByIdSelector(userId), shallowEqual);

export const useIsUserWritingPlanById = (userId: string) =>
  useSelector(
    (state: RootState) =>
      userByIdSelector(userId)(state)?.writeStatusPlan ===
      LOADING_STATUSES.LOADING,
  );

export const useUserByOktaUid = (oktaUid: string) =>
  useSelector(userByOktaIdSelector(oktaUid), shallowEqual);

export const useIsDeletingUsers = () => useSelector(isDeletingUsers);

export const useIsDeletingUsersFinished = () =>
  useSelector(isDeletingUserFinishedSelector);

export const useIsAnyUserWritingPlan = () =>
  useSelector((state: RootState) =>
    usersSelector(state).some(
      (user) => user.writeStatusPlan === LOADING_STATUSES.LOADING,
    ),
  );
