import styled from 'styled-components';

import COLORS from '../../../styles/Colors';

interface Props {
  sideMargin?: number;
  color?: COLORS;
  type?: 'vertical' | 'horizontal';
}

const Divider = styled.div<Props>(
  ({ sideMargin = 10, color = COLORS.ATHENS_GRAY, type = 'horizontal' }) => {
    const border = `1px solid ${color}`;
    let styles;

    if (type === 'horizontal') {
      styles = {
        marginTop: sideMargin,
        marginBottom: sideMargin,
        borderTop: border,
        width: '100%',
      };
    } else {
      // Vertical requires to be in a flex container with and Stretch property
      styles = {
        marginRight: sideMargin,
        marginLeft: sideMargin,
        borderRight: border,
      };
    }

    return styles;
  },
);

export default Divider;
