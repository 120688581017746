import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const TrendingDown: VFC<IconInterface> = ({ color = COLORS.GRAY }) => (
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1667 0L15.1673 1.11759e-08C15.4435 1.11759e-08 15.6673 0.223858 15.6673 0.5V4.5C15.6673 4.77614 15.4435 5 15.1673 5C14.8912 5 14.6673 4.77614 14.6673 4.5V1.70645L9.18689 7.18689C9.09312 7.28065 8.96594 7.33333 8.83333 7.33333C8.70072 7.33333 8.57355 7.28065 8.47978 7.18689L5.5 4.20711L0.853553 8.85355C0.658291 9.04882 0.341709 9.04882 0.146447 8.85355C-0.0488155 8.65829 -0.0488155 8.34171 0.146447 8.14645L5.14645 3.14645C5.24021 3.05268 5.36739 3 5.5 3C5.63261 3 5.75978 3.05268 5.85355 3.14645L8.83333 6.12623L13.9596 1H11.1673C10.8912 1 10.6673 0.776142 10.6673 0.5C10.6673 0.223858 10.8912 1.11759e-08 11.1673 1.11759e-08L15.1667 0Z"
      fill={color}
    />
  </svg>
);

export default TrendingDown;
