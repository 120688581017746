import { createSlice } from '@reduxjs/toolkit';

import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';
import { getContractByPartner } from '../thunks/contracts';

export interface Contract {
  id: number;
  partnerId: number;
  contractUrl: string;
  signedBy: string;
  signedTitle: string;
  signedEmail: string;
  signedPhone: number;
  signDate: Date;
  startDate: Date;
  expireDate: Date;
  active: boolean;
  totalSeats: number;
  initialAmount: number;
  billingCycle: string;
}

export interface ContractState {
  readonly contract: Contract | null;
  readonly loadingStatus: LOADING_STATUSES;
  readonly loadError: string | null;
}

const initialState: ContractState = {
  loadingStatus: LOADING_STATUSES.NOT_LOADING,
  contract: null,
  loadError: null,
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getContractByPartner.pending, (state) => {
        state.loadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(getContractByPartner.fulfilled, (state, { payload }) => {
        state.loadingStatus = LOADING_STATUSES.FULFILLED;
        state.contract = payload;
      })
      .addCase(getContractByPartner.rejected, (state, { error }) => {
        state.loadingStatus = LOADING_STATUSES.FAILED;
        state.loadError = error.message ?? 'Unknown error';
      }),
});

export default contractsSlice.reducer;
