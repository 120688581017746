import React from 'react';

import UsersOutlined from '../../assets/icons/UsersOutlined';
import COLORS from '../../styles/Colors';
import { TMAnchor } from '../common/typography';
import BaseWidget from './BaseWidget';

const TMNews: React.FC = () => {
  return (
    <BaseWidget
      title="Token Metrics News"
      icon={<UsersOutlined color={COLORS.MUSTARD} />}
      containerIconColor={COLORS.ALPHA_MUSTARD}
    >
      <TMAnchor $bold blank href="https://tv.tokenmetrics.com/">
        TMTV Apps
      </TMAnchor>
    </BaseWidget>
  );
};

export default TMNews;
