import type { FC } from 'react';
import styled from 'styled-components';
import { AiFillWarning } from 'react-icons/ai';
import { Button, Modal, Typography } from 'antd';

import COLORS from '../../../styles/Colors';
import FlexContainer from '../flex/FlexContainer';

interface Props {
  message?: string;
  subtitle?: string;
  isVisible: boolean;
  hideHandler: () => void;
  isDeleting: boolean;
  deleteButtonText?: string;
  deleteHandler: () => void;
}

const DeleteConfirmation: FC<Props> = ({
  isVisible,
  hideHandler,
  isDeleting,
  deleteHandler,
  message = 'Are you sure to continue and delete this resource?',
  subtitle = 'This action is not reversible',
  deleteButtonText = 'Delete',
}) => {
  return (
    <Modal
      centered
      destroyOnClose
      footer={null}
      closable={!isDeleting}
      maskClosable={!isDeleting}
      visible={isVisible}
      onCancel={hideHandler}
    >
      <FlexContainer $direction="column" $align="center" $textAlign="center">
        <StyledIcon />
        <Typography.Title level={3}>{message}</Typography.Title>
        <Typography.Text type="danger">{subtitle}</Typography.Text>
      </FlexContainer>
      <FlexContainer $justify="space-evenly" $marginTop={40} $gap={25}>
        <Button block onClick={hideHandler} disabled={isDeleting}>
          Cancel
        </Button>
        <Button danger block onClick={deleteHandler} loading={isDeleting}>
          {deleteButtonText}
        </Button>
      </FlexContainer>
    </Modal>
  );
};

const StyledIcon = styled(AiFillWarning)`
  font-size: 60px;
  margin-bottom: 20px;
  color: ${COLORS.DANGER};
`;

export default DeleteConfirmation;
