import type { FC } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Input, InputNumber, Button } from 'antd';
import type { Stripe, SetupIntent } from '@stripe/stripe-js';

import Bank from '../../../assets/icons/Bank';
import COLORS from '../../../styles/Colors';
import CircleIconContainer from '../../common/CircleIconContainer';
import { TMText, TMTitle } from '../../common/typography';
import { setSetupIntent } from '../../../store/slices/partners';

interface Props {
  stripe: Promise<Stripe | null>;
  clientSecret: string | null;
}

const VerifyMicrodeposits: FC<Props> = ({ stripe, clientSecret }) => {
  const dispatch = useDispatch();
  const [firstNumber, setFirstNumber] = useState<number | string>();
  const [secondNumber, setSecondNumber] = useState<number | string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onPaymentAction = () => {
    if (firstNumber && secondNumber) {
      if (clientSecret) {
        setErrorMessage('');

        stripe.then(async (client) => {
          if (client) {
            setIsSubmitting(true);

            const result = await client.verifyMicrodepositsForSetup(
              clientSecret,
              {
                amounts: [Number(firstNumber), Number(secondNumber)],
              },
            );

            if (result.error) {
              dispatch(
                setSetupIntent(result.error.setup_intent as SetupIntent),
              );
            } else {
              const setupIntent = result.paymentIntent as unknown;
              dispatch(setSetupIntent(setupIntent as SetupIntent));
            }
            setIsSubmitting(false);
          }
        });
      }
    } else {
      setErrorMessage(
        'Please verify the microdeposits that were sent to your bank account',
      );
    }
  };

  return (
    <Container>
      <CircleIconContainer color={COLORS.ALPHA_MUSTARD}>
        <Bank color={COLORS.MUSTARD} />
      </CircleIconContainer>
      <ContentContainer>
        <TMTitle level={4}>Verify bank account with microdeposits</TMTitle>

        <TMText $color={COLORS.TITLE_BLACK}>
          Stripe has sent a single 0.01 USD microdeposit to your bank account
          with a unique 6-digit description_code that starts with SM. You need
          to use this code to verify your bank account. Please enter the
          statement descriptor that looks like{' '}
          <StyledCode>AMTS: 32,45</StyledCode>.
        </TMText>
      </ContentContainer>

      <ContentContainer>
        <Input.Group compact>
          <InputNumber
            style={{ width: 50, textAlign: 'center' }}
            controls={false}
            onChange={(n) => setFirstNumber(n)}
          />
          <Input
            className="site-input-split"
            style={{
              width: 30,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            placeholder="-"
            disabled
          />
          <InputNumber
            className="site-input-right"
            style={{
              width: 50,
              textAlign: 'center',
            }}
            controls={false}
            onChange={(n) => setSecondNumber(n)}
          />
        </Input.Group>

        {errorMessage && (
          <TMText $textAlign="center" $color={COLORS.DANGER}>
            {errorMessage}
          </TMText>
        )}
      </ContentContainer>

      <StyledButton
        type="primary"
        loading={isSubmitting}
        onClick={onPaymentAction}
      >
        Verify Bank Account
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled(Container)`
  margin-top: 20px;
  gap: 10px 0;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  align-self: center;
`;

const StyledCode = styled.span`
  background-color: lightgray;
  padding: 2px 5px;
  font-family: 'Courier New';
  border-radius: 2px;
`;

export default VerifyMicrodeposits;
