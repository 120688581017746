import http from '../http';
import { ResponseParser } from '../http/ResponseParser';
import PartnerAdminEndpoints from './Endpoints';

const UpdateUserDidLogin = async (adminId: string) => {
  const { data: pureData } = await http.request({
    url: PartnerAdminEndpoints.updateUserDidFirstLogin.endpoint.replace(
      ':adminId',
      adminId,
    ),
    method: PartnerAdminEndpoints.updateUserDidFirstLogin.method,
  });

  return ResponseParser<boolean>(pureData);
};

export default UpdateUserDidLogin;
