import { useState, useEffect, useCallback } from 'react';
import type { VFC } from 'react';
import { useDispatch } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { Button, Form, Input, message, Select } from 'antd';
import styled from 'styled-components';
import { MdInfoOutline } from 'react-icons/md';

import { updatePaymentMethod } from '../../../../../../store/thunks/partners';
import {
  resetPaymentMethod,
  resetUpdatePaymentLoading,
  setSetupIntent,
} from '../../../../../../store/slices/partners';
import {
  usePartnerName,
  useIsLoadingPaymentMethodFinished,
  usePaymentMethod,
  useSetupIntent,
} from '../../../../../../store/selectors/partners';
import COLORS from '../../../../../../styles/Colors';

interface Props {
  toggleEditMode: () => void;
}

export interface EditPaymentForm {
  name: string;
  line1: string;
  state: string;
  usBankAccount?: {
    routing_number: string;
    account_number: string;
    confirm_account_number?: string;
    account_holder_type?: string;
    account_type?: string;
  };
}

const EditPaymentForm: VFC<Props> = ({ toggleEditMode }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const companyName = usePartnerName();
  const paymentMethod = usePaymentMethod();
  const setupIntent = useSetupIntent();
  const updatePaymentMethodFinished = useIsLoadingPaymentMethodFinished();
  const [paymentValues, setPaymentValues] = useState<EditPaymentForm>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  const confirmPaymentMethod = useCallback(async () => {
    if (stripe && setupIntent) {
      if (setupIntent?.status !== 'succeeded') {
        const result = await stripe.confirmUsBankAccountSetup(
          setupIntent.client_secret || '',
          {
            return_url: `${window.location.origin}/manage-your-plan`,
          },
        );

        if (result.error) {
          message.error(result.error.message);
          dispatch(resetUpdatePaymentLoading());
          setIsSaving(false);
        } else {
          dispatch(resetPaymentMethod());
          dispatch(setSetupIntent(result.setupIntent));
          setIsSaving(false);
          toggleEditMode();
        }
      }
    }
  }, [stripe, setupIntent, toggleEditMode, dispatch]);

  useEffect(() => {
    if (paymentMethod) {
      setPaymentValues({
        name: paymentMethod.ach.holderName,
        line1: paymentMethod.line1,
        state: paymentMethod.state,
        usBankAccount: {
          routing_number: '',
          account_number: '',
          account_holder_type: paymentMethod.ach.holderType,
        },
      });
    }

    return () => form.resetFields();
  }, [paymentMethod, form]);

  useEffect(() => {
    if (isSaving && updatePaymentMethodFinished) {
      confirmPaymentMethod();
    }
  }, [isSaving, updatePaymentMethodFinished, confirmPaymentMethod]);

  const onFinish = async (data: EditPaymentForm) => {
    if (stripe && data && data.usBankAccount) {
      setIsSaving(true);

      const { error, paymentMethod: paymentMethodCreated } =
        await stripe.createPaymentMethod({
          type: 'us_bank_account',
          us_bank_account: {
            account_number: data.usBankAccount.account_number,
            routing_number: data.usBankAccount.routing_number,
            account_holder_type:
              data.usBankAccount.account_holder_type || 'individual',
            account_type: 'checking',
          },
          billing_details: {
            address: {
              state: data.state,
              line1: data.line1,
            },
            name: data.name,
          },
        });

      if (error) {
        message.error(error.message);
        setIsSaving(false);
      } else if (paymentMethodCreated) {
        const { id: paymentMethodId, type } = paymentMethodCreated;
        dispatch(updatePaymentMethod({ paymentMethodId, type, companyName }));
      }
    }
  };

  if (!paymentValues) return null;

  return (
    <Form
      name="editPaymentForm"
      form={form}
      initialValues={paymentValues}
      labelAlign="left"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 12, push: 2 }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <StyledItem
        labelAlign="left"
        label="Name on Account"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input the name on account!',
          },
          {
            max: 100,
            message: 'the name must not be greater than 100 characters',
          },
        ]}
      >
        <StyledInput placeholder="Name" />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="Bank Routing Number"
        name={['usBankAccount', 'routing_number']}
        rules={[
          {
            required: true,
            message: 'Please input the bank routing number!',
          },
          {
            len: 9,
            message: 'The bank routing number must be 9 digits',
          },
        ]}
      >
        <StyledInput placeholder="9 Digits" suffix={<MdInfoOutline />} />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="Checking Account Number"
        name={['usBankAccount', 'account_number']}
        rules={[
          {
            required: true,
            message: 'Please input the bank account number!',
          },
          {
            max: 17,
            message: 'The bank account number must not be more than 17 digits',
          },
        ]}
      >
        <StyledInput placeholder="Up to 17 Digits" suffix={<MdInfoOutline />} />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="Re-enter Checking Account Number"
        name={['usBankAccount', 'confirm_account_number']}
        rules={[
          {
            required: true,
            message: 'Please confirm the bank account number!',
          },
          {
            max: 17,
            message: 'The bank account number must not be more than 17 digits',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                !value ||
                getFieldValue(['usBankAccount', 'account_number']) === value
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Account number does not match'));
            },
          }),
        ]}
      >
        <StyledInput placeholder="Up to 17 Digits" />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="Account holder type"
        name={['usBankAccount', 'account_holder_type']}
      >
        <StyledSelect placeholder="Holder type" defaultValue="individual">
          <Select.Option key="individual" value="individual">
            Individual
          </Select.Option>
          <Select.Option key="company" value="company">
            Company
          </Select.Option>
        </StyledSelect>
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="Address line 1"
        name="line1"
        rules={[
          {
            required: true,
            message: 'Please input the address!',
          },
          {
            max: 100,
            message: 'the line 1 must not be greater than 100 characters',
          },
        ]}
      >
        <StyledInput placeholder="Address 1" />
      </StyledItem>

      <StyledItem
        labelAlign="left"
        label="State"
        name="state"
        rules={[
          {
            required: true,
            message: 'Please input the state!',
          },
          {
            max: 50,
            message: 'the state must not be greater than 50 characters',
          },
        ]}
      >
        <StyledInput placeholder="State" />
      </StyledItem>

      <StyledItem wrapperCol={{ span: 18, offset: 4 }}>
        <ButtonsContainer>
          <Button
            type="primary"
            loading={isSaving}
            htmlType="submit"
            size="large"
            block
          >
            Save Changes
          </Button>
          <Button
            disabled={isSaving}
            style={{
              backgroundColor: '#eff0f6',
              border: '#eff0f6',
              color: '#171717',
            }}
            onClick={toggleEditMode}
            size="large"
            block
          >
            Cancel
          </Button>
        </ButtonsContainer>
      </StyledItem>
    </Form>
  );
};

const StyledItem = styled(Form.Item)`
  margin-bottom: 15px;

  label:not(.ant-form-item-required) {
    padding-left: 10px;
  }
`;
const StyledInput = styled(Input)`
  border: 0 !important;
  border-radius: 5px;
  box-shadow: none !important;
  width: 100%;
`;
const StyledSelect = styled(Select)`
  .ant-select-selector {
    background-color: #eff0f6 !important;
    border: 0 !important;
    border-radius: 5px;
    box-shadow: none !important;
    color: ${COLORS.TITLE_BLACK};
    width: 100%;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 40px;
`;

export default EditPaymentForm;
