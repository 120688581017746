import { Button } from 'antd';
import { Link } from 'react-router-dom';

import Routes from '../../routes/routeNames';
import COLORS from '../../styles/Colors';
import useModal from '../../hooks/useModal';
import Divider from '../common/Divider/Divider';
import { TMAnchor, TMText } from '../common/typography';
import FlexContainer from '../common/flex/FlexContainer';
import UploadBulkUsersModal from './UploadBulkUsersModal';
import { useTotalUsers } from '../../store/selectors/users';
import BorderedHeader from '../common/headers/BorderedHeader';
import { usePartnerName } from '../../store/selectors/partners';
import useOktaAuthCustom from '../../hooks/useOktaAuthCustom';

const Header = () => {
  const { isVisible, showHandler, hideHandler } = useModal();
  const totalUsers = useTotalUsers();
  const partnerName = usePartnerName();
  const authState = useOktaAuthCustom();

  return (
    <>
      <UploadBulkUsersModal isVisible={isVisible} hideHandler={hideHandler} />
      <BorderedHeader $paddingY={10}>
        <FlexContainer>
          <TMText $verticalAligned>
            Current Number of Users:
            <TMText $bold $color={COLORS.TITLE_BLACK} $marginX={5}>
              {totalUsers}
            </TMText>
          </TMText>
          <Divider type="vertical" />
          <TMText $verticalAligned>
            Company:
            <TMText $bold $color={COLORS.TITLE_BLACK} $marginX={5}>
              {partnerName}
            </TMText>
          </TMText>
        </FlexContainer>

        <FlexContainer>
          {!authState.user?.isSuperAdmin && (
            <Button type="primary" onClick={showHandler}>
              Upload Users in Bulk
            </Button>
          )}
          <TMText $marginX={20} $verticalAligned>
            <TMAnchor
              blank
              href="https://prtnr-testbucket.s3.amazonaws.com/data/internal/partnerplan/useruploads/template.xlsx"
              $color={COLORS.CERULEAN_BLUE}
              $marginRight={5}
            >
              Download
            </TMAnchor>{' '}
            File Template for Bulk User Upload
          </TMText>
          <TMText $color={COLORS.CERULEAN_BLUE} $verticalAligned $underline>
            <Link to={Routes.HowToUploadUsers}>How to Upload Users?</Link>
          </TMText>
        </FlexContainer>
      </BorderedHeader>
    </>
  );
};

export default Header;
