import type { SetupIntent } from '@stripe/stripe-js';
import { message, notification } from 'antd';
import { createSlice } from '@reduxjs/toolkit';

import type ErrorStoreType from '../types/ErrorStoreType';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';
import {
  deleteLogo,
  getPartner,
  updatePartner,
  uploadLogoImage,
  createSetupIntent,
  getPartnerPayment,
  retrieveSetupIntent,
  updatePaymentMethod,
  switchChargeMethod,
  getPartners,
} from '../thunks/partners';

export interface Card {
  name: string;
  last4: string;
  brand: string;
  expMonth: string;
  expYear: string;
  country: string;
}
export interface ACH {
  holderName: string;
  routingNumber: string;
  last4: string;
  holderType: string;
  accountType: string;
  bankName: string;
}
export interface SEPA {
  name: string;
  email: string;
  bankCode: string;
  branchCode: string;
  country: string;
  last4: string;
}
export interface PartnerPayment {
  id: string;
  type: string;
  active: boolean;
  chargeMethod: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  card: Card;
  ach: ACH;
  sepa: SEPA;
}

interface ContactPerson {
  fullname: string;
  email: string;
  phone: number;
}

export interface Partner {
  id: number;
  name: string;
  contactPerson?: ContactPerson;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode?: string;
  country: string;
  logoUrl?: string | null;
  customerId?: string;
  setupId?: string;
}

export interface PartnerManager {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  telegram: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

interface PartnerPaymentState {
  readonly paymentMethod: PartnerPayment | null;
  readonly loadingPaymentStatus: LOADING_STATUSES;
  readonly loadPaymentError: string | null;
  readonly loadingChargeMethodStatus: LOADING_STATUSES;
}

interface UpdatePaymentState {
  readonly loadingUpdatePayment: LOADING_STATUSES;
  readonly updatePaymentError: string | null;
}

export interface PartnerState {
  readonly partner: Partner | null;
  readonly partners: Partner[];
  readonly loadPartnersStatus: LOADING_STATUSES;
  readonly loadPartnersError: ErrorStoreType;
  readonly writeStatus: LOADING_STATUSES;
  readonly writeError: ErrorStoreType;
  readonly loadingStatus: LOADING_STATUSES;
  readonly loadError: ErrorStoreType | null;
  readonly partnerManager: PartnerManager | null;
  readonly setupIntent: SetupIntent | null;
  readonly setupIntentLoadingStatus: LOADING_STATUSES;
  readonly setupIntentCreatingStatus: LOADING_STATUSES;
  readonly createSetupIntentError: string | null;
}

export type PartnerFinalState = PartnerState &
  PartnerPaymentState &
  UpdatePaymentState;

const initialState: PartnerFinalState = {
  loadingStatus: LOADING_STATUSES.NOT_LOADING,
  writeStatus: LOADING_STATUSES.NOT_LOADING,
  writeError: null,
  partner: null,
  partnerManager: null,
  partners: [],
  loadPartnersStatus: LOADING_STATUSES.NOT_LOADING,
  loadPartnersError: null,
  loadError: null,
  loadingPaymentStatus: LOADING_STATUSES.NOT_LOADING,
  paymentMethod: null,
  loadPaymentError: null,
  loadingUpdatePayment: LOADING_STATUSES.NOT_LOADING,
  updatePaymentError: null,
  setupIntentLoadingStatus: LOADING_STATUSES.NOT_LOADING,
  setupIntent: null,
  setupIntentCreatingStatus: LOADING_STATUSES.NOT_LOADING,
  createSetupIntentError: null,
  loadingChargeMethodStatus: LOADING_STATUSES.NOT_LOADING,
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    resetWriteStatus(state) {
      state.writeError = null;
      state.writeStatus = LOADING_STATUSES.NOT_LOADING;
    },
    setPaymentMethod(state, { payload }) {
      state.paymentMethod = payload;
    },
    setSetupIntent(state, { payload }) {
      state.setupIntent = payload;
    },
    resetPaymentMethod(state) {
      state.paymentMethod = null;
      state.loadingPaymentStatus = LOADING_STATUSES.NOT_LOADING;
    },
    resetUpdatePaymentLoading(state) {
      state.loadingUpdatePayment = LOADING_STATUSES.NOT_LOADING;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPartner.pending, (state) => {
        state.loadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(getPartner.fulfilled, (state, { payload }) => {
        state.loadingStatus = LOADING_STATUSES.FULFILLED;
        state.partner = payload.partner;
        state.partnerManager = payload.manager;
      })
      .addCase(getPartner.rejected, (state, action) => {
        state.loadingStatus = LOADING_STATUSES.FAILED;
        state.loadError = action.payload ?? null;
      })
      .addCase(updatePartner.pending, (state) => {
        state.loadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(updatePartner.fulfilled, (state, { payload }) => {
        state.loadingStatus = LOADING_STATUSES.FULFILLED;
        state.partner = payload;
        message.success('Partner updated successfully');
      })
      .addCase(updatePartner.rejected, (state, { payload }) => {
        state.loadingStatus = LOADING_STATUSES.FAILED;
        state.loadError = payload ?? null;
      })
      .addCase(uploadLogoImage.pending, (state) => {
        state.writeError = null;
        state.writeStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(uploadLogoImage.fulfilled, (state, { payload }) => {
        state.writeStatus = LOADING_STATUSES.FULFILLED;
        if (state.partner) {
          state.partner.logoUrl = payload;

          notification.success({
            message: 'Success',
            description: 'Logo updated',
          });
        }
      })
      .addCase(uploadLogoImage.rejected, (state, { payload }) => {
        state.writeStatus = LOADING_STATUSES.FAILED;
        state.writeError = payload ?? null;
        notification.error({
          message: 'Error',
          description: payload?.message,
        });
      })
      .addCase(deleteLogo.pending, (state) => {
        state.writeError = null;
        state.writeStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(deleteLogo.fulfilled, (state) => {
        state.writeStatus = LOADING_STATUSES.FULFILLED;
        if (state.partner) {
          state.partner.logoUrl = null;

          notification.success({
            message: 'Success',
            description: 'Logo deleted',
          });
        }
      })
      .addCase(deleteLogo.rejected, (state, { payload }) => {
        state.writeError = payload ?? null;
        state.writeStatus = LOADING_STATUSES.FAILED;

        notification.error({
          message: 'Error',
          description: payload?.message,
        });
      })
      .addCase(getPartnerPayment.pending, (state) => {
        state.loadingPaymentStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(getPartnerPayment.fulfilled, (state, { payload }) => {
        state.loadingPaymentStatus = LOADING_STATUSES.FULFILLED;
        state.paymentMethod = payload;
      })
      .addCase(getPartnerPayment.rejected, (state, { error }) => {
        state.loadingPaymentStatus = LOADING_STATUSES.FAILED;
        state.loadPaymentError = error.message ?? 'Unknown error';
      })
      .addCase(createSetupIntent.pending, (state) => {
        state.setupIntentCreatingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(createSetupIntent.fulfilled, (state, { payload }) => {
        state.setupIntentCreatingStatus = LOADING_STATUSES.FULFILLED;
        state.setupIntent = payload;
      })
      .addCase(createSetupIntent.rejected, (state, { error }) => {
        state.setupIntentCreatingStatus = LOADING_STATUSES.FAILED;
        state.createSetupIntentError = error.message ?? 'Unknown error';
      })
      .addCase(retrieveSetupIntent.pending, (state) => {
        state.setupIntentLoadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(retrieveSetupIntent.fulfilled, (state, { payload }) => {
        state.setupIntentLoadingStatus = LOADING_STATUSES.FULFILLED;
        state.setupIntent = payload;
      })
      .addCase(retrieveSetupIntent.rejected, (state, { error }) => {
        state.setupIntentLoadingStatus = LOADING_STATUSES.FAILED;
        state.loadPaymentError = error.message ?? 'Unknown error';
      })
      .addCase(updatePaymentMethod.pending, (state) => {
        state.loadingUpdatePayment = LOADING_STATUSES.LOADING;
      })
      .addCase(updatePaymentMethod.fulfilled, (state, { payload }) => {
        state.setupIntent = payload;
        state.loadingUpdatePayment = LOADING_STATUSES.FULFILLED;
        state.updatePaymentError = null;
      })
      .addCase(updatePaymentMethod.rejected, (state, { error }) => {
        state.loadingUpdatePayment = LOADING_STATUSES.FAILED;
        state.updatePaymentError = error.message ?? 'Unknown error';
      })
      .addCase(switchChargeMethod.pending, (state) => {
        state.loadingChargeMethodStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(switchChargeMethod.fulfilled, (state, { payload }) => {
        state.paymentMethod = payload;
        state.loadingChargeMethodStatus = LOADING_STATUSES.FULFILLED;
      })
      .addCase(switchChargeMethod.rejected, (state, { payload }) => {
        state.loadingChargeMethodStatus = LOADING_STATUSES.FAILED;
        if (payload?.validationErrors) {
          Object.values(payload.validationErrors)
            .flat()
            .forEach((error: string) => {
              notification.error({
                message: 'Error',
                description: error,
              });
            });
        } else {
          notification.error({
            message: 'Error',
            description: payload?.message,
          });
        }
      })
      .addCase(getPartners.pending, (state) => {
        state.loadPartnersError = null;
        state.loadPartnersStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(getPartners.fulfilled, (state, { payload }) => {
        state.loadPartnersStatus = LOADING_STATUSES.FULFILLED;
        state.partners = payload;
      })
      .addCase(getPartners.rejected, (state, { payload }) => {
        state.loadPartnersStatus = LOADING_STATUSES.FAILED;
        state.partners = [];
        state.loadPartnersError = payload ?? null;
      }),
});

export const {
  setSetupIntent,
  resetPaymentMethod,
  resetUpdatePaymentLoading,
  resetWriteStatus,
} = partnersSlice.actions;
export default partnersSlice.reducer;
