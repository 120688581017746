import { Modal } from 'antd';
import type { VFC } from 'react';
import styled from 'styled-components';

import COLORS from '../../styles/Colors';
import { TMTitle } from '../common/typography';
import type ModalProps from '../../types/ModalProps';
import { BorderRoundedDivider } from '../common/Divider';
import RocketStars from '../../assets/icons/RocketStars';
import FlexContainer from '../common/flex/FlexContainer';

const FirstLoginModal: VFC<ModalProps> = ({ isVisible, hideHandler }) => {
  return (
    <Modal visible={isVisible} onCancel={hideHandler} footer={null} centered>
      <Container>
        <TMTitle level={3} $textAlign="center">
          WELCOME
        </TMTitle>
        <BorderRoundedDivider $color={COLORS.TITLE_BLACK} $verticalMargin={5} />
        <FlexContainer $justify="center" $verticalMargin={30}>
          <RocketStars />
        </FlexContainer>
        <TMTitle level={4} $color={COLORS.SUBTITLE_BLACK} $textAlign="center">
          Congratulations on being part of Token Metrics Crypto Family!
        </TMTitle>
        {/* <TMText $marginY={15} $horizontalAligned $color={COLORS.SUBTITLE_BLACK}>
        We also recommend you to watch{' '}
        <TMText $color={COLORS.CERULEAN_BLUE} $underline>
          &ldquo;Video Tour of Dashboard&rdquo;
        </TMText>
      </TMText> */}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export default FirstLoginModal;
