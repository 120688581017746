import http from '../http';
import UserEndpoints from './Endpoints';
import { multiParse } from '../planDetails/Parser';
import type { DBPlanDetails } from '../planDetails/Parser';
import { ResponseParserList } from '../http/ResponseParser';
import type { PlanDetail } from '@/store/slices/planDetails';

const DeleteUsers = async (oktaUids: string[]) => {
  const { data: pureResponse } = await http.request({
    method: UserEndpoints.deleteUsers.method,
    url: UserEndpoints.deleteUsers.endpoint,
    data: { oktaUids },
  });

  return ResponseParserList<DBPlanDetails, PlanDetail>(
    pureResponse,
    multiParse,
  );
};

export default DeleteUsers;
