import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadPlans } from '../store/thunks/plans';

import {
  useIsLoadingPlans,
  usePlans as useOriginalPlans,
} from '../store/selectors/plans';

const usePlans = () => {
  const dispatch = useDispatch();

  const plans = useOriginalPlans();
  const isLoading = useIsLoadingPlans();

  useEffect(() => {
    if (plans.length <= 0 && !isLoading) {
      dispatch(loadPlans());
    }
  }, [plans, isLoading, dispatch]);

  return {
    plans,
    isLoading,
  };
};

export default usePlans;
