import { useState } from 'react';
import type { FC } from 'react';
import { Button, Col, Row, Skeleton, Spin, Popconfirm } from 'antd';
import ReactSwitch from 'react-switch';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';
import { format } from 'date-fns';

import {
  useIsLoadingChargeMethod,
  usePaymentMethod,
} from '../../../../../store/selectors/partners';
import {
  useInvoice,
  useIsLoadingInvoice,
} from '../../../../../store/selectors/invoices';
import { switchChargeMethod } from '../../../../../store/thunks/partners';
import COLORS from '../../../../../styles/Colors';
import { TMText } from '../../../../common/typography';
import useModal from '../../../../../hooks/useModal';
import InvoiceModal from './Modal';
import useOktaAuthCustom from '../../../../../hooks/useOktaAuthCustom';

const InvoiceInformation: FC = () => {
  const dispatch = useDispatch();
  const authState = useOktaAuthCustom();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { isVisible, showHandler, hideHandler } = useModal();
  const paymentMethod = usePaymentMethod();
  const isChargeMethodLoading = useIsLoadingChargeMethod();
  const invoice = useInvoice();
  const isInvoiceLoading = useIsLoadingInvoice();

  return (
    <Container>
      {invoice && (
        <InvoiceModal
          isVisible={isVisible}
          invoice={invoice}
          hideHandler={hideHandler}
        />
      )}

      <RowContainer>
        <Col span={10} style={{ display: 'flex', alignItems: 'center' }}>
          <TMText>Charge Method:</TMText>
        </Col>
        <Col span={14}>
          <Popconfirm
            title={`This payment method will ${
              paymentMethod?.chargeMethod === 'MANUAL' ? 'auto' : 'manual'
            } charge from the next billing cycle. Are you sure you want to continue?`}
            visible={showConfirm}
            onConfirm={() => {
              setShowConfirm(false);
              dispatch(
                switchChargeMethod(
                  paymentMethod?.chargeMethod === 'MANUAL' ? 'AUTO' : 'MANUAL',
                ),
              );
            }}
            onCancel={() => setShowConfirm(false)}
            okText="Yes"
            cancelText="No"
          >
            <StyledSwitch
              disabled={isChargeMethodLoading || authState.user?.isSuperAdmin}
              checked={paymentMethod?.chargeMethod === 'AUTO'}
              onChange={() => setShowConfirm(true)}
              offColor={COLORS.SOFT_GRAY}
              onColor={COLORS.SOFT_GRAY}
              offHandleColor={COLORS.MUSTARD}
              onHandleColor={COLORS.MUSTARD}
              height={40}
              width={140}
              borderRadius={4}
              activeBoxShadow={`0px 0px 1px 2px ${COLORS.MUSTARD}`}
              uncheckedIcon={<SwitchLabel>Auto</SwitchLabel>}
              checkedIcon={<SwitchLabel>Manual</SwitchLabel>}
              uncheckedHandleIcon={
                isChargeMethodLoading ? (
                  <Spin indicator={<SpinIcon />} />
                ) : (
                  <SwitchLabel>Manual</SwitchLabel>
                )
              }
              checkedHandleIcon={
                isChargeMethodLoading ? (
                  <Spin indicator={<SpinIcon />} />
                ) : (
                  <SwitchLabel>Auto</SwitchLabel>
                )
              }
            />
          </Popconfirm>
        </Col>
      </RowContainer>

      <Skeleton active loading={isInvoiceLoading}>
        {invoice && (
          <>
            {invoice.status !== 'SUCCESS' && (
              <RowContainer>
                <Col span={10}>
                  <TMText>Your next billing due date:</TMText>
                </Col>
                <Col span={14}>
                  <TMText $bold $color={COLORS.TITLE_BLACK}>
                    {format(new Date(invoice.paymentDueDate), 'PP')}
                  </TMText>
                </Col>
              </RowContainer>
            )}

            <RowContainer>
              <Col span={24}>
                <TMText $size={12} $color={COLORS.TITLE_BLACK}>
                  Please click on below button to see the invoice for your
                  previous billing cycle.
                </TMText>
              </Col>
            </RowContainer>

            <RowContainer justify="center">
              <Col span={8}>
                <Button block type="primary" size="large" onClick={showHandler}>
                  Show Invoice
                </Button>
              </Col>
            </RowContainer>
          </>
        )}
      </Skeleton>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;
const RowContainer = styled(Row)`
  margin-bottom: 20px;
`;
const StyledSwitch = styled(ReactSwitch)`
  .react-switch-bg {
    display: flex;
    flex-direction: row;

    & div {
      display: flex;
      align-items: center;
      justify-content: center;
      position: initial !important;
      width: ${({ width }) => width && `${width / 2}px !important`};
    }
  }
  .react-switch-handle {
    display: flex !important;
    transform: ${({ checked, width }) =>
      width && `translateX(${checked ? width / 2 : 0}px) !important`};
    height: ${({ height }) => height && `${height * 0.7}px !important`};
    left: ${({ checked }) => (checked ? '-7px' : '7px')};
    top: ${({ height }) => height && `${height * 0.15}px !important`};
    width: ${({ width }) => width && `${width / 2}px !important`};

    & div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${({ height }) => height && `${height * 0.7}px !important`};
      width: ${({ width }) => width && `${width / 2}px !important`};
    }
  }
`;
const SwitchLabel = styled.div`
  color: ${COLORS.TITLE_BLACK};
  font-weight: 600;
  padding: 2px;
`;
const SpinIcon = styled(ImSpinner2)`
  color: ${COLORS.SUBTITLE_BLACK};
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default InvoiceInformation;
