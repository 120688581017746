import { createSlice } from '@reduxjs/toolkit';

import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';
import { loadPlans } from '../thunks/plans';

export interface Plan {
  name: string;
  id: string;
  planKey: string;
  price: number;
}

export interface PlanState {
  readonly plans: Plan[];
  readonly loadingStatus: LOADING_STATUSES;
  readonly loadError: string | null;
}

const initialState: PlanState = {
  loadingStatus: LOADING_STATUSES.NOT_LOADING,
  plans: [],
  loadError: null,
};

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loadPlans.pending, (state) => {
        state.loadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(loadPlans.fulfilled, (state, { payload }) => {
        state.loadingStatus = LOADING_STATUSES.FULFILLED;
        state.plans = payload;
      })
      .addCase(loadPlans.rejected, (state, { error }) => {
        state.loadingStatus = LOADING_STATUSES.FAILED;
        state.loadError = error.message ?? 'Unknown error';
      }),
});

export default plansSlice.reducer;
