import type { VFC } from 'react';
import styled from 'styled-components';
import UnderConstructionImage from '../assets/images/under_construction.png';

const UnderConstruction: VFC = () => {
  return (
    <StyledContainer>
      <StyledImage src={UnderConstructionImage} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const StyledImage = styled.img`
  width: 40%;

  @media (max-width: 1100px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export default UnderConstruction;
