import http from '../http';
import type { DBPartnerPayment } from './Parser';
import { paymentParse } from './Parser';
import PartnerEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';
import type { PartnerPayment } from '@/store/slices/partners';

const SwitchChargeMethod = async (partnerId: number, chargeMethod: string) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.switchChargeMethod.method,
    url: PartnerEndpoints.switchChargeMethod.endpoint.replace(
      ':partnerId',
      `${partnerId}`,
    ),
    data: { chargeMethod },
  });

  return ResponseParser<DBPartnerPayment, PartnerPayment>(
    pureResponse,
    paymentParse,
  );
};

export default SwitchChargeMethod;
