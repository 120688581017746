import type { Invoice } from '../../store/slices/invoices';

enum INVOICE_STATUSES {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  DUE = 'DUE',
  CANCELLED = 'CANCELLED',
  SUCCESS = 'SUCCESS',
}

export interface DBInvoice {
  PRTNR_INVOICE_ID: string;
  PRTNR_ID: number;
  INVOICE_DT: string;
  PAYMENT_DUE_DT: string;
  BILLING_CURRENCY: string;
  STRIPE_PAYMENT_ID: string;
  STRIPE_INVOICE_ID: string;
  MEMO_URL: string;
  PRTNR_BILLING_ADDR_1: string;
  PRTNR_BILLING_ADDR_2: string;
  PRTNR_BILLING_CITY: string;
  PRTNR_BILLING_STATE: string;
  PRTNR_BILLING_COUNTRY: string;
  PRTNR_BILLING_ZIPCODE: string;
  BASIC_ACTIVE_USERS: number;
  BASIC_TOTAL_USERS: number;
  BASIC_TOTAL_COST: number;
  BASIC_DISCOUNT: number;
  ADVANCED_ACTIVE_USERS: number;
  ADVANCED_TOTAL_USERS: number;
  ADVANCED_TOTAL_COST: number;
  ADVANCED_DISCOUNT: number;
  PREMIUM_ACTIVE_USERS: number;
  PREMIUM_TOTAL_USERS: number;
  PREMIUM_TOTAL_COST: number;
  PREMIUM_DISCOUNT: number;
  TOTAL_DUE_INVOICE_AMT: number;
  TOTAL_BILLING_AMT: number;
  BILLING_STATUS: INVOICE_STATUSES;
  TXN_DETAILS: string;
  CREATED_DT: string;
  UPDATED_DT: string;
}

const InvoiceParser = {
  parse: (dbInvoice: DBInvoice): Invoice => ({
    id: dbInvoice.PRTNR_INVOICE_ID,
    partnerId: dbInvoice.PRTNR_ID,
    invoiceDate: dbInvoice.INVOICE_DT,
    paymentDueDate: dbInvoice.PAYMENT_DUE_DT,
    billingCurrency: dbInvoice.BILLING_CURRENCY,
    paymentId: dbInvoice.STRIPE_PAYMENT_ID,
    stripeInvoiceId: dbInvoice.STRIPE_INVOICE_ID,
    url: dbInvoice.MEMO_URL,
    address1: dbInvoice.PRTNR_BILLING_ADDR_1,
    address2: dbInvoice.PRTNR_BILLING_ADDR_2,
    city: dbInvoice.PRTNR_BILLING_CITY,
    state: dbInvoice.PRTNR_BILLING_STATE,
    country: dbInvoice.PRTNR_BILLING_COUNTRY,
    zipcode: dbInvoice.PRTNR_BILLING_ZIPCODE,
    basic: {
      users: {
        active: dbInvoice.BASIC_ACTIVE_USERS,
        total: dbInvoice.BASIC_TOTAL_USERS,
      },
      totalCost: dbInvoice.BASIC_TOTAL_COST,
      discount: dbInvoice.BASIC_DISCOUNT,
    },
    advanced: {
      users: {
        active: dbInvoice.ADVANCED_ACTIVE_USERS,
        total: dbInvoice.ADVANCED_TOTAL_USERS,
      },
      totalCost: dbInvoice.ADVANCED_TOTAL_COST,
      discount: dbInvoice.ADVANCED_DISCOUNT,
    },
    premium: {
      users: {
        active: dbInvoice.PREMIUM_ACTIVE_USERS,
        total: dbInvoice.PREMIUM_TOTAL_USERS,
      },
      totalCost: dbInvoice.PREMIUM_TOTAL_COST,
      discount: dbInvoice.PREMIUM_DISCOUNT,
    },
    totalDueInvoiceAmount: dbInvoice.TOTAL_DUE_INVOICE_AMT,
    totalBillingAmount: dbInvoice.TOTAL_BILLING_AMT,
    status: dbInvoice.BILLING_STATUS,
    transactionDetails: dbInvoice.TXN_DETAILS,
  }),
  multiParse: (dbInvoices: DBInvoice[]): Invoice[] =>
    dbInvoices.map(InvoiceParser.parse),
};

export const { parse, multiParse } = InvoiceParser;
export default InvoiceParser;
