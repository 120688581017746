import { Skeleton } from 'antd';

import COLORS from '../../styles/Colors';
import Divider from '../common/Divider/Divider';
import { TMText } from '../common/typography';
import FlexContainer from '../common/flex/FlexContainer';
import BorderedHeader from '../common/headers/BorderedHeader';
import {
  useIsLoadingPartnerAdmin,
  usePartnerAdmin,
} from '../../store/selectors/partnerAdmins';

const Header = () => {
  const admin = usePartnerAdmin();
  const isLoading = useIsLoadingPartnerAdmin();

  const expiration = admin?.registrationDate
    ? new Date(admin.registrationDate)
    : new Date();
  const expirationFormat = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'long',
    timeZone: 'UTC',
  }).format(expiration);

  return (
    <BorderedHeader $paddingY={15} $marginYBottom={60}>
      <Skeleton
        loading={isLoading}
        paragraph={{ rows: 0, width: '100%' }}
        active
      >
        <FlexContainer $gap={20}>
          <TMText $color={COLORS.TITLE_BLACK} $verticalAligned>
            {admin?.email}
          </TMText>
          <Divider type="vertical" />
          <TMText $color={COLORS.TITLE_BLACK} $verticalAligned>
            ID:
            <TMText $color={COLORS.TITLE_BLACK} $marginX={5}>
              {admin?.oktaUid}
            </TMText>
          </TMText>
          <Divider type="vertical" />
          <TMText $color={COLORS.TITLE_BLACK} $verticalAligned>
            Registration Date:
            <TMText $color={COLORS.TITLE_BLACK} $marginX={5}>
              {expirationFormat}
            </TMText>
          </TMText>
        </FlexContainer>
      </Skeleton>
    </BorderedHeader>
  );
};

export default Header;
