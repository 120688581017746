import styled from 'styled-components';

import ClockTime from '../../../assets/icons/ClockTime';
import COLORS from '../../../styles/Colors';
import CircleIconContainer from '../../common/CircleIconContainer';
import { TMText, TMTitle } from '../../common/typography';

const PaymentProcessing = () => {
  return (
    <Container>
      <CircleIconContainer color={COLORS.ALPHA_JUNGLE_GREEN}>
        <ClockTime color={COLORS.JUNGLE_GREEN} />
      </CircleIconContainer>
      <ContentContainer>
        <TMTitle level={4}>Processing...</TMTitle>

        <TMText $color={COLORS.TITLE_BLACK}>
          Hold tight, we&apos;re setting up your payment method for future
          payments. We&apos;ll email you when your payment method has been
          successfully set up.
        </TMText>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled(Container)`
  margin-top: 20px;
  gap: 10px 0;
  text-align: center;
`;

export default PaymentProcessing;
