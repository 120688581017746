import http from '../http';
import UserEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';

const GetActiveProcess = async (partnerId: number) => {
  const { data: pureResponse } = await http.request({
    method: UserEndpoints.getActiveProcess.method,
    url: UserEndpoints.getActiveProcess.endpoint,
    params: { partnerId },
  });

  return ResponseParser<boolean>(pureResponse);
};

export default GetActiveProcess;
