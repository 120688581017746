import axios from 'axios';

import CONFIG from '../../config';
import AuthRequestInterceptor from './interceptors/Request/AuthRequestInterceptor';
import AuthResponseInterceptor from './interceptors/Response/AuthResponseInterceptor';

const http = axios.create({
  baseURL: CONFIG.http.baseURL,
  validateStatus: (status) => status !== 401,
});

AuthRequestInterceptor(http);
AuthResponseInterceptor(http);

export default http;
