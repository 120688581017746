import type { FC } from 'react';
import styled from 'styled-components';

import FlexContainer from '../../common/flex/FlexContainer';

const BaseFilter: FC = ({ children }) => {
  return (
    <MainContainer>
      <FlexContainer $direction="column" $gap={10}>
        {children}
      </FlexContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  padding: 15px 20px;
`;

export default BaseFilter;
