import type { FC } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';

import COLORS from '../../../styles/Colors';
import { TMText } from '../../common/typography';

interface Props {
  onChange: () => void;
}

const PasswordInformation: FC<Props> = ({ onChange }) => {
  return (
    <Row>
      <Col span={23} push={1} style={{ marginBottom: 10 }}>
        <TMText $bold $color={COLORS.TITLE_BLACK} $size={16}>
          Password Information
        </TMText>
      </Col>
      <Col span={23} push={1} style={{ marginBottom: 10 }}>
        <Form.Item
          wrapperCol={{ push: 3 }}
          colon={false}
          label="Current Password"
        >
          <StyledInput disabled={!false} value="••••••••••••" />
        </Form.Item>
      </Col>
      <Col span={23} push={1} style={{ marginBottom: 10 }}>
        <TMText $bold $color={COLORS.TITLE_BLACK} $size={16}>
          Update your Password
        </TMText>
      </Col>
      <Col span={23} push={1}>
        <TMText $color={COLORS.TITLE_BLACK}>
          Ensure that these requirements are met:
        </TMText>
        <StyledList>
          <StyledListItem>
            At least 10 characters (and up to 20 characters)
          </StyledListItem>
          <StyledListItem>
            At least one lowercase character and one uppercase character
          </StyledListItem>
          <StyledListItem>
            Include of at least one special character, e.g., ! @ # ?
          </StyledListItem>
        </StyledList>
      </Col>

      <Col xs={24} md={12} xxl={10} style={{ marginTop: 10 }}>
        <Form.Item wrapperCol={{ span: 20, push: 2 }}>
          <Button type="primary" onClick={onChange} size="large" block>
            Edit
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

const StyledInput = styled(Input)`
  color: ${COLORS.GRAY} !important;
  font-size: 18px;
  border: ${COLORS.SOFT_GRAY};
  padding: 0;
`;
const StyledList = styled.ul`
  margin: 0;
  padding: 1rem 2rem;
`;
const StyledListItem = styled.li``;

export default PasswordInformation;
