import { createContext } from 'react';

export interface ContextValue {
  editMode: boolean;
  toggleEditMode: () => void;
}

const PaymentMethodContext = createContext<ContextValue>({
  editMode: false,
  toggleEditMode: () => null,
});

export default PaymentMethodContext;
