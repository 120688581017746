import http from '../http';
import { multiParse } from './Parser';
import type { DBPlan } from './Parser';
import PlanEndpoints from './Endpoints';
import type { Plan } from '@/store/slices/plans';
import { ResponseParserList } from '../http/ResponseParser';

const LoadPlans = async () => {
  const { data: pureResponse } = await http.request({
    method: PlanEndpoints.loadPlans.method,
    url: PlanEndpoints.loadPlans.endpoint,
    params: { partnerPlansOnly: true },
  });

  return ResponseParserList<DBPlan, Plan>(pureResponse, multiParse);
};

export default LoadPlans;
