import { Row, Col, Image } from 'antd';
import styled from 'styled-components';

import { TMTitle, TMText, TMAnchor } from '../common/typography';
import Telescope from '../../assets/images/telescope.png';
import Rocket from '../../assets/images/rocket.png';
import Planet from '../../assets/images/planet.png';
import TextSizes from '../../styles/TextSizes';
import COLORS from '../../styles/Colors';

const LearnMore = () => {
  return (
    <Row align="middle" justify="space-around">
      <StyledTextCol span="5">
        <TMTitle level={5}>
          Plans Features Provided to Partner Plan End Users
        </TMTitle>
        <TMText $size={TextSizes.SMALL}>
          See details of each plan{' '}
          <TMAnchor
            href="https://tokenmetrics.com/pricing"
            blank
            $color={COLORS.CERULEAN_BLUE}
          >
            here
          </TMAnchor>
        </TMText>
      </StyledTextCol>
      <Col span="17">
        <Row justify="space-around">
          <StyledCol span="8">
            <Image src={Telescope} preview={false} />
            <TMTitle level={5}>BASIC</TMTitle>
          </StyledCol>
          <StyledCol span="8">
            <Image src={Rocket} preview={false} />
            <TMTitle level={5}>ADVANCED</TMTitle>
          </StyledCol>
          <StyledCol span="8">
            <Image src={Planet} preview={false} />
            <TMTitle level={5}>PREMIUM</TMTitle>
          </StyledCol>
        </Row>
      </Col>
    </Row>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextCol = styled(StyledCol)`
  align-items: flex-start;
`;

export default LearnMore;
