import type { PartnerAdmin } from '../../store/slices/partnerAdmins';

export interface DBPartnerAdmin {
  PRTNR_ADMIN_ID: number;
  OKTA_UID: string;
  PRTNR_ID: number;
  ADMIN_ROLE: string;
  ADMIN_FIRST_NAME: string;
  ADMIN_LAST_NAME: string;
  ADMIN_EMAIL_ADDRESS: string;
  ADMIN_PHONE_NUMBER: number;
  ADMIN_CELL_NUMBER: number;
  ACTIVE_FLAG: boolean;
  CREATED_DT: string;
  UPDATED_DT: string;
  USER_DID_FIRST_LOGIN: boolean;
}

const PartnerAdminParser = {
  parse: (dbPartnerAdmin: DBPartnerAdmin): PartnerAdmin => ({
    id: dbPartnerAdmin.PRTNR_ADMIN_ID,
    oktaUid: dbPartnerAdmin.OKTA_UID,
    companyName: '',
    partnerId: dbPartnerAdmin.PRTNR_ID,
    role: dbPartnerAdmin.ADMIN_ROLE,
    firstName: dbPartnerAdmin.ADMIN_FIRST_NAME,
    lastName: dbPartnerAdmin.ADMIN_LAST_NAME,
    email: dbPartnerAdmin.ADMIN_EMAIL_ADDRESS,
    phoneNumber: dbPartnerAdmin.ADMIN_PHONE_NUMBER,
    cellNumber: dbPartnerAdmin.ADMIN_CELL_NUMBER,
    active: dbPartnerAdmin.ACTIVE_FLAG,
    registrationDate: dbPartnerAdmin.CREATED_DT,
    userDidFirstLogin: dbPartnerAdmin.USER_DID_FIRST_LOGIN,
  }),
};

export const { parse } = PartnerAdminParser;
export default PartnerAdminParser;
