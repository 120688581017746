import { Button } from 'antd';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import COLORS from '../../styles/Colors';
import Plus from '../../assets/icons/Plus';
import useModal from '../../hooks/useModal';
import { TMText } from '../common/typography';
import UserManagementContext from './Context';
import InviteUsersModal from './InviteUsersModal';
import SearchInput from '../common/inputs/SearchInput';
import ResetButton from '../common/Buttons/ResetButton';
import FlexContainer from '../common/flex/FlexContainer';
import { getPartnerUsers } from '../../store/thunks/users';
import { usePartnerId } from '../../store/selectors/partners';
import useOktaAuthCustom from '../../hooks/useOktaAuthCustom';

const SubHeader = () => {
  const dispatch = useDispatch();
  const authState = useOktaAuthCustom();
  const { isVisible, showHandler, hideHandler } = useModal();
  const partnerId = usePartnerId();
  const {
    searchValue,
    setSearchValue,
    setCurrentPage,
    planFilter,
    accountStatusFilter,
    nameSorter,
    lastActivitySorter,
  } = useContext(UserManagementContext);
  const [isFilterParams, setIsFilterParams] = useState<boolean>(false);

  const minInvitations = 1;
  const maxInvitations = 10;

  const resetFilterParam = () => {
    if (partnerId && isFilterParams) {
      setIsFilterParams(false);
      setCurrentPage(1);
      setSearchValue('');
      dispatch(
        getPartnerUsers({
          planFilter,
          accountStatusFilter,
          nameSorter,
          lastActivitySorter,
        }),
      );
    }
  };

  const handleSearch = () => {
    if (partnerId && searchValue.length !== 0) {
      setCurrentPage(1);
      setIsFilterParams(true);
      dispatch(
        getPartnerUsers({
          searchValue,
          planFilter,
          accountStatusFilter,
          nameSorter,
          lastActivitySorter,
        }),
      );
    }
  };

  return (
    <>
      <InviteUsersModal
        isVisible={isVisible}
        hideHandler={hideHandler}
        maxNumberOfForms={maxInvitations}
        minNumberOfForms={minInvitations}
      />
      <Container>
        <FlexContainer>
          <SearchInput
            value={searchValue}
            onChange={setSearchValue}
            onPressEnter={handleSearch}
            placeholder="Search by Name / Email"
          />
          <ResetButton onClick={resetFilterParam}>Reset</ResetButton>
        </FlexContainer>
        {!authState.user?.isSuperAdmin && (
          <FlexContainer>
            <StyledButton
              type="primary"
              shape="circle"
              icon={
                <FlexContainer $align="center" $justify="center">
                  <Plus color={COLORS.TITLE_BLACK} />
                </FlexContainer>
              }
              onClick={showHandler}
            />
            <TMText $verticalAligned>
              Invite {minInvitations} to {maxInvitations} Users
            </TMText>
          </FlexContainer>
        )}
      </Container>
    </>
  );
};

const Container = styled(FlexContainer)`
  margin: 20px 0px 30px 0px;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default SubHeader;
