import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';
import {
  getPartnerAdmin,
  updatePartnerAdmin,
  changePassword,
  updateUserDidLogin,
} from '../thunks/partnerAdmins';

export interface PartnerAdmin {
  id?: number;
  oktaUid: string | undefined;
  companyName?: string;
  partnerId?: number;
  role?: string;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phoneNumber: number | null;
  cellNumber?: number | null;
  active?: boolean;
  registrationDate?: string;
  userDidFirstLogin?: boolean;
}

export interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

interface PartnerAdminState {
  readonly partnerAdmin: PartnerAdmin | null;
  readonly loadingStatus: LOADING_STATUSES;
  readonly loadError: string | null;
}

interface ChangePasswordState {
  readonly loadingChangePassword: LOADING_STATUSES;
  readonly changePasswordError: string | null;
}

export type PartnerAdminFinalState = PartnerAdminState & ChangePasswordState;

const initialState: PartnerAdminFinalState = {
  loadingStatus: LOADING_STATUSES.NOT_LOADING,
  partnerAdmin: null,
  loadError: null,
  loadingChangePassword: LOADING_STATUSES.NOT_LOADING,
  changePasswordError: null,
};

const partnerAdminsSlice = createSlice({
  name: 'partnerAdmins',
  initialState,
  reducers: {
    resetChangePasswordLoading(state) {
      state.loadingChangePassword = LOADING_STATUSES.NOT_LOADING;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPartnerAdmin.pending, (state) => {
        state.loadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(getPartnerAdmin.fulfilled, (state, { payload }) => {
        state.loadingStatus = LOADING_STATUSES.FULFILLED;
        state.partnerAdmin = payload;
      })
      .addCase(getPartnerAdmin.rejected, (state, { error }) => {
        state.loadingStatus = LOADING_STATUSES.FAILED;
        state.loadError = error.message ?? 'Unknown error';
      })
      .addCase(updatePartnerAdmin.pending, (state) => {
        state.loadingStatus = LOADING_STATUSES.LOADING;
      })
      .addCase(updatePartnerAdmin.fulfilled, (state, { payload }) => {
        state.loadingStatus = LOADING_STATUSES.FULFILLED;
        state.partnerAdmin = payload;
        message.success('Partner admin updated successfully');
      })
      .addCase(updatePartnerAdmin.rejected, (state, { error }) => {
        state.loadingStatus = LOADING_STATUSES.FAILED;
        state.loadError = error.message ?? 'Unknown error';
      })
      .addCase(changePassword.pending, (state) => {
        state.loadingChangePassword = LOADING_STATUSES.LOADING;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loadingChangePassword = LOADING_STATUSES.FULFILLED;
        state.changePasswordError = null;
      })
      .addCase(changePassword.rejected, (state, { error }) => {
        state.loadingChangePassword = LOADING_STATUSES.FAILED;
        state.changePasswordError = error.message ?? 'Unknown error';
      })
      .addCase(updateUserDidLogin.fulfilled, (state, { payload }) => {
        if (state.partnerAdmin?.userDidFirstLogin) {
          state.partnerAdmin.userDidFirstLogin = payload;
        }
      }),
});

export const { resetChangePasswordLoading } = partnerAdminsSlice.actions;
export default partnerAdminsSlice.reducer;
