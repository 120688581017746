import type EndpointsType from '../../types/EndpointsType';

const PartnerEndpoints = Object.freeze<EndpointsType>({
  getPartner: {
    endpoint: '/partners/:id',
    method: 'GET',
  },
  updatePartner: {
    endpoint: '/partners',
    method: 'PUT',
  },
  uploadLogo: {
    endpoint: '/partners/logo/:partnerId',
    method: 'POST',
  },
  deleteLogo: {
    endpoint: '/partners/logo/:partnerId',
    method: 'DELETE',
  },
  createSetupIntent: {
    endpoint: '/partners/:partnerId/setup-payment',
    method: 'POST',
  },
  getSetupIntentByPartnerId: {
    endpoint: '/partners/:partnerId/setup',
    method: 'GET',
  },
  getPaymentByPartnerId: {
    endpoint: '/partners/:partnerId/payment',
    method: 'GET',
  },
  updatePaymentMethod: {
    endpoint: '/partners/:partnerId/payment/update',
    method: 'POST',
  },
  switchChargeMethod: {
    endpoint: '/partners/:partnerId/payment',
    method: 'PUT',
  },
  getPartners: {
    endpoint: '/partners',
    method: 'GET',
  },
});

export default PartnerEndpoints;
