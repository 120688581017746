import { message } from 'antd';
import type { SetupIntent } from '@stripe/stripe-js';

export const showPaymentMessage = (setupIntent: SetupIntent) => {
  const clientSecret = new URLSearchParams(window.location.search).get(
    'setup_intent_client_secret',
  );

  switch (setupIntent?.status) {
    case 'succeeded':
      if (clientSecret) {
        message.success('Success! Your payment method has been saved.');
        window.history.pushState(
          {},
          '',
          `${window.location.origin}/manage-your-plan`,
        );
      }
      break;
    case 'processing':
      message.info(
        "Processing payment details. We'll update you when processing is complete.",
      );
      break;
    case 'requires_payment_method':
      // Redirect your user back to your payment page to attempt collecting
      // payment again
      if (setupIntent.last_setup_error) {
        message.warning(
          'Failed to process payment details. Please try another payment method.',
        );
      }
      break;
    case 'requires_action':
      // Here, we need to show to the user a fields with two entries for submitting
      // the microdeposits
      message.info(
        'Your payment method has been saved but is waiting for verification',
      );
      break;
    default:
      break;
  }
};
