import { List, Modal } from 'antd';
import type { VFC } from 'react';

import COLORS from '../../../styles/Colors';
import TextSizes from '../../../styles/TextSizes';
import type ModalProps from '../../../types/ModalProps';
import { TMText, TMTitle } from '../../common/typography';
import { BorderRoundedDivider } from '../../common/Divider';
import { useInviteUsersError } from '../../../store/selectors/users';

const ErrorModal: VFC<ModalProps> = ({ isVisible, hideHandler }) => {
  const errors = useInviteUsersError();

  const getErrorsList = () => {
    if (!errors?.validationErrors) return [];
    return Object.values(errors.validationErrors).flat();
  };

  return (
    <Modal visible={isVisible} onCancel={hideHandler} footer={null} centered>
      <TMTitle level={3} $textAlign="center">
        There was an error.
      </TMTitle>
      <BorderRoundedDivider
        $color={COLORS.TITLE_BLACK}
        $verticalMargin={5}
        $marginBottom={20}
      />
      <TMText
        $horizontalAligned
        $color={COLORS.SUBTITLE_BLACK}
        $size={TextSizes.LARGE}
        $marginYBottom={15}
      >
        {errors?.message}
      </TMText>
      <List
        header={
          <TMText
            $size={TextSizes.LARGE}
            $horizontalAligned
            $bold
            $color={COLORS.SUBTITLE_BLACK}
          >
            Errors list
          </TMText>
        }
        bordered
        dataSource={getErrorsList()}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Modal>
  );
};

export default ErrorModal;
