import { shallowEqual, useSelector } from 'react-redux';

import type RootState from '../types/RootState';
import type { Invoice } from '../slices/invoices';
import LOADING_STATUSES from '../../types/statuses/LoadingStatuses';

const isLoadingCurrentInvoice = (state: RootState): boolean => {
  return state.invoices.loadingStatus === LOADING_STATUSES.LOADING;
};

const isLoadingCurrentInvoiceFinished = (state: RootState): boolean => {
  return (
    state.invoices.loadingStatus === LOADING_STATUSES.FULFILLED ||
    state.invoices.loadingStatus === LOADING_STATUSES.FAILED
  );
};

const currentInvoiceSelector = (state: RootState): Invoice | null =>
  state.invoices.current;

const isLoadingPaymentIntent = (state: RootState): boolean => {
  return state.invoices.paymentStatus === LOADING_STATUSES.LOADING;
};

const isLoadingInvoices = (state: RootState): boolean => {
  return state.invoices.invoices.loading === LOADING_STATUSES.LOADING;
};

const isLoadingInvoicesFinished = (state: RootState): boolean => {
  return (
    state.invoices.invoices.loading === LOADING_STATUSES.FULFILLED ||
    state.invoices.invoices.loading === LOADING_STATUSES.FAILED
  );
};

const invoicesSelector = (state: RootState): Invoice[] =>
  state.invoices.invoices.items;

export const useIsLoadingInvoice = () => useSelector(isLoadingCurrentInvoice);

export const useIsLoadingPayment = () => useSelector(isLoadingPaymentIntent);

export const useIsLoadingInvoiceFinished = () =>
  useSelector(isLoadingCurrentInvoiceFinished);

export const useInvoice = () =>
  useSelector(currentInvoiceSelector, shallowEqual);

export const useColorInvoice = () =>
  useSelector((state: RootState): string => {
    const invoice = currentInvoiceSelector(state);

    switch (invoice?.status) {
      case 'PENDING':
        return 'geekblue';
      case 'SUCCESS':
        return 'success';
      case 'PROCESSING':
        return 'processing';
      case 'DUE':
        return 'warning';
      case 'CANCELLED':
        return 'error';
      default:
        return '';
    }
  });

export const useIsLoadingInvoices = () => useSelector(isLoadingInvoices);

export const useIsLoadingInvoicesFinished = () =>
  useSelector(isLoadingInvoicesFinished);

export const useInvoices = () => useSelector(invoicesSelector, shallowEqual);
