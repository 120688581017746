import styled from 'styled-components';

import Divider from '../../common/Divider/Divider';
import NoBorderedTile from '../../common/tiles/NoBorderedTile';
import PlanDetails from './PlanDetails';
import Title from './Title';

const PlanOverview = () => {
  return (
    <StyledTile
      component={
        <div>
          <Divider />
          <Title />
          <Divider />

          <PlanDetails />
        </div>
      }
    />
  );
};

const StyledTile = styled(NoBorderedTile)`
  padding: 25px 40px;
`;

export default PlanOverview;
