import http from '../http';
import UserEndpoints from './Endpoints';
import { ResponseParser } from '../http/ResponseParser';
import type { InviteUserForm } from '@/store/slices/users';
import type ParsedResponse from '../http/types/ParsedResponse';

const InviteUsers = async (
  users: InviteUserForm[],
  partnerId: string,
): Promise<ParsedResponse<string>> => {
  const { data: pureResponse } = await http.request({
    method: UserEndpoints.inviteUsers.method,
    url: UserEndpoints.inviteUsers.endpoint,
    data: { users, partnerId },
  });

  return ResponseParser(pureResponse);
};

export default InviteUsers;
