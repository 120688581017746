import { createContext } from 'react';
import type { SortOrder } from 'antd/lib/table/interface';

export interface ContextValue {
  searchValue: string;
  setSearchValue: (value: string) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  planFilter: string;
  setPlanFilter: (value: string) => void;
  accountStatusFilter: string;
  setAccountStatusFilter: (value: string) => void;
  nameSorter: SortOrder | undefined;
  setNameSorter: (sort: ContextValue['nameSorter']) => void;
  lastActivitySorter: SortOrder | undefined;
  setLastActivitySorter: (sort: ContextValue['lastActivitySorter']) => void;
}

const UserManagementContext = createContext<ContextValue>({
  searchValue: '',
  setSearchValue: () => null,
  currentPage: 1,
  setCurrentPage: () => null,
  planFilter: 'none',
  setPlanFilter: () => null,
  accountStatusFilter: 'none',
  setAccountStatusFilter: () => null,
  nameSorter: undefined,
  setNameSorter: () => null,
  lastActivitySorter: undefined,
  setLastActivitySorter: () => null,
});

export default UserManagementContext;
