import type { VFC } from 'react';
import type IconInterface from './IconInterface';

import COLORS from '../../styles/Colors';

const Bank: VFC<IconInterface> = ({ color = COLORS.GRAY }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2803 6.48914C22.7221 6.21918 23.2778 6.21918 23.7196 6.48914L40.9695 17.0308C41.6199 17.4282 41.8249 18.2776 41.4275 18.9279C41.0301 19.5783 40.1807 19.7833 39.5303 19.3859L23 9.28395L6.46955 19.3859C5.81922 19.7833 4.96984 19.5783 4.57242 18.9279C4.175 18.2776 4.38002 17.4282 5.03035 17.0308L22.2803 6.48914Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20337 38.3333C8.20337 37.5712 8.82122 36.9533 9.58337 36.9533H36.4167C37.1789 36.9533 37.7967 37.5712 37.7967 38.3333C37.7967 39.0955 37.1789 39.7133 36.4167 39.7133H9.58337C8.82122 39.7133 8.20337 39.0955 8.20337 38.3333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7866 17.25C17.7866 16.4878 18.4045 15.87 19.1666 15.87H26.8333C27.5954 15.87 28.2133 16.4878 28.2133 17.25C28.2133 18.0122 27.5954 18.63 26.8333 18.63H19.1666C18.4045 18.63 17.7866 18.0122 17.7866 17.25Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 21.62C12.2621 21.62 12.88 22.2378 12.88 23V32.5833C12.88 33.3455 12.2621 33.9633 11.5 33.9633C10.7378 33.9633 10.12 33.3455 10.12 32.5833V23C10.12 22.2378 10.7378 21.62 11.5 21.62Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1666 21.62C19.9288 21.62 20.5466 22.2378 20.5466 23V32.5833C20.5466 33.3455 19.9288 33.9633 19.1666 33.9633C18.4045 33.9633 17.7866 33.3455 17.7866 32.5833V23C17.7866 22.2378 18.4045 21.62 19.1666 21.62Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8334 21.62C27.5955 21.62 28.2134 22.2378 28.2134 23V32.5833C28.2134 33.3455 27.5955 33.9633 26.8334 33.9633C26.0712 33.9633 25.4534 33.3455 25.4534 32.5833V23C25.4534 22.2378 26.0712 21.62 26.8334 21.62Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5 21.62C35.2621 21.62 35.88 22.2378 35.88 23V32.5833C35.88 33.3455 35.2621 33.9633 34.5 33.9633C33.7378 33.9633 33.12 33.3455 33.12 32.5833V23C33.12 22.2378 33.7378 21.62 34.5 21.62Z"
        fill={color}
      />
    </svg>
  );
};

export default Bank;
