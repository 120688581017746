import type { FC, ReactElement, ReactNode } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import type { CardSize } from 'antd/lib/card';

interface Props {
  title?: ReactNode | string;
  $height?: number;
  size?: CardSize;
  component: ReactNode;
  className?: string;
  rightOptions?: ReactElement;
  loading?: boolean;
}

interface StyledProps {
  $height?: number;
  $paddingX?: number;
  $paddingY?: number;
}

const Tile: FC<Props & StyledProps> = ({
  title,
  $height,
  $paddingX,
  $paddingY,
  size = 'default',
  component,
  className,
  rightOptions,
  loading,
}) => {
  return (
    <TMCard
      title={title}
      $height={$height}
      $paddingX={$paddingX}
      $paddingY={$paddingY}
      size={size}
      bordered={false}
      className={className}
      extra={rightOptions}
      loading={loading}
    >
      {component}
    </TMCard>
  );
};

const TMCard = styled(Card)<StyledProps>(
  ({ $height, $paddingX = 15, $paddingY = 10 }) => {
    return {
      borderRadius: '10px',
      boxShadow: '0px 0px 50px #a0a3bd30',
      padding: `${$paddingY}px ${$paddingX}px`,
      height: $height ? `${$height}px` : '100%',
    };
  },
);

export default Tile;
