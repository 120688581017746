import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Button, message, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';

import {
  useIsLoadingPartner,
  useIsLoadingPaymentMethod,
  usePaymentMethod,
  useIsLoadingSetupIntent,
  useSetupIntent,
  useIsCreatingSetupIntent,
  useIsCreatingSetupIntentSuccess,
  useCreateSetupIntentError,
} from '../../../store/selectors/partners';
import StripeSetup from './StripeSetup';
import ThereIsNotPayment from './ThereIsNotPayment';
import VerifyMicrodeposits from './VerifyMicrodeposits';
import CONFIG from '../../../config';
import type { Partner } from '../../../store/slices/partners';
import { createSetupIntent } from '../../../store/thunks/partners';
import PaymentInformation from './PaymentInformation';
import ProcessingPayment from './ProcessingPayment';
import { showPaymentMessage } from '../../../utils/showPaymentMessage';
import useOktaAuthCustom from '../../../hooks/useOktaAuthCustom';

interface Props {
  partner: Partner | null;
}
interface StyledProps {
  $align?: string;
}

const PaymentProcesses: FC<Props> = ({ partner }) => {
  const dispatch = useDispatch();
  const [showStripeForm, setShowStripeForm] = useState<boolean>(false);
  const authState = useOktaAuthCustom();

  const setupIntent = useSetupIntent();
  const isSetupIntentLoading = useIsLoadingSetupIntent();
  const paymentMethod = usePaymentMethod();
  const isPartnerLoading = useIsLoadingPartner();
  const isPaymentLoading = useIsLoadingPaymentMethod();
  const isCreatingSetupIntent = useIsCreatingSetupIntent();
  const isCreatingSetupIntentSuccess = useIsCreatingSetupIntentSuccess();
  const createSetupIntentError = useCreateSetupIntentError();
  const stripe = loadStripe(CONFIG.stripe.publishableKey || '', {
    locale: 'en',
  });

  useEffect(() => {
    if (setupIntent) showPaymentMessage(setupIntent);
  }, [setupIntent]);

  useEffect(() => {
    if (setupIntent && isCreatingSetupIntentSuccess) setShowStripeForm(true);
    else if (createSetupIntentError) message.error(createSetupIntentError);
  }, [setupIntent, isCreatingSetupIntentSuccess, createSetupIntentError]);

  const cancelEditionHandler = () => setShowStripeForm(false);

  const onPaymentAction = () => {
    if (partner) {
      if (!setupIntent) {
        dispatch(createSetupIntent());
      } else {
        setShowStripeForm(true);
      }
    } else {
      message.info(
        'Some info is missing, please reload the page and try again',
      );
    }
  };

  // Let's show the skeleton loading when the partner is loading and also if we don't have SetupIntent
  const isLoading =
    isPartnerLoading || isPaymentLoading || isSetupIntentLoading;
  return (
    <Container $align={paymentMethod ? 'strech' : 'center'}>
      {showStripeForm ? (
        <StripeSetup
          stripe={stripe}
          clientSecret={setupIntent?.client_secret || ''}
          cancelEditionHandler={cancelEditionHandler}
        />
      ) : (
        <Skeleton active loading={isLoading}>
          {((!paymentMethod && !setupIntent) ||
            setupIntent?.status === 'requires_payment_method') && (
            <ThereIsNotPayment />
          )}
          {setupIntent?.status === 'requires_action' && (
            <VerifyMicrodeposits
              stripe={stripe}
              clientSecret={setupIntent.client_secret}
            />
          )}
          {setupIntent?.status === 'processing' && <ProcessingPayment />}
          {paymentMethod && <PaymentInformation />}
        </Skeleton>
      )}

      {!showStripeForm && (
        <Container>
          {isLoading ? (
            <Skeleton.Button active size="large" style={{ marginTop: 20 }} />
          ) : (
            <div>
              {!authState.user?.isSuperAdmin && (
                <Container>
                  {((!paymentMethod && !setupIntent) ||
                    setupIntent?.status === 'requires_payment_method') && (
                    <StyledButton
                      type="primary"
                      size="large"
                      onClick={onPaymentAction}
                      loading={isCreatingSetupIntent}
                    >
                      Add Payment Method
                    </StyledButton>
                  )}
                </Container>
              )}
            </div>
          )}
        </Container>
      )}
    </Container>
  );
};

const Container = styled.div<StyledProps>(({ $align }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: $align || 'center',
}));

const StyledButton = styled(Button)`
  margin-top: 20px;
  align-self: center;
`;

export default PaymentProcesses;
