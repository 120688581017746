import styled from 'styled-components';

import { NoBorderedTile } from '../tiles';
import COLORS from '../../../styles/Colors';
import { TMText, TMTitle } from '../typography';
import PlayButton from '../../../assets/icons/PlayButton';
import GirlComputer from '../../../assets/images/GirlComputer.png';

const DashboardTour = () => {
  return (
    <StyledNoBorderedTile
      component={
        <>
          <Content>
            <TMTitle level={5}>Video Tour of Dashboard</TMTitle>
            <PlayButton color={COLORS.SUN_GLOW} />
            <TMText>Play</TMText>
          </Content>
          <StyledImage src={GirlComputer} />
        </>
      }
    />
  );
};

// Used img directly instead of Image from Antd because Image apply its className directly to the image instead its container
const StyledImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 23px;
`;

// TODO: Find a better way to set height instead of this way related to file src\components\common\cards\BillingHistory\index.tsx
const Content = styled.div`
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0;
`;

const StyledNoBorderedTile = styled(NoBorderedTile)`
  background: ${COLORS.SELAGO};
`;

export default DashboardTour;
