import http from '../http';
import { paymentParse } from './Parser';
import PartnerEndpoints from './Endpoints';
import type { DBPartnerPayment } from './Parser';
import { ResponseParser } from '../http/ResponseParser';
import type { PartnerPayment } from '@/store/slices/partners';

const GetPaymentByPartnerId = async (partnerId: number) => {
  const { data: pureResponse } = await http.request({
    method: PartnerEndpoints.getPaymentByPartnerId.method,
    url: PartnerEndpoints.getPaymentByPartnerId.endpoint.replace(
      ':partnerId',
      `${partnerId}`,
    ),
  });

  return ResponseParser<DBPartnerPayment, PartnerPayment>(
    pureResponse,
    paymentParse,
  );
};

export default GetPaymentByPartnerId;
