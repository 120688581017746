import { createAsyncThunk } from '@reduxjs/toolkit';

import GetCurrentInvoiceByPartnerId from '../../services/invoices/GetCurrentInvoiceByPartnerId';
import GetInvoicesByPartnerId from '../../services/invoices/GetInvoicesByPartnerId';
import PayInvoice from '../../services/invoices/payInvoice';
import type { Invoice } from '../slices/invoices';
import type ErrorStoreType from '../types/ErrorStoreType';
import type RootState from '../types/RootState';

export const getCurrentInvoiceSilent = createAsyncThunk<
  Invoice,
  number,
  {
    rejectValue: ErrorStoreType;
  }
>(
  'invoices/getCurrentInvoiceSilent',
  async (partnerId, { rejectWithValue }) => {
    if (!partnerId) {
      throw rejectWithValue({
        message: 'No partner id',
      });
    }

    const {
      parsedData: invoice,
      success,
      message,
      validationErrors,
    } = await GetCurrentInvoiceByPartnerId(partnerId);

    if (success && invoice) {
      return invoice;
    }

    if (success && !invoice) {
      throw rejectWithValue({
        message: 'There are no invoices generated this month',
        validationErrors,
      });
    }

    throw rejectWithValue({
      message,
      validationErrors,
    });
  },
);

export const getCurrentInvoiceByPartnerId = createAsyncThunk<
  Invoice,
  number,
  {
    rejectValue: ErrorStoreType;
  }
>(
  'invoices/getCurrentInvoiceByPartnerId',
  async (partnerId, { rejectWithValue }) => {
    if (!partnerId) {
      throw rejectWithValue({
        message: 'No partner id',
      });
    }

    const {
      parsedData: invoice,
      success,
      message,
      validationErrors,
    } = await GetCurrentInvoiceByPartnerId(partnerId);

    if (success && invoice) {
      return invoice;
    }

    if (success && !invoice) {
      throw rejectWithValue({
        message: 'There are no invoices generated this month',
        validationErrors,
      });
    }

    throw rejectWithValue({
      message,
      validationErrors,
    });
  },
);

export const payInvoice = createAsyncThunk<
  Invoice | null,
  void,
  {
    rejectValue: ErrorStoreType;
    state: RootState;
  }
>('invoices/payInvoice', async (_, { rejectWithValue, getState }) => {
  const partnerId = getState().partners.partner?.id;
  const invoiceId = getState().invoices.current?.id;

  if (!partnerId) {
    throw rejectWithValue({
      message: 'No partner id',
    });
  }

  if (!invoiceId) {
    throw rejectWithValue({
      message: 'No invoice id',
    });
  }

  const {
    parsedData: invoice,
    success,
    message,
    validationErrors,
  } = await PayInvoice(partnerId, invoiceId);

  if (success) return invoice || null;

  throw rejectWithValue({
    message,
    validationErrors,
  });
});

export const getInvoicesByPartnerId = createAsyncThunk<
  {
    invoices: Invoice[];
    total: number;
  },
  number,
  {
    rejectValue: ErrorStoreType;
  }
>('invoices/getInvoicesByPartnerId', async (partnerId, { rejectWithValue }) => {
  if (!partnerId) {
    throw rejectWithValue({
      message: 'No partner id',
    });
  }

  const {
    parsedData: invoices,
    success,
    message,
    validationErrors,
    total,
  } = await GetInvoicesByPartnerId(partnerId);

  if (success && invoices && typeof total === 'number') {
    return { invoices, total };
  }

  throw rejectWithValue({
    message,
    validationErrors,
  });
});
