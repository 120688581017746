enum TextSizes {
  X_SMALL = 10,
  SMALL = 12,
  // Default
  MEDIUM = 14,
  LARGE = 16,
  X_LARGE = 18,
}

export default TextSizes;
