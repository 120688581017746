import { useContext } from 'react';
import type { FC } from 'react';
import { Col, Row } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import USBankInformation from './USBank/Information';
import SEPABankInformation from './SEPABank/Information';
import CreditCardInformation from './CreditCard/Information';
import EditCardForm from './CreditCard/EditForm';
import EditSEPAForm from './SEPABank/EditForm';
import EditUSBankForm from './USBank/EditForm';
import { usePaymentMethod } from '../../../../store/selectors/partners';
import CONFIG from '../../../../config';
import PaymentMethodContext from '../Context';
import { Divider } from '../../../common/Divider';
import InvoiceInformation from './Invoice/Information';

const PaymentInformation: FC = () => {
  const paymentMethod = usePaymentMethod();
  const { editMode, toggleEditMode } = useContext(PaymentMethodContext);
  const stripe = loadStripe(CONFIG.stripe.publishableKey || '', {
    locale: 'en',
  });

  return (
    <Row>
      <Col span={24}>
        {!editMode ? (
          <>
            {paymentMethod?.type === 'SEPA_DEBIT' && <SEPABankInformation />}
            {paymentMethod?.type === 'US_BANK_ACCOUNT' && <USBankInformation />}
            {paymentMethod?.type === 'CARD' && <CreditCardInformation />}
          </>
        ) : (
          <Elements stripe={stripe}>
            <>
              {paymentMethod?.type === 'SEPA_DEBIT' && (
                <EditSEPAForm toggleEditMode={toggleEditMode} />
              )}
              {paymentMethod?.type === 'US_BANK_ACCOUNT' && (
                <EditUSBankForm toggleEditMode={toggleEditMode} />
              )}
              {paymentMethod?.type === 'CARD' && (
                <EditCardForm toggleEditMode={toggleEditMode} />
              )}
            </>
          </Elements>
        )}
      </Col>

      {!editMode && (
        <Col span={24}>
          <Divider />
          <InvoiceInformation />
        </Col>
      )}
    </Row>
  );
};

export default PaymentInformation;
